import "./SmsGroup.scss";

import React from "react";
import SmsSendingCard from "../smsSendingCard/SmsSendingCard";
import SmsStatisticPlotByDays from "../statistic/smsStatisticPlotByDays/SmsStatisticPlotByDays";

interface Props {
  group?: App.SmsGroup;
  sms: Array<App.SmsSending>;
  onClickUpdate: () => void;
  onClickDelete: () => void;
}
interface State {}

class SmsGroup extends React.Component<Props, State> {
  public render() {
    return (
      <div className="sms-group">
        <div className="sms-group-head">
          <div className="sms-group-name">{this.props.group?.name}</div>
          <div className="buttons">
            <button onClick={this.props.onClickDelete}>Удалить</button>
            <button onClick={this.props.onClickUpdate}>Редактировать</button>
          </div>
        </div>
        <SmsStatisticPlotByDays
          totalSms={this.props.group?.totalSms || 0}
          calls={this.props.group?.calls || []}
        />
        {this.props.sms.length > 0 && (
          <div className={"sms-group-list-sms-sendings"}>
            {this.props.sms.map((sms) => (
              <SmsSendingCard key={sms.id} smsSending={sms} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default SmsGroup;
