import "../../../styles/labels.scss";

import React, { SyntheticEvent } from "react";

import LabelName from "../name/LabelName";

class LabelText extends React.Component<
  UI.Label.Props<string> & { "data-number"?: boolean }
> {
  static allowedSymbols = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  private handleChange(event: SyntheticEvent<HTMLInputElement>) {
    if (this.props["data-number"]) {
      this.props.onChange(
        ("" + event.currentTarget.value)
          .split("")
          .filter((symbol) => LabelText.allowedSymbols.includes(symbol))
          .join("")
      );
    } else {
      this.props.onChange(event.currentTarget.value);
    }
  }

  public render() {
    return (
      <label className="label label-comment">
        <LabelName {...this.props} />
        <input {...this.props} onChange={this.handleChange.bind(this)} />
      </label>
    );
  }
}

export default LabelText;
