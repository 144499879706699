import FileLoader from "../../../UI/components/fileLoader/FileLoader";
import Message from "../../../UI/components/popups/message/Message";
import ProgressBar from "../../../UI/components/loaders/progressbar/ProgressBar";
import React from "react";
import Table from "../../../UI/components/table/table/Table";

interface Props {
  columns: Array<UI.Table.Column>;
  complite: number;
  filters: UI.Filters.TableFilters;
  info: Array<App.LoadRecordsInfoAboutFiles>;
  total: number;
  options: UI.Filters.TableFiltersOptions;
  records: Array<App.IFile>;
  rows: Array<UI.Table.RowData>;
  rules: UI.Filters.Rules;
  onRestart: () => void;
  onChangeFilters: (filters: UI.Filters.TableFilters) => void;
  onClosePopup: () => void;
  onChangeRecors: (records: Array<App.IFile>) => void;
  onChangeInfo: (info: Array<App.IFile>) => void;
}
interface State {}

class LoadInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <>
        {this.props.info.length > 0 ? (
          <>
            <button onClick={this.props.onRestart}>
              Загрузить другой файл
            </button>

            <FileLoader
              title={"Записи (.wav)"}
              types={["audio/wav"]}
              files={this.props.records}
              handleChange={this.props.onChangeRecors}
              deleteFile={() => {}}
              maxCountFiles={10000}
              displayType="list"
            />
            <Table
              title="Записи"
              columns={this.props.columns}
              rules={this.props.rules}
              filterOptions={this.props.options}
              filters={this.props.filters}
              dispatchUpdate={this.props.onChangeFilters}
              rows={this.props.rows}
            />
          </>
        ) : (
          <FileLoader
            title={"Файл информации (.csv)"}
            types={["csv", "application/vnd.ms-excel"]}
            handleChange={this.props.onChangeInfo}
            deleteFile={() => {}}
            maxCountFiles={1}
            displayType="list"
          />
        )}
        {this.props.total > 0 && (
          <Message
            title="Загрузка файлов"
            text={`Обработано ${this.props.complite}/${this.props.total}`}
            onClick={this.props.onClosePopup.bind(this)}
          >
            <ProgressBar
              percent={(this.props.complite / this.props.total) * 100}
            />
          </Message>
        )}
      </>
    );
  }
}

export default LoadInfo;
