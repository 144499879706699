import "./GroupCard.scss";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  group: App.Group;
  active: boolean;
}
interface State {}

class GroupCard extends React.Component<Props, State> {
  public render() {
    return (
      <div className="group-card" data-active={this.props.active}>
        <NavLink
          to={`/autocenter/${this.props.group.autocenter_id}/${this.props.group.id}`}
          className="main"
        >
          <div className="title">
            <div
              className="color-indicator"
              style={{ backgroundColor: this.props.group.color }}
            ></div>
            <div className="code">{this.props.group.code}</div>
          </div>
          <div className="name">{this.props.group.name}</div>
        </NavLink>
        {this.props.children}
      </div>
    );
  }
}

export default GroupCard;
