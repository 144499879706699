import "./SmsSendingCallsByCities.scss";

import CallsByCity from "./callsByCity/CallsByCity";
import React from "react";

interface Props {
  total: number;
  cities: Array<App.City>;
}
interface State {
  active: string | null;
}

class SmsSendingCallsByCities extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: null,
    };
  }
  private handleChangeActive(active: string | null) {
    this.setState({ active });
  }
  public render() {
    return (
      <div className="sms-sending-calls-by-cities">
        {this.props.cities.map((city) => (
          <CallsByCity
            onChangeActive={this.handleChangeActive.bind(this)}
            key={city.name}
            total={this.props.total}
            active={this.state.active}
            city={city}
          />
        ))}
      </div>
    );
  }
}
export default SmsSendingCallsByCities;
