import {
  SessionActions,
  changeTokensType,
  changeUserType,
  logoutType,
} from "../actions/session";

export interface SessionStore {
  tokens: App.Tokens | null;
  user: App.User | null;
}
export const defaultSessionStore: SessionStore = getStateFromLocalStorage();

export default function (
  state: App.Store.Session,
  action: SessionActions
): App.Store.Session {
  switch (action.type) {
    case changeTokensType:
      localStorage.tokens = JSON.stringify(action.tokens);
      state.tokens = action.tokens;
      break;
    case changeUserType:
      localStorage.user = JSON.stringify(action.user);
      state.user = action.user;
      break;
    case logoutType:
      localStorage.removeItem("user");
      localStorage.removeItem("tokens");
      state.user = null;
      state.tokens = null;
      break;
    default:
      break;
  }
  return state;
}

function getStateFromLocalStorage(): SessionStore {
  try {
    return {
      user: JSON.parse(localStorage.user),
      tokens: JSON.parse(localStorage.tokens),
    };
  } catch {
    return {
      user: null,
      tokens: null,
    };
  }
}
