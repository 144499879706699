import "./CallsByCity.scss";

import CallsTableDefaultContainerContainer from "../../../../containers/calls/callsTableDefaultContainer/CallsTableDefaultContainerContainer";
import React from "react";

interface Props {
  total: number;
  city: App.City;
  active: string | null;
  onChangeActive: (active: string | null) => void;
}
interface State {}

class CallsByCity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  private handleClick() {
    this.props.onChangeActive(
      this.props.active === this.props.city.name ? null : this.props.city.name
    );
  }
  public render() {
    return (
      <div className="calls-by-city">
        <div
          className="head"
          onClick={this.handleClick.bind(this)}
          data-active={this.props.active === this.props.city.name}
        >
          <div
            className="complited"
            style={{
              width: `${(this.props.city.amount / this.props.total) * 100}%`,
            }}
          ></div>
          <div className="content">
            <div className="name">{this.props.city.name}</div>
            <div className="amount">
              {this.props.city.amount} / {this.props.total}
            </div>
          </div>
        </div>
        {this.props.active === this.props.city.name && (
          <CallsTableDefaultContainerContainer calls={this.props.city.calls} />
        )}
      </div>
    );
  }
}
export default CallsByCity;
