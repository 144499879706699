import "./SmsDetailsView.scss";

import { notFoundPath, smsPath } from "../../routes";

import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router";
import SmsDetailsContainer from "../../containers/sms/SmsDetailsContainer";

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {}

class SmsDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public componentDidMount() {
    Page.setTitle(`Смс рассылка №${this.props.match.params.id}`);
  }
  private setTitle(name: string) {
    Page.setTitle(name);
  }
  private redirectToList() {
    this.props.history.replace(smsPath);
  }
  private redirectToNotFound() {
    this.props.history.replace(notFoundPath);
  }
  public render() {
    return (
      <div className="sms-details-view">
        <SmsDetailsContainer
          setTitle={this.setTitle.bind(this)}
          id={+this.props.match.params.id}
          redirectToList={this.redirectToList.bind(this)}
          redirectToNotFound={this.redirectToNotFound.bind(this)}
        />
      </div>
    );
  }
}
export default SmsDetailsView;
