import "./LabelText.scss";

import React, { SyntheticEvent } from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;

  placeholder?: string;
  label?: string;
  area?: boolean;
  className: string;
  type: "text" | "email" | "phone" | "password" | "number";
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  correct?: boolean;
  uncorrect?: boolean;
  disabled?: boolean;
}
interface State {
  isOpen: boolean;
}

class LabelText extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  static defaultProps = {
    value: "",
    className: "",
    type: "text",
    onChange: () => {},
  };

  static regExpPhone: RegExp = /^\+?375\s\(?\s(25|29|33|44)\s\)?\s\d{3}\s-\s\d{2}\s-\s\d{2}$/;
  static regExpEmail: RegExp = /^[a-zA-Z0-9][a-zA-Z\-_0-9.]{1,}[a-zA-Z0-9]@[a-zA-Z][a-zA-Z\-_0-9]{1,}[a-zA-Z].[a-zA-Z]{2,11}$/;

  private handleChange(
    event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    this.props.onChange(event.currentTarget.value);
  }
  private handleClickOnEye() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  private get type() {
    if (this.props.type === "password" && this.state.isOpen) return "text";
    else return this.props.type;
  }

  public render() {
    return (
      <label className={"text " + this.props.className}>
        {this.props.label && <div className="label">{this.props.label}</div>}
        {this.props.area ? (
          <textarea
            data-correct={this.props.correct}
            data-uncorrect={this.props.uncorrect}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.handleChange.bind(this)}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            required={this.props.required}
            disabled={this.props.disabled}
          ></textarea>
        ) : (
          <input
            type={this.type}
            data-correct={this.props.correct}
            data-uncorrect={this.props.uncorrect}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.handleChange.bind(this)}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            required={this.props.required}
            disabled={this.props.disabled}
          />
        )}
        {this.props.type === "password" && (
          <div
            className="eye"
            data-open={this.state.isOpen}
            onClick={this.handleClickOnEye.bind(this)}
          ></div>
        )}
      </label>
    );
  }
}

export default LabelText;
