import TrunkService, { TrunkData } from "../../services/TrunkService";

import { AxiosError } from "axios";
import Question from "../../UI/components/popups/question/Question";
import React from "react";
import TrunkList from "../../components/trunk/trunkList/TrunkList";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {}
interface State {
  trunks: Array<App.Trunk>;
  idForDelete: number | null;
}

class TrunkListContainer extends React.Component<Props, State> {
  private trunkService: TrunkService;
  constructor(props: Props) {
    super(props);

    this.trunkService = new TrunkService();

    this.state = {
      idForDelete: null,
      trunks: [],
    };
  }

  public componentDidMount() {
    this.loadList();
  }

  private loadList() {
    this.trunkService
      .getList()
      .then((response) => {
        this.setState({
          trunks: response.data,
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось загрузить список транков"]
          )
        );
      });
  }

  private async create(trunk: TrunkData): Promise<boolean> {
    return this.trunkService
      .create(trunk)
      .then((response) => {
        this.setState({
          trunks: this.state.trunks.concat(response.data),
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось создать транк"])
        );
        return false;
      });
  }
  private async update(trunk: App.Trunk) {
    return this.trunkService
      .update(trunk.id, trunk)
      .then((response) => {
        this.setState({
          trunks: this.state.trunks.concat(response.data),
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось редактировать транк"]
          )
        );
        return false;
      });
  }
  private delete() {
    if (this.state.idForDelete === null) return;
    const id = this.state.idForDelete;
    return this.trunkService
      .delete(id)
      .then(() => {
        this.setState({
          trunks: this.state.trunks.filter((trunk) => trunk.id !== id),
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось удалить транк"])
        );
        return false;
      });
  }
  private askDelete(id: number) {
    this.setState({ idForDelete: id });
  }
  private close() {
    this.setState({ idForDelete: null });
  }
  public render() {
    return (
      <>
        <TrunkList
          trunks={this.state.trunks}
          create={this.create.bind(this)}
          update={this.update.bind(this)}
          delete={this.askDelete.bind(this)}
        />
        {this.state.idForDelete !== null && (
          <Question
            question="Вы действительно хотите удалить Транк?"
            onAgree={this.delete.bind(this)}
            onDisagree={this.close.bind(this)}
          />
        )}
      </>
    );
  }
}

export default TrunkListContainer;
