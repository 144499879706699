type DisplayErrorsType = "DISPLAY_ERRORS";

interface DisplayErrorsAction {
  id: number;
  type: DisplayErrorsType;
  errors: Array<string>;
  callback?: () => void;
}

export type ErrorActions = DisplayErrorsAction;
export const displayErrorsType: DisplayErrorsType = "DISPLAY_ERRORS";
let ID_ERROR = 1;

export function displayErrors(
  errors: Array<string>,
  callback?: () => void
): DisplayErrorsAction {
  return {
    type: displayErrorsType,
    id: ID_ERROR++,
    errors,
    callback,
  };
}
