import { AxiosError } from "axios";
import GroupService from "../../services/GroupService";
import GroupsList from "../../components/group/list/GroupsList";
import React from "react";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  redact: boolean;
  autocenter: number;
  groups?: Array<App.Group>;
  active?: number;
}

interface State {
  loading: boolean;
  groups: Array<App.Group>;
}

class GroupsListContainer extends React.Component<Props, State> {
  private groupService: GroupService;
  constructor(props: Props) {
    super(props);
    this.groupService = new GroupService();

    this.state = {
      loading: false,
      groups: props.groups || [],
    };
  }

  public componentDidMount() {
    if (!this.props.groups) {
      this.loadGroups();
    }
  }
  public componentDidUpdate(prevProps: Props) {
    if (!this.props.groups && prevProps.autocenter !== this.props.autocenter) {
      this.loadGroups();
    }
  }

  private loadGroups() {
    this.groupService
      .getList({
        autocenter: this.props.autocenter,
      })
      .then((response) => {
        this.setState({
          groups: this.sortByWeight(response.data),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось загрузить список групп"],
            this.loadGroups.bind(this)
          )
        );
      });
  }

  private async create(group: App.GroupData): Promise<boolean> {
    group.weight = this.state.groups.length;
    const prevGroups = this.state.groups;
    this.setState({ loading: true });
    return this.groupService
      .create(group, this.props.autocenter)
      .then((response) => {
        if (this.state.groups === prevGroups) {
          this.setState({
            loading: false,
            groups: this.sortByWeight(this.state.groups.concat(response.data)),
          });
        }
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        this.setState({ loading: false });
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось создать группу"])
        );
        return false;
      });
  }

  private async handleUpdate(
    value: App.GroupData,
    id: number
  ): Promise<boolean> {
    if (this.state.loading) return false;
    this.setState({ loading: true });

    return this.groupService
      .update(value, id)
      .then((response) => {
        this.setState({
          loading: false,
          groups: this.sortByWeight(
            this.state.groups.map((a) =>
              a.id === response.data.id ? response.data : a
            )
          ),
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        this.setState({ loading: false });
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось редактировать группу"]
          )
        );
        return false;
      });
  }

  private async handleDelete(id: number) {
    this.setState({
      groups: this.state.groups.filter((a) => a.id !== id),
    });
  }

  private updateCurrentGroup(group: App.Group) {
    this.setState({
      groups: this.state.groups
        .map((a) => (a.id === group.id ? group : a))
        .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
    });
  }

  private sortByWeight(groups: Array<App.Group>) {
    return groups.sort((a, b) => (a.weight > b.weight ? 1 : -1));
  }

  public render() {
    return (
      <GroupsList
        redact={this.props.redact}
        active={this.props.active || 0}
        groups={this.state.groups}
        loading={this.state.loading}
        onUpdate={this.handleUpdate.bind(this)}
        onDelete={this.handleDelete.bind(this)}
        onCreate={this.create.bind(this)}
        updateCurrentGroup={this.updateCurrentGroup.bind(this)}
      />
    );
  }
}

export default GroupsListContainer;
