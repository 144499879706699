import "./HorisontalScroll.scss";

import Arrow from "../arrow/Arrow";
import React, { RefObject, SyntheticEvent } from "react";

interface IHorisontalScrollProps {
  scrolledTo?: (percent: number) => void;
}

interface IHorisontalScrollState {
  list: RefObject<HTMLDivElement>;
  scrollIsActive: boolean;
  visibleLeftShadow: boolean;
  visibleRightShadow: boolean;
  direction: number;
}

class HorisontalScroll extends React.Component<
  IHorisontalScrollProps,
  IHorisontalScrollState
> {
  constructor(props: any) {
    super(props);

    this.moveTo = this.moveTo.bind(this);
    this.activateScroll = this.activateScroll.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.deactivateScroll = this.deactivateScroll.bind(this);
    this.handleWheel = this.handleWheel.bind(this);

    this.state = {
      list: React.createRef(),
      scrollIsActive: false,
      visibleLeftShadow: false,
      visibleRightShadow: false,
      direction: 0
    };
  }
  static stepMove = 10;
  static stepWheel = 1;

  public componentDidMount() {
    this.isDisplayShadows();
    if (this.state.list.current) {
      this.state.list.current.addEventListener("wheel", this.handleWheel);
      window.addEventListener("mouseup", this.deactivateScroll);
    }
  }

  public componentWillUnmount() {
    if (this.state.list.current) {
      this.state.list.current.removeEventListener("wheel", this.handleWheel);
      window.removeEventListener("mouseup", this.deactivateScroll);
    }
  }

  public handleWheel(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.currentTarget.clientWidth < event.currentTarget.scrollWidth) {
      event.currentTarget.scrollBy(
        HorisontalScroll.stepWheel * event.deltaY,
        0
      );
      this.dispatchScrolledTo();
    }
  }

  private dispatchScrolledTo(): void {
    if (this.props.scrolledTo && this.state.list.current) {
      this.props.scrolledTo(
        Math.max(
          this.state.list.current.scrollLeft /
            (this.state.list.current.scrollWidth -
              this.state.list.current.clientWidth),
          0
        )
      );
    }
  }

  private handleMouseMove(event: SyntheticEvent<HTMLDivElement, MouseEvent>) {
    if (this.state.scrollIsActive) {
      event.currentTarget.scrollBy(-event.nativeEvent.movementX, 0);
      this.dispatchScrolledTo();
    }
  }

  private moveTo() {
    if (this.state.list.current) {
      this.state.list.current.scrollBy({
        left: this.state.direction * HorisontalScroll.stepMove,
        behavior: "auto"
      });
      this.dispatchScrolledTo();
    }
  }

  private isDisplayShadows(): void {
    requestAnimationFrame(() => {
      if (this.state.list.current) {
        this.setState({
          visibleLeftShadow: this.state.list.current.scrollLeft > 0,
          visibleRightShadow:
            this.state.list.current.scrollLeft +
              this.state.list.current.clientWidth <
            this.state.list.current.scrollWidth
        });
      }
      this.isDisplayShadows();
    });
  }

  private activateScroll() {
    this.setState({ scrollIsActive: true });
  }

  private deactivateScroll() {
    this.moveTo();
    this.setState({
      scrollIsActive: false,
      direction: 0
    });
  }

  public handleClickArrow(event: SyntheticEvent) {
    event.stopPropagation();
  }

  private startMove(direction?: number) {
    if (direction) {
      this.setState({ direction });
    }
    requestAnimationFrame(() => {
      this.moveTo();
      if (this.state.direction) {
        this.startMove();
      }
    });
  }

  public render() {
    return (
      <div className="horisontal-scroll">
        <div
          className="container"
          onMouseDown={this.activateScroll}
          onMouseMove={this.handleMouseMove}
          ref={this.state.list}
          onWheel={this.handleWheel.bind(this)}
        >
          <div className="list">{this.props.children}</div>
        </div>

        {this.state.visibleLeftShadow && (
          <>
            <div className="shadow-left">
              <div></div>
            </div>
            <Arrow
              direction="back"
              onClick={this.handleClickArrow}
              onMouseDown={() => this.startMove(-1)}
            />
          </>
        )}
        {this.state.visibleRightShadow && (
          <>
            <div className="shadow-right">
              <div></div>
            </div>
            <Arrow
              direction="forward"
              onClick={this.handleClickArrow}
              onMouseDown={() => this.startMove(1)}
            />
          </>
        )}
      </div>
    );
  }
}

export default HorisontalScroll;
