import React from "react";
import DateCell from "../cells/dateCell/DateCell";
import TextCell from "../cells/textCell/TextCell";
import LinkCell from "../cells/linkCell/LinkCell";
import TimerCell from "../cells/timerCell/TimerCell";
import NumberCell from "../cells/numberCell/NumberCell";
import AudioCell from "../cells/audioCell/AudioCell";

type Props = UI.Table.RowData & {
  openPopup: (event: any, children: JSX.Element) => void;
};

class TableRow extends React.Component<Props> {
  static instanceOfITableLink(link: any): link is UI.Table.Link {
    return (link as UI.Table.Link).to !== undefined;
  }

  private getCell(cell: UI.Table.TableCellType, index: number) {
    switch (cell.type) {
      case "number":
        return (
          <NumberCell
            key={index}
            {...cell.props}
            onMouseOver={this.getMouseOverHandler(cell)}
          />
        );
      case "text":
        return (
          <TextCell
            key={index}
            {...cell.props}
            onMouseOver={this.getMouseOverHandler(cell)}
          />
        );
      case "link":
        return (
          <LinkCell
            key={index}
            {...cell.props}
            onMouseOver={this.getMouseOverHandler(cell)}
          />
        );
      case "date":
        return (
          <DateCell
            key={index}
            {...cell.props}
            onMouseOver={this.getMouseOverHandler(cell)}
          />
        );
      case "timer":
        return (
          <TimerCell
            key={index}
            {...cell.props}
            onMouseOver={this.getMouseOverHandler(cell)}
          />
        );
      case "audio":
        return (
          <AudioCell
            key={index}
            {...cell.props}
            onMouseOver={this.getMouseOverHandler(cell)}
          />
        );
      case "children":
        return <td key={index}>{cell.props.children}</td>;
      default:
        return <td key={index}>-</td>;
    }
  }

  private getMouseOverHandler(cell: UI.Table.TableCellType) {
    if (cell.props.hover) {
      const hover = cell.props.hover;
      return (event: MouseEvent) => this.props.openPopup(event, hover);
    } else {
      return () => {};
    }
  }

  public render() {
    return (
      <tr className={this.props.className}>
        {this.props.cells.map(this.getCell.bind(this))}
      </tr>
    );
  }
}

export default TableRow;
