import "./SmsStatisticByDays.scss";

import React from "react";
import SmsStatisticByDay from "./smsStatisticByDay/SmsStatisticByDay";

interface Props {
  calls: Array<number>;
  totalCalls: number;
}
interface State {}

class SmsStatisticByDays extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    const amountCallsInOtherDays = this.props.calls.reduce(
      (g, e, i) => (i < 2 ? g : g + e),
      0
    );
    return (
      <div
        className="sms-statistic-by-days"
        data-empty={!this.props.totalCalls}
      >
        {!this.props.totalCalls && (
          <div className="sms-statistic-by-days__not-calls">
            Не было ни одного звонка
          </div>
        )}
        {!!this.props.totalCalls && (
          <>
            <SmsStatisticByDay
              key="1"
              name="1 день"
              color={"#0AD668"}
              percent={
                +((this.props.calls[0] / this.props.totalCalls) * 100).toFixed(
                  1
                )
              }
              amount={this.props.calls[0]}
            />
            <SmsStatisticByDay
              key="2"
              name="2 день"
              color={"#6E76FF"}
              percent={
                +((this.props.calls[1] / this.props.totalCalls) * 100).toFixed(
                  1
                )
              }
              amount={this.props.calls[1]}
            />
            <SmsStatisticByDay
              key="3-7"
              name="3-7 дни"
              color={"#FFA1A3"}
              percent={
                +(
                  (amountCallsInOtherDays / this.props.totalCalls) *
                  100
                ).toFixed(1)
              }
              amount={amountCallsInOtherDays}
            />
          </>
        )}
      </div>
    );
  }
}
export default SmsStatisticByDays;
