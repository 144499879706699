import React from "react";
import "./TablePagination.scss";

interface Props {
  active: number;
  pageNumber: number;
  hadleSelectPage: (page: number) => void;
}
interface State {}

class TablePagination extends React.Component<Props, State> {
  public componentDidUpdate(props: Props) {
    if (this.props.pageNumber !== props.pageNumber) {
      this.props.hadleSelectPage(1);
    }
  }
  public render() {
    return (
      <>
        {this.props.pageNumber > 1 && (
          <div className="table-pagination">
            {new Array(this.props.pageNumber).fill(1).map((e, i) => (
              <div
                key={i}
                data-active={i + 1 === this.props.active}
                className="page"
                onClick={() => this.props.hadleSelectPage(i + 1)}
              >
                {i + 1}
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}

export default TablePagination;
