import "./SmsSendingsView.scss";

import Page from "../../UI/utils/Page";
import React from "react";
import SmsSendingsContainer from "../../containers/sms/SmsSendingsContainer";

interface Props {}
interface State {}

class SmsSendingView extends React.Component<Props, State> {
  public componentDidMount() {
    Page.setTitle("Смс рассылки");
  }
  public render() {
    return (
      <div className="sms-sending-view">
        <SmsSendingsContainer brand year month sending tabs />
      </div>
    );
  }
}

export default SmsSendingView;
