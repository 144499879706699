import "./FaqForm.scss";

import React, { SyntheticEvent } from "react";

import { FaqData } from "../../services/FaqService";
import LabelFile from "../custom/labelFile/LabelFile";
import LabelText from "../custom/text/LabelText";
import ShadowBack from "../../UI/components/popups/shadowBack/ShadowBack";

interface Props {
  question?: App.Faq;
  close: () => void;
  onSubmit: (data: FaqData) => void;
}
interface State {
  question: string;
  answer: string;
  image: string | File | null;
}

class FaqForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      question: "",
      answer: "",
      image: null,
    };
  }

  public componentDidMount() {
    this.props.question &&
      this.setState({
        question: this.props.question.question,
        answer: this.props.question.answer,
        image: this.props.question.image,
      });
  }

  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (this.state.answer && this.state.question) {
      const data: FaqData = {
        question: this.state.question,
        answer: this.state.answer,
      };
      if (this.state.image === null || this.state.image instanceof File) {
        data.image = this.state.image;
      }
      this.props.onSubmit(data);
    }
  }

  public render() {
    return (
      <ShadowBack onClick={this.props.close}>
        <form className={"faq-form"} onSubmit={this.handleSubmit.bind(this)}>
          <LabelText
            label="Вопрос"
            value={this.state.question}
            onChange={(question) => this.setState({ question })}
          />
          <LabelText
            label="Ответ"
            area
            value={this.state.answer}
            onChange={(answer) => this.setState({ answer })}
          />
          <LabelFile
            label="Добавить изображенте"
            value={this.state.image}
            onChange={(image) => this.setState({ image: image })}
          />
          <button>Сохранить</button>
        </form>
      </ShadowBack>
    );
  }
}
export default FaqForm;
