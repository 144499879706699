import SmsGroupService, { SmsGroupData } from "../../services/SmsGroupService";

import { AxiosError } from "axios";
import React from "react";
import SmsGroupForm from "../../components/sms/groupForm/SmsGroupForm";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  close: (group?: App.SmsGroup) => void;
  isOpen: boolean;
}
interface State {
  loading: boolean;
}

class SmsGroupFormContainer extends React.Component<Props, State> {
  private smsGroupService: SmsGroupService;
  constructor(props: Props) {
    super(props);
    this.smsGroupService = new SmsGroupService();
    this.state = {
      loading: false,
    };
  }

  private create(group: SmsGroupData) {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.smsGroupService
      .create(group)
      .then((response) => {
        this.props.close(response.data);
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось создать группу"])
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  public render() {
    return (
      <>
        {this.props.isOpen && (
          <SmsGroupForm
            loading={this.state.loading}
            close={this.props.close}
            submit={this.create.bind(this)}
          />
        )}
      </>
    );
  }
}

export default SmsGroupFormContainer;
