import "./GroupsList.scss";

import GroupCard from "../card/GroupCard";
import GroupForm from "../form/GroupForm";
import GroupRedactContainer from "../../../containers/group/GroupRedactContainer";
import React from "react";

interface Props {
  redact: boolean;
  groups: Array<App.Group>;
  loading: boolean;
  active: number;
  onUpdate: (value: App.GroupData, id: number) => void;
  onDelete: (id: number) => void;
  onCreate: (group: App.GroupData) => Promise<boolean>;
  updateCurrentGroup: (group: App.Group) => void;
}

interface State {
  groups: Array<App.Group>;
  dragIndex: number | null;
  isOpenForm: boolean;
}

class GroupsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      groups: props.groups,
      dragIndex: null,
      isOpenForm: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.groups !== prevProps.groups) {
      this.setState({ groups: this.props.groups });
    }
  }
  private handleStartMove(dragIndex: number) {
    this.setState({ dragIndex });
  }
  private handleMouseEnter(index: number) {
    if (this.state.dragIndex !== null && index !== this.state.dragIndex) {
      const dragIndex: number = this.state.dragIndex;
      const newA = this.state.groups.map((e) => e);
      newA[index] = this.state.groups[dragIndex];
      newA[dragIndex] = this.state.groups[index];

      this.setState({
        groups: newA,
        dragIndex: index,
      });
    }
  }
  private hanldeEndMove() {
    this.setState({ dragIndex: null });
    for (let index = 0; index < this.state.groups.length; index++) {
      if (this.state.groups[index].weight !== index) {
        const group = this.state.groups[index];
        group.weight = index;
        this.props.onUpdate(group, group.id);
      }
    }
  }

  private async handleSubmitCreateGroupForm(group: App.GroupData) {
    const result = await this.props.onCreate(group);
    this.setState({ isOpenForm: !result });
  }
  private openFormCreateGroup() {
    this.setState({ isOpenForm: true });
  }
  private closeFormCreateGroup() {
    this.setState({ isOpenForm: false });
  }

  public render() {
    return (
      <>
        <div className="groups-list">
          <div className="list">
            {this.state.groups.map((group, index) =>
              this.props.redact ? (
                <GroupRedactContainer
                  key={group.id}
                  index={index}
                  group={group}
                  updateCurrentGroup={this.props.updateCurrentGroup}
                  onDelete={this.props.onDelete}
                  onStartMove={this.handleStartMove.bind(this)}
                  onMouseEnter={this.handleMouseEnter.bind(this)}
                  onEndMove={this.hanldeEndMove.bind(this)}
                />
              ) : (
                <GroupCard
                  key={group.id}
                  active={group.id === this.props.active}
                  group={group}
                />
              )
            )}
          </div>
          {this.props.redact && (
            <button
              className="create-group-button"
              onClick={this.openFormCreateGroup.bind(this)}
            >
              +
            </button>
          )}
        </div>

        {this.state.isOpenForm && (
          <GroupForm
            group={null}
            submit={this.handleSubmitCreateGroupForm.bind(this)}
            close={this.closeFormCreateGroup.bind(this)}
          />
        )}
      </>
    );
  }
}

export default GroupsList;
