import "./UserFilters.scss";

import LabelText from "../../custom/text/LabelText";
import React from "react";

interface Props {
  activeTab: string;
  keyword: string;
  onChange: (activeTab: string, keyword: string) => void;
}
interface State {}

class UserFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="user-filters">
        <LabelText
          className="search-label"
          onChange={(keyword: string) =>
            this.props.onChange(this.props.activeTab, keyword)
          }
          value={this.props.keyword}
        />
        <div className="tabs">
          <div
            className="tab"
            onClick={() => this.props.onChange("all", this.props.keyword)}
            data-active={this.props.activeTab === "all"}
          >
            Все
          </div>
          <div
            className="tab"
            onClick={() => this.props.onChange("users", this.props.keyword)}
            data-active={this.props.activeTab === "users"}
          >
            Пользователи
          </div>
          <div
            className="tab"
            onClick={() => this.props.onChange("advanced_users", this.props.keyword)}
            data-active={this.props.activeTab === "advanced_users"}
          >
            Пользователи с полным поиском
          </div>
          <div
            className="tab"
            onClick={() => this.props.onChange("admins", this.props.keyword)}
            data-active={this.props.activeTab === "admins"}
          >
            Админы
          </div>
        </div>
      </div>
    );
  }
}
export default UserFilters;
