import "./FileLoader.scss";

import React, { DragEvent, RefObject, SyntheticEvent } from "react";

import AddFile from "../../assets/add-file.png";
import DragAndDrop from "../dragAndDrop/DragAndDrop";
import ImageDisplay from "./display/ImageDisplay";
import ListDisplay from "./display/ListDisplay";

interface Props {
  files: Array<App.IFile>;
  handleChange: (files: Array<App.IFile>) => void;
  deleteFile: (id: number) => void;
  title: string;
  maxCountFiles: number;
  types: Array<string>;
  displayType: "list" | "image";

  "data-correct"?: boolean;
  "data-uncorrect"?: boolean;
  "data-cash"?: boolean;
}
interface State {
  ref: RefObject<HTMLInputElement>;
}

class FileLoader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handlePaste = this.handlePaste.bind(this);
    this.state = {
      ref: React.createRef(),
    };
  }

  static defaultProps = {
    files: [],
    maxCountFiles: 30,
    displayType: "list",
    types: ["image/jpeg", "image/png"],
  };
  static countActive = 0;

  public componentDidMount() {
    FileLoader.countActive++;
    document.addEventListener("paste", this.handlePaste);
  }
  public componentWillUnmount() {
    FileLoader.countActive--;
    document.removeEventListener("paste", this.handlePaste);
  }

  private handleClick() {
    if (this.state.ref.current) {
      this.state.ref.current.click();
    }
  }

  private handleChange(event: SyntheticEvent<HTMLInputElement>): void {
    if (event.currentTarget.files) {
      this.addFiles(event.currentTarget.files);
    }
  }

  private handleDrop(event: DragEvent<HTMLDivElement>): void {
    this.addFiles(event.dataTransfer.files);
  }

  private handlePaste(event: ClipboardEvent) {
    if (event.clipboardData) {
      const files = event.clipboardData.files;
      if (files.length > 0) {
        event.preventDefault();
        this.addFiles(files);
      }
    }
  }

  private addFiles(filesForLoad: FileList) {
    if (filesForLoad.length) {
      const lastIndex: number = filesForLoad.length;
      let maxCountAddImage: number =
        this.props.maxCountFiles - this.props.files.length;
      const files: Array<App.IFile> = Object.assign([], this.props.files);
      let index: number = 0;
      while (maxCountAddImage && index < lastIndex) {
        if (this.props.types.includes(this.getFileType(filesForLoad[index]))) {
          let id = +new Date();
          // eslint-disable-next-line
          while (files.some((file) => file.id === id)) {
            id++;
          }

          files.push({
            id,
            //@ts-ignore
            file: filesForLoad[index],
            name: filesForLoad[index].name,
            path: "",
          });
          maxCountAddImage--;
        }
        index++;
      }
      this.props.handleChange(files);
    }
  }

  private getFileType(file: File) {
    if (file.type) {
      return file.type;
    } else {
      const type = file.name.match(/\.([a-z]*)$/);
      if (type) {
        return type[1];
      }
      return "\\\\\\";
    }
  }

  private clear(event: SyntheticEvent) {
    event.stopPropagation();
    this.props.handleChange([]);
  }

  private remove(id: number) {
    this.props.files.filter((file) => {
      if (file.id === id) {
        if (file.path) {
          this.props.deleteFile(id);
        } else {
          this.props.handleChange(
            this.props.files.filter((file) => id !== file.id)
          );
          return false;
        }
      }
      return true;
    });
  }

  public render() {
    return (
      <div className="file-list-container">
        <div className="head">
          {this.props.files.length > 0 && (
            <button onClick={this.clear.bind(this)}>Очистить</button>
          )}
        </div>
        <DragAndDrop
          onClick={this.handleClick.bind(this)}
          onDrop={this.handleDrop.bind(this)}
        >
          <div className="files-list">
            <div className="add-file">
              <img src={AddFile} alt="" />
              <input
                type="file"
                multiple
                onChange={this.handleChange.bind(this)}
                ref={this.state.ref}
                itemType={this.props.types.join(";")}
                value=""
              />
              <h3 className="about-files">
                {this.props.files.length > 0
                  ? `Выбрано ${this.props.files.length} файлов`
                  : this.props.title}
              </h3>
            </div>

            {this.props.displayType === "list" && (
              <ListDisplay
                files={this.props.files}
                remove={this.remove.bind(this)}
              />
            )}
            {this.props.displayType === "image" && (
              <ImageDisplay
                images={this.props.files}
                remove={this.remove.bind(this)}
              />
            )}
          </div>
        </DragAndDrop>
      </div>
    );
  }
}

export default FileLoader;
