import { changeTokens, logout } from "../../store/actions/session";

import AuthService from "../../services/AuthService";
import store from "../../store/store";

class JWT {
  private authService: AuthService;
  private isRefresh: boolean;
  private refresh: Promise<string>;

  constructor() {
    this.authService = new AuthService();
    this.isRefresh = false;
    this.refresh = new Promise((resolve) => resolve(""));
  }
  public async getAuth(): Promise<string> {
    let tokens = store.getState().session.tokens;
    // eslint-disable-next-line
    if (!tokens) throw { status: false, data: ["Вы не авторизованы"] };
    if (!this.check(tokens.refresh)) {
      store.dispatch(logout());
      // eslint-disable-next-line
      throw { status: false, data: ["Вы не авторизованы"] };
    } else if (!this.check(tokens.access)) {
      return `Bearer ${await this.refreshToken(tokens.refresh)}`;
    } else {
      return `Bearer ${tokens.access}`;
    }
  }
  public async refreshToken(token: string): Promise<string> {
    if (!this.isRefresh) {
      this.isRefresh = true;
      this.refresh = this.authService.refresh({ token }).then((response) => {
        store.dispatch(changeTokens(response.data.session));
        this.isRefresh = false;
        return response.data.session.access;
      });
      await this.refresh;
      this.isRefresh = false;
    }

    return this.refresh
      .then((access) => {
        return access;
      })
      .catch(() => {
        store.dispatch(logout());
        // eslint-disable-next-line
        throw { status: false, data: ["Вы не авторизованы"] };
      });
  }
  public check(token: string) {
    try {
      const [, payload] = token.split(".");
      const decodedPayload: { exp: number } = JSON.parse(atob(payload));
      if (decodedPayload.exp < Date.now() / 1000 + 10) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }
}

export default new JWT();
