import React, { SyntheticEvent } from "react";

import "./ShadowBack.scss";

interface Props {
  onClick: () => void;
}

class ShadowBack extends React.Component<Props> {
  static defaultProps = {
    onClick() {}
  };

  private handleClick(event: SyntheticEvent) {
    if (event.target === event.currentTarget) {
      this.props.onClick();
    }
  }
  public render() {
    return (
      <div className="shadow-back" onClick={this.handleClick.bind(this)}>
        {this.props.children}
      </div>
    );
  }
}

export default ShadowBack;
