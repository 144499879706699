import { BRANDS, MONTHS, SENDINGS, YEARS } from "../../config";
import React, { SyntheticEvent } from "react";
import SmsService, { SmsListParams } from "../../services/SmsService";

import { AxiosError } from "axios";
import SmsSendings from "../../components/sms/smsSendings/SmsSendings";
import SmsSendingsFilters from "../../components/sms/smsSendings/smsSendingsFilters/SmsSendingsFilters";
import Time from "../../UI/utils/Time";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  activeList?: Array<number>;
  keyword?: boolean;
  brand?: boolean;
  year?: boolean;
  month?: boolean;
  sending?: boolean;
  tabs?: boolean;
  onClick?: (event: SyntheticEvent, smsSending: App.SmsSending) => void;
}
interface State {
  isEnd: boolean;
  loading: boolean;
  page: number;
  smsStatistic: Array<App.SmsSending>;

  keyword: string;
  brand: number | null;
  year: number | null;
  month: number | null;
  sending: number | null;
}

class SmsSendingsContainer extends React.Component<Props, State> {
  private smsService: SmsService;
  private delay: number;
  constructor(props: Props) {
    super(props);
    this.smsService = new SmsService();
    this.delay = 0;
    this.state = {
      isEnd: false,
      loading: false,
      page: 1,
      smsStatistic: [],

      keyword: "",
      brand: null,
      year: null,
      month: null,
      sending: null,
    };
  }

  public componentDidMount() {
    this.getSmsSending();
  }
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.page !== prevState.page) {
      this.getSmsSending();
    }
  }

  private async getSmsSending() {
    if (this.state.loading || this.state.isEnd) return;
    this.setState({ loading: true });
    const params: SmsListParams = {
      page: this.state.page,
      amount: 20,
    };

    if (this.state.keyword) {
      params.keyword = this.state.keyword;
    }
    if (this.state.brand) {
      params.brand = BRANDS.find((e) => e.id === this.state.brand)?.value;
    }
    if (this.state.year) {
      params.year = YEARS.find((e) => e.id === this.state.year)?.value;
    }
    if (this.state.month) {
      params.month = MONTHS.find((e) => e.id === this.state.month)?.value;
    }
    if (this.state.sending) {
      params.sending = SENDINGS.find((e) => e.id === this.state.sending)?.value;
    }

    this.smsService
      .getList(params)
      .then((response) => {
        this.setState({
          isEnd: response.data.length === 0,
          page: this.state.page + 1,
          smsStatistic:
            this.state.page === 1
              ? response.data
              : this.state.smsStatistic.concat(response.data),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Ошибка получения списка смс рассылок"]
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private handleChangeKeyword(keyword: string) {
    this.setState({ keyword });
    this.setDelay();
  }
  private handleChangeBrand(brand: number | null) {
    this.setState({ brand });
    this.setDelay();
  }

  private handleChangeYear(year: number | null) {
    this.setState({ year });
    this.setDelay();
  }

  private handleChangeMonth(month: number | null) {
    this.setState({ month });
    this.setDelay();
  }

  private handleChangeSending(sending: number | null) {
    this.setState({ sending });
    this.setDelay();
  }
  private setDelay() {
    clearTimeout(this.delay);
    this.delay = window.setTimeout(
      () => this.setState({ page: 1, isEnd: false }),
      Time.second
    );
  }

  public render() {
    return (
      <>
        <SmsSendingsFilters
          displayKeyword={!!this.props.keyword}
          displayBrand={!!this.props.brand}
          displayYear={!!this.props.year}
          displayMonth={!!this.props.month}
          displaySending={!!this.props.sending}
          displayTabs={!!this.props.tabs}
          keyword={this.state.keyword}
          brand={this.state.brand}
          year={this.state.year}
          month={this.state.month}
          sending={this.state.sending}
          disabled={this.state.loading}
          onChangeKeyword={this.handleChangeKeyword.bind(this)}
          onChangeBrand={this.handleChangeBrand.bind(this)}
          onChangeYear={this.handleChangeYear.bind(this)}
          onChangeMonth={this.handleChangeMonth.bind(this)}
          onChangeSending={this.handleChangeSending.bind(this)}
        />
        <SmsSendings
          activeList={this.props.activeList}
          onClick={this.props.onClick}
          smsStatistic={this.state.smsStatistic}
          loading={this.state.loading}
          loadMore={this.getSmsSending.bind(this)}
        />
      </>
    );
  }
}
export default SmsSendingsContainer;
