import Axios, {AxiosResponse} from "axios";
import JWT from "../utils/jwt/JWT";

export interface ParamsFilter {
  autocenter?: number;
  group?: number;
  client?: number;
}

export interface ParamsFilterForList extends ParamsFilter {
  page: number;
  sort: string;
  order: "ASC" | "DESC";
  keyword?: string;
  from: string;
  to: string;
}

export interface ParamsFilterForPlot extends ParamsFilter {
  from: number;
  to: number;
}

export interface ParamsFilterForPlotGroups extends ParamsFilterForPlot {
  incoming: string;
  outgoing: string;
}

class RecordService {
  public async saveFile(data: FormData): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.post("/records/create", data, {
      headers: {Authorization},
    }).then(() => {
      return {status: true, data: null};
    });
  }

  public async saveCallsInfo(
    records: Array<any>
  ): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/records/load-list",
      {
        records,
      },
      {
        headers: {Authorization},
      }
    ).then(() => {
      return {status: true, data: null};
    });
  }

  public async getMissedCalls(params: {
    autocenter?: number;
    group?: number;
    operator?: number;
    date?: number;
  }): Promise<App.ServiceResponse<Array<App.Record>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/records/missed-calls", {
      headers: {Authorization, DontDisplayLoader: true},
      params,
    }).then((response: AxiosResponse): App.ServiceResponse<Array<App.Record>> => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
}

export default RecordService;
