import "./SmsSendingHead.scss";

import BrandName from "../../../markers/brandName/BrandName";
import React from "react";
import SendingName from "../../../markers/sendingName/SendingName";

interface Props {
  brand: App.Brand;
  sending: App.SendingType;
  name: string;
}
interface State {}

class SmsSendingHead extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="sms-sending-head">
        <div className="name">{this.props.name}</div>
        <BrandName brand={this.props.brand} />
        <SendingName sending={this.props.sending} />
      </div>
    );
  }
}
export default SmsSendingHead;
