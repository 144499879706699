import React from "react";
import { Link } from "react-router-dom";

class LinkCell extends React.Component<UI.Table.LinkCellProps> {
  public render() {
    return (
      <td onMouseOver={this.props.onMouseOver} className={this.props.className}>
        <Link to={this.props.link}>{this.props.text}</Link>
      </td>
    );
  }
}

export default LinkCell;
