import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";
import Time from "../UI/utils/Time";

export interface SmsGroupsListParams {
  page: number;
  amount: number;
  keyword?: string;
}

export interface SmsGroupData {
  name: string;
  sms_sendings: Array<number>;
}

class SmsGroupService {
  public async getById(id: number): Promise<App.ServiceResponse<App.SmsGroup>> {
    const Authorization = await JWT.getAuth();
    return Axios.get(`/sms-sending-group/get/${id}`, {
      headers: { Authorization },
    }).then(
      (
        response: AxiosResponse<
          App.SmsGroup & {
            sms_sendings: Array<number>;
            datetime: number;
          }
        >
      ) => {
        response.data.date = new Date(response.data.datetime * Time.second);
        response.data.sendings = response.data.sms_sendings;
        // @ts-ignore
        delete response.data.datetime;
        // @ts-ignore
        delete response.data.sms_sendings;
        return {
          data: response.data,
          status: true,
        };
      }
    );
  }

  public async getList(
    params: SmsGroupsListParams
  ): Promise<App.ServiceResponse<Array<App.SmsGroup>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/sms-sending-groups", {
      params,
      headers: { Authorization },
    }).then((response: AxiosResponse<Array<App.SmsGroup>>) => {
      return {
        data: response.data.map((e: any) => {
          e.date = new Date(e.datetime * Time.second);
          e.sendings = e.sms_sendings;
          delete e.datetime;
          delete e.sms_sendings;
          return e;
        }),
        status: true,
      };
    });
  }

  public async create(
    data: SmsGroupData
  ): Promise<App.ServiceResponse<App.SmsGroup>> {
    const Authorization = await JWT.getAuth();
    return Axios.post("/sms-sending-group", data, {
      headers: { Authorization },
    }).then(
      (
        response: AxiosResponse<
          App.SmsGroup & {
            sms_sendings: Array<number>;
            datetime: number;
          }
        >
      ) => {
        response.data.date = new Date(response.data.datetime * Time.second);
        response.data.sendings = response.data.sms_sendings;
        // @ts-ignore
        delete response.data.datetime;
        // @ts-ignore
        delete response.data.sms_sendings;
        return {
          data: response.data,
          status: true,
        };
      }
    );
  }
  public async update(
    data: SmsGroupData,
    id: number
  ): Promise<App.ServiceResponse<App.SmsGroup>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(`/sms-sending-group/${id}`, data, {
      headers: { Authorization },
    }).then(
      (
        response: AxiosResponse<
          App.SmsGroup & {
            sms_sendings: Array<number>;
            datetime: number;
          }
        >
      ) => {
        response.data.date = new Date(response.data.datetime * Time.second);
        response.data.sendings = response.data.sms_sendings;
        // @ts-ignore
        delete response.data.datetime;
        // @ts-ignore
        delete response.data.sms_sendings;
        return {
          data: response.data,
          status: true,
        };
      }
    );
  }
  public async delete(id: number): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/sms-sending-group/${id}`, {
      headers: { Authorization },
    }).then(() => {
      return {
        status: true,
        data: null,
      };
    });
  }
}
export default SmsGroupService;
