import React from "react";
import RecordService from "../../../services/RecordService";
import store from "../../../store/store";
import {AxiosError} from "axios";
import {displayErrors} from "../../../store/actions/error";
import {selectAudio} from "../../../store/actions/audio";
import Time from "../../../UI/utils/Time";
import {Unsubscribe} from "redux";
import LabelWithButton from "../../../components/custom/labelWithButton/LabelWithButton";
import LabelDateTime from "../../../components/custom/datetime/LabelDateTime";
import LDSSpinner from "../../../UI/components/loaders/spinners/lds/LdsSpinner";
import MissedRecordsTable from "../../../components/records/missed/MissedRecordsTable";
import Select from "../../../components/custom/select/Select";
import AutocenterService from "../../../services/AutocenterService";

interface Props {
}

interface State {
  loading: boolean;
  date: Date;
  centers: Array<App.Autocenter>;
  selectedCenter: number;
  records: Array<App.Record>;
  active: string;
}

export default class MissedRecordsTableContainer extends React.Component<Props, State> {
  private centerService: AutocenterService;
  private recordService: RecordService;
  private unsubscribe: Unsubscribe;

  constructor(props: Props) {
    super(props);
    this.centerService = new AutocenterService();
    this.recordService = new RecordService();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    this.state = {
      loading: false,
      date: new Date(),
      centers: [],
      selectedCenter: 0,
      records: [],
      active: "",
    };
  }

  static columns: Array<UI.Table.Column> = [
    {name: "ID", sortFlag: "id"},
    {name: "", sortFlag: "type"},
    {name: "Номер абонента", sortFlag: "caller_number"},
    {name: "Внутренний", sortFlag: "number"},
    {name: "Дата", sortFlag: "date_call"},
    {name: "Начало", sortFlag: "time_start"},
    {name: "Время разговора", sortFlag: "duration"},
    {name: "Аудио", sortFlag: "is_audio"},
  ];

  public componentDidMount() {
    this.loadCenters();
    this.load();
  }

  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState({active: store.getState().audio.active});
  }

  private handleCenterSelect(selectedCenter: number | Array<number> | null) {
    if (typeof selectedCenter === "number")
      this.setState({selectedCenter})
  }

  private get params() {
    const params: any = {
      autocenter: this.state.selectedCenter,
      date: Math.floor(+this.state.date / Time.second),
    };
    if (!params.autocenter) delete params.autocenter;
    return params;
  }

  private async load(forcibly?: boolean) {
    if (!forcibly && this.state.loading)
      return;
    this.setState({loading: true});
    this.recordService.getMissedCalls(this.params)
      .then((response) => this.setState({records: response.data}))
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Ошибка загрузки данных"])
        );
      })
      .finally(() => this.setState({loading: false}));
  }

  private async loadCenters() {
    this.centerService.getList()
      .then((response) => this.setState({centers: response.data}))
      .catch((error: AxiosError<Array<string>>) => store.dispatch(displayErrors(error.response?.data || ["Ошибка загрузки данных"])));
  }

  private playAudio(audio?: string) {
    if (audio)
      store.dispatch(selectAudio(audio));
  }

  private changeDate(date: Date) {
    this.setState({date});
  }

  public render() {
    return <div className="missed-records-table-container">
      <div className="missed-records-table-search-controls">
        <LabelWithButton
          className="active"
          onClick={(event) => this.load()}
          disabled={this.state.loading}
          buttonText={"Искать по дате"}
        >
          <LabelDateTime
            label="Выберите день"
            selected={this.state.date}
            onChange={this.changeDate.bind(this)}
            dateFormat="dd.MM.yyyy"
            maxDate={new Date()}
          />
        </LabelWithButton>
        <Select
          label="Автоцентр"
          multiselect={false}
          options={this.state.centers}
          selected={this.state.selectedCenter}
          onChange={this.handleCenterSelect.bind(this)}
        />
      </div>
      <MissedRecordsTable
        records={this.state.records}
        playAudio={this.playAudio.bind(this)}
      />
      {
        this.state.loading && <div className="calls-for-1c-table-loader">
            <LDSSpinner/>
        </div>
      }
      <div className="amount-calls">
          <span>
            {Time.beautifyDate(this.state.date)}{" "}
            всего звонков:
          </span>
        <b>{this.state.records?.length || 0}</b>
      </div>
    </div>
  }
}