import "./SendingName.scss";

import React from "react";

interface Props {
  sending: App.SendingType;
}
interface State {}

class SendingName extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  private get sendingName(): string {
    switch (this.props.sending) {
      case "salon":
        return "Салон";
      case "service":
        return "Сервис";
      default:
        return "";
    }
  }
  public render() {
    return (
      <>
        {this.props.sending && (
          <div className="sending-name">{this.sendingName.toLowerCase()}</div>
        )}
      </>
    );
  }
}
export default SendingName;
