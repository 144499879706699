import React from "react";
import Time from "../../../utils/Time";
import "./Timer.scss";

interface ITimerProps {
  timestamp: number | Date;
  hideTitle?: boolean;
}
interface ITimerState {
  difference: UI.Time.Difference;
  nowDifference: number;
  animationFrameID: number;
}

class Timer extends React.Component<ITimerProps, ITimerState> {
  constructor(props: ITimerProps) {
    super(props);
    this.state = {
      nowDifference: 0,
      difference: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      animationFrameID: 0
    };
    this.setTimestamp = this.setTimestamp.bind(this);
  }

  public componentDidMount() {
    this.setState({
      animationFrameID: requestAnimationFrame(this.setTimestamp)
    });
  }

  public componentWillUnmount(): void {
    cancelAnimationFrame(this.state.animationFrameID);
  }

  private setTimestamp(): void {
    const nowDifference: number = +this.props.timestamp - +new Date();
    const days: number = Math.floor(nowDifference / Time.day);
    const hours: number = Math.floor((nowDifference % Time.day) / Time.hour);
    const minutes: number = Math.floor(
      ((nowDifference % Time.day) % Time.hour) / Time.minute
    );
    const seconds: number = Math.floor(
      (((nowDifference % Time.day) % Time.hour) % Time.minute) / Time.second
    );

    this.setState({
      nowDifference,
      difference: {
        days,
        hours,
        minutes,
        seconds
      }
    });

    if (days || hours || minutes || seconds) {
      this.setState({
        animationFrameID: requestAnimationFrame(this.setTimestamp)
      });
    }
  }

  public render() {
    return (
      <>
        {this.state.nowDifference > Time.second && (
          <div className="timer">
            {this.props.hideTitle || (
              <span className="additional">Осталось времени</span>
            )}

            {this.state.nowDifference / Time.day > 1 && (
              <div>
                <span className="value">{this.state.difference.days}</span>
                <span className="name">д</span>
              </div>
            )}
            {this.state.nowDifference / Time.hour > 1 && (
              <div>
                <span className="value">
                  {Time.toTwoSymbols(this.state.difference.hours)}
                </span>
                <span className="name">ч</span>
              </div>
            )}
            {this.state.nowDifference / Time.minute > 1 && (
              <div>
                <span className="value">
                  {Time.toTwoSymbols(this.state.difference.minutes)}
                </span>
                <span className="name">мин</span>
              </div>
            )}
            {this.state.nowDifference / Time.second > 1 && (
              <div>
                <span className="value">
                  {Time.toTwoSymbols(this.state.difference.seconds)}
                </span>
                <span className="name">сек</span>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default Timer;
