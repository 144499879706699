import "./SmsDetailsBodyInfo.scss";

import Box from "../../../custom/box/Box";
import BrandName from "../../../markers/brandName/BrandName";
import React from "react";
import SendingName from "../../../markers/sendingName/SendingName";
import SmsDetailsBodyInfoGroups from "./smsDetailsBodyInfoGroups/SmsDetailsBodyInfoGroups";
import Time from "../../../../UI/utils/Time";

interface Props {
  smsSending?: App.SmsSending;
}
interface State {}

class SmsDetailsBodyInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Box shadow={false} className="sms-details-body-info">
        <div className="text">
          <div className="message">{this.props.smsSending?.text}</div>
          {this.props.smsSending && (
            <div className="date">
              {Time.beautifyDateTime(this.props.smsSending.date)}
            </div>
          )}
        </div>
        {this.props.smsSending && (
          <div className="footer">
            <div className="markers">
              <BrandName brand={this.props.smsSending.brand} />
              <SendingName sending={this.props.smsSending.sending} />
            </div>
            <SmsDetailsBodyInfoGroups groups={this.props.smsSending.groups} />
          </div>
        )}
      </Box>
    );
  }
}
export default SmsDetailsBodyInfo;
