import "./LabelDateTime.scss";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import Calendar from "../assets/Calendar";
import React from "react";
import ru from "date-fns/locale/ru";

registerLocale("ru", ru);
setDefaultLocale("ru");

export default class LabelDateTime extends React.Component<
  ReactDatePickerProps & {
    label?: string;
  }
> {
  public render() {
    return (
      <label className={"datetime " + (this.props.className || "")}>
        {this.props.label && <div className="label">{this.props.label}</div>}
        <DatePicker {...this.props} />
        <div className="icon">
          <Calendar fill={"#959595"} stroke="none" />
        </div>
      </label>
    );
  }
}
