export default class AuthValidator {
  static login = {
    minLength: 3,
    maxLength: 36,
    regExp: /^[A-z0-9@._,*&?^:;#%$-]{3,36}$/,
  };
  static password = {
    minLength: 3,
    maxLength: 36,
    regExp: /^[A-z0-9@._,*&?^:;#%$-]{3,36}$/,
  };

  static isCorrectLogin(login: string): boolean {
    return (
      login.length >= AuthValidator.login.minLength &&
      login.length <= AuthValidator.login.maxLength &&
      !!login.match(AuthValidator.login.regExp)
    );
  }
  static isCorrectPassword(password: string): boolean {
    return (
      password.length >= AuthValidator.password.minLength &&
      password.length <= AuthValidator.password.maxLength &&
      !!password.match(AuthValidator.password.regExp)
    );
  }
  static isCorrectPasswordConfirmation(
    password: string,
    passwordConfirmation: string
  ): boolean {
    return (
      AuthValidator.isCorrectPassword(passwordConfirmation) &&
      password === passwordConfirmation
    );
  }
}
