import {
  AutocentersStore,
  autocenters,
  defaultAutocentersStore,
} from "./reducers/autocentersReducer";
import { HttpStore, defaultHttpStore, http } from "./reducers/httpReducer";
import audio, { AudioStore, defaultAudioStore } from "./reducers/audio";
import error, { ErrorStore, defaultErrorStore } from "./reducers/error";
import session, { SessionStore, defaultSessionStore } from "./reducers/session";
import undistributedExtensionNumbers, {
  UndistributedExtensionNumbersStore,
  defaultUndistributedExtensionNumbersStore
} from "./reducers/undistributedExtensionNumbers";

import { createStore } from "redux";

interface Store {
  audio: AudioStore;
  autocenters: AutocentersStore;
  error: ErrorStore;
  session: SessionStore;
  http: HttpStore;
  undistributedExtensionNumbers: UndistributedExtensionNumbersStore
}

const defaultStore: Store = {
  audio: defaultAudioStore,
  autocenters: defaultAutocentersStore,
  error: defaultErrorStore,
  session: defaultSessionStore,
  http: defaultHttpStore,
  undistributedExtensionNumbers: defaultUndistributedExtensionNumbersStore
};

export default createStore(function (
  state: Store = defaultStore,
  action: any
): Store {
  return {
    audio: audio(state.audio, action),
    autocenters: autocenters(state.autocenters, action),
    error: error(state.error, action),
    session: session(state.session, action),
    http: http(state.http, action),
    undistributedExtensionNumbers: undistributedExtensionNumbers(state.undistributedExtensionNumbers, action)
  };
});
