import React from "react";
import {RouteComponentProps} from "react-router-dom";
import Page from "../../UI/utils/Page";
import MissedRecordsTableContainer from "../../containers/records/missed/MissedRecordsTableContainer";
import './MissedRecords.scss';

export default class MissedRecords extends React.Component<RouteComponentProps> {
  public componentDidMount() {
    Page.setTitle("Потерянные аудио");
  }

  public render() {
    return <div className="missed-records">
      <MissedRecordsTableContainer/>
    </div>
  }
}