import AutocenterService from "../../services/AutocenterService";
import AutocentersLinks from "../../components/autocenter/autocentersLinks/AutocentersLinks";
import { AxiosError } from "axios";
import React from "react";
import { Unsubscribe } from "redux";
import { changeLoadedStatusAction } from "../../store/actions/autocentersActions";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {}
interface State {
  autocenters: Array<App.Autocenter>;
  isOpen: boolean;
  loading: boolean;
}

class AutocentersLinksContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  private autocentersService: AutocenterService;

  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.autocentersService = new AutocenterService();
    this.state = {
      isOpen: store.getState().autocenters.isOpenLinks,
      autocenters: [],
      loading: false
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    store.getState().session.user && this.loadAutocenters();
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private async loadAutocenters() {
    this.setState({
      loading: true
    })
    await this.autocentersService
      .getList()
      .then((response) => {
        store.dispatch(changeLoadedStatusAction(true));
        this.setState({ autocenters: response.data });
      })
      .catch((error: AxiosError<Array<string>>) => {
        displayErrors(
          error.response?.data || [
            "Не удалось получить список ваших автоцентров",
          ]
        );
      });
    this.setState({
      loading: false
    })
  }

  private async handleUpdateStore() {
    this.setState({
      isOpen: store.getState().autocenters.isOpenLinks,
    });
    if (!this.state.loading && !store.getState().autocenters.isLoaded)
      await this.loadAutocenters();
  }

  public render() {
    return (
      <AutocentersLinks
        isOpen={this.state.isOpen}
        autocenters={this.state.autocenters}
      />
    );
  }
}
export default AutocentersLinksContainer;
