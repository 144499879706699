import "./Tabs.scss";

import React from "react";

import Tab from "./components/tab/Tab";

export default class Tabs extends React.Component<UI.Tabs.Props.Tabs> {
  public render() {
    return (
      <div className="tabs">
        {this.props.tabs.map(tab => (
          <Tab
            key={tab.id}
            tab={tab}
            selected={this.props.selected === tab.id}
            onClick={this.props.onClick}
          />
        ))}
      </div>
    );
  }
}
