import "./CallsFor_1cTableRow.scss";

import PlayIcon from "../../../../../assets/play-for-poltys.png";
import React from "react";
import StringMethods from "../../../../../UI/utils/StringMethods";
import Time from "../../../../../UI/utils/Time";

interface Props {
  call: App.Call;
  playAudio: (link?: string) => void;
  getWaves: (extensions: Array<number>, call: number) => void;
  showPossibleRecords?: (callId: number) => void;
}
interface State {
  isOpen: boolean;
}

class CallsFor_1cTableRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    if (this.props.call.hasAudio) this.setState({ isOpen: true });
  }

  private handleClickOnArrow() {
    if (this.props.call.extensions.length === 0) return;
    this.setState({
      isOpen: !this.state.isOpen,
    });
    if (!this.props.call.hasAudio)
      this.props.getWaves(
        this.props.call.extensions.map((e) => e.id),
        this.props.call.id
      );
  }

  private getClassForCall(call: App.Call) {
    if (call.active_duration > 0) {
      return call.is_incoming ? "i" : "o";
    } else {
      return "l";
    }
  }

  private handleShowPossibleRecords(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    if (
      this.props.call.possible_records?.length &&
      this.props.showPossibleRecords
    )
      this.props.showPossibleRecords(this.props.call.id);
  }

  public render() {
    const activeDurationMinutes = Math.floor(
      this.props.call.call_duration / 60
    );
    const activeDurationSeconds = Math.floor(
      this.props.call.call_duration % 60
    );
    const comments = this.props.call.extensions
      .filter((e) => e.customer_request || e.agent_notes)
      .map((e) => e.customer_request || e.agent_notes);
    const extension_number_names = this.props.call.extensions.map(
      (e) => e.extension_number
    );
    return (
      <>
        <tr
          className="calls-for-1c-table-call"
          onClick={this.handleClickOnArrow.bind(this)}
          data-lost={
            this.props.call.is_incoming && this.props.call.active_duration === 0
          }
        >
          <td>{this.props.call.id}</td>
          <td>
            <div className={"img " + this.getClassForCall(this.props.call)} />
          </td>
          <td>{this.props.call.group}</td>
          <td>
            {StringMethods.beautifyPhoneNumber(this.props.call.caller_number)}
          </td>
          <td>
            {Time.beautifyDate(this.props.call.start_timestamp * Time.second)}
          </td>
          <td>
            {Time.beautifyTime(this.props.call.start_timestamp * Time.second)}
          </td>
          <td>
            {Time.beautifyTime(this.props.call.end_timestamp * Time.second)}
          </td>
          <td>{this.props.call.call_duration > 0 ? "Да" : "Нет"}</td>
          <td className="extensions-number">
            <div>
              {extension_number_names.length > 0 ? (
                <>
                  <span>{extension_number_names.length}шт.: </span>
                  <span>{extension_number_names.join(", ")}</span>
                </>
              ) : (
                "-"
              )}
            </div>
          </td>
          <td>{this.props.call.wait_duration} сек.</td>
          <td>
            {activeDurationMinutes > 9
              ? activeDurationMinutes
              : "0" + activeDurationMinutes}
            :
            {activeDurationSeconds > 9
              ? activeDurationSeconds
              : "0" + activeDurationSeconds}
          </td>
          <td>{this.props.call.hold_duration} сек.</td>
          <td className="comment">
            {comments.length > 0 ? (
              <div>
                <span>{comments.length}шт.: </span>{" "}
                <span>{comments.join(", ")}</span>
              </div>
            ) : (
              "-"
            )}
          </td>
          <td>
            {this.props.call.extensions.length > 0 &&
              (this.state.isOpen ? "Свернуть" : "Развернуть")}
          </td>
          <td
            data-content={this.props.call.extensions.length > 0}
            data-arrow={this.state.isOpen ? "opened" : "closed"}
          ></td>
        </tr>
        {this.state.isOpen &&
          this.props.call.extensions.map((e) => {
            const activeDurationMinutes = Math.floor(e.active_duration / 60);
            const activeDurationSeconds = Math.floor(e.active_duration % 60);
            return (
              <tr
                key={e.id}
                className="calls-for-1c-table-extension"
                data-missing={e.active_duration === 0}
              >
                <td>{}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{Time.beautifyDate(e.start_timestamp * Time.second)}</td>
                <td>{Time.beautifyTime(e.start_timestamp * Time.second)}</td>
                <td>{Time.beautifyTime(e.end_timestamp * Time.second)}</td>
                <td></td>
                <td className="extensions-number">
                  <div>{e.extension_number_name}</div>
                </td>
                <td>{e.wait_duration} сек.</td>
                <td>
                  {activeDurationMinutes > 9
                    ? activeDurationMinutes
                    : "0" + activeDurationMinutes}
                  :
                  {activeDurationSeconds > 9
                    ? activeDurationSeconds
                    : "0" + activeDurationSeconds}
                </td>
                <td>{e.hold_duration} сек.</td>
                <td className="comment">
                  <div>
                    <span>{e.customer_request || e.agent_notes || "-"}</span>
                  </div>
                </td>
                <td>
                  {e.wave ? (
                    <img
                      onClick={() => this.props.playAudio(e.wave || undefined)}
                      src={PlayIcon}
                      alt=""
                    />
                  ) : e.wave === null ? (
                    <span>Загрузка</span>
                  ) : (
                    <span>Нет</span>
                  )}
                </td>
                <td></td>
              </tr>
            );
          })}
        {this.state.isOpen && this.props.call.possible_records?.length ? (
          <tr className="calls-for-1c-table-extension">
            <td colSpan={15}>
              <div className="show-possible-records-button-container">
                <button
                  className="show-possible-records-button"
                  onClick={this.handleShowPossibleRecords.bind(this)}
                >
                  Возможные аудио
                  <div className="show-possible-records-help">
                    Аудиозаписи, которые не были распределены, но могут
                    относится к данному звонку.
                  </div>
                </button>
              </div>
            </td>
          </tr>
        ) : (
          <></>
        )}
      </>
    );
  }
}
export default CallsFor_1cTableRow;
