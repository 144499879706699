import "./index.scss";

import * as serviceWorker from "./serviceWorker";

import Axios, { AxiosError } from "axios";
import { BASE_URL, TIMEOUT } from "./config";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  closeRequestAction,
  openRequestAction,
} from "./store/actions/httpActions";

import AudioContainer from "./containers/audio/AudioContainer";
import AutocentersLinksContainer from "./containers/autocentersLinks/AutocentersLinksContainer";
import CallsFor_1cContainer from "./containers/calls/callsFor_1c/CallsFor_1cContainer";
import ErrorWindowContainer from "./containers/popups/ErrorWindowContainer";
import NavMenu from "./containers/navMenu/NavMenuContainer";
import PageLoader from "./containers/pageLoader/PageLoader";
import React from "react";
import ReactDOM from "react-dom";
import View from "./View";
import { callsFor1C } from "./routes";
import store from "./store/store";

initAxios();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path={callsFor1C} component={CallsFor_1cContainer}></Route>
        <Route>
          <Route component={NavMenu} />
          <Route component={AutocentersLinksContainer} />
          <Route component={View} />
        </Route>
      </Switch>
      <ErrorWindowContainer />
      <AudioContainer />
      <PageLoader />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();

function initAxios() {
  Axios.defaults.baseURL = BASE_URL;
  Axios.defaults.timeout = TIMEOUT;

  Axios.interceptors.request.use(
    (config) => {
      if (!config.headers?.DontDisplayLoader) {
        store.dispatch(openRequestAction());
      }
      return config;
    },
    (error) => {
      store.dispatch(openRequestAction());
      return error;
    }
  );
  Axios.interceptors.response.use(
    (response) => {
      if (!response.config.headers?.DontDisplayLoader) {
        store.dispatch(closeRequestAction());
      }
      return response;
    },
    (error: AxiosError<any>) => {
      if (!error.config.headers?.DontDisplayLoader) {
        store.dispatch(closeRequestAction());
      }
      store.dispatch(closeRequestAction());
      throw error;
    }
  );
}
