import "./CallsFor_1cTableHead.scss";

import React from "react";

interface Props {}
interface State {}

class CallsFor_1cTableHead extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <thead className="calls-for-1c-table-head">
        <tr>
          <th>ID</th>
          <th></th>
          <th>Куда пришел</th>
          <th>Номер абонента</th>
          <th>Дата</th>
          <th>Начало</th>
          <th>Конец</th>
          <th>Ответ</th>
          <th>Внутренний</th>
          <th>Время ожидания</th>
          <th>Время разговора</th>
          <th>Время удержания</th>
          <th>Примечание</th>
          <th>Аудио</th>
          <th></th>
        </tr>
      </thead>
    );
  }
}
export default CallsFor_1cTableHead;
