interface OpenRequestAction {
  type: "OpenRequestAction";
}
interface CloseRequestAction {
  type: "CloseRequestAction";
}

export type Actions = OpenRequestAction | CloseRequestAction;

export function openRequestAction(): OpenRequestAction {
  return {
    type: "OpenRequestAction",
  };
}
export function closeRequestAction(): CloseRequestAction {
  return {
    type: "CloseRequestAction",
  };
}
