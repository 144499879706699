import React, { SyntheticEvent } from "react";

import PickedIcon from "../../assets/checkmark.svg";

interface Props {
  multiselect?: boolean;
  selected: boolean;
  option: { id: number; name: string };
  onClick: (id: number, isSelect: boolean) => void;
}
interface State {
  isOpen: boolean;
}

class SelectOption extends React.Component<Props, State> {
  private handleClick(event: SyntheticEvent) {
    if (this.props.multiselect) {
      event.stopPropagation();
    }
    this.props.onClick(this.props.option.id, this.props.selected);
  }
  public render() {
    return (
      <div className="option" onClick={this.handleClick.bind(this)}>
        <div className="mark">
          {this.props.selected && <img src={PickedIcon} alt="" />}
        </div>
        <span>{this.props.option.name}</span>
      </div>
    );
  }
}

export default SelectOption;
