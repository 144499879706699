import { AxiosError } from "axios";
import PhoneForm from "../../components/phone/form/PhoneForm";
import PhoneRedact from "../../components/phone/redact/PhoneRedact";
import PhoneService from "../../services/PhoneService";
import Question from "../../UI/components/popups/question/Question";
import React from "react";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  phone: App.Phone;
  index: number;
  onDelete: (id: number) => void;
  onStartMove: (index: number) => void;
  onMouseEnter: (index: number) => void;
  onEndMove: () => void;
  updateCurrentPhone: (phone: App.Phone) => void;
}
interface State {
  isOpenForm: boolean;
  isOpenQuestion: boolean;
}

class PhoneRedactContainer extends React.Component<Props, State> {
  private phoneService: PhoneService;
  constructor(props: Props) {
    super(props);
    this.phoneService = new PhoneService();
    this.state = {
      isOpenForm: false,
      isOpenQuestion: false,
    };
  }

  private handleRedact() {
    this.setState({ isOpenForm: true });
  }
  private handleTryDelete() {
    this.setState({ isOpenQuestion: true });
  }

  private handleUpdate(phone: App.PhoneData) {
    phone.weight = this.props.index;
    this.phoneService
      .update(phone, this.props.phone.id)
      .then((response) => {
        this.setState({ isOpenForm: false });
        this.props.updateCurrentPhone(response.data);
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось редактировать номер"]
          )
        );
      });
  }
  private handleDelete() {
    this.phoneService
      .delete(this.props.phone.id)
      .then(() => {
        this.setState({ isOpenQuestion: false });
        this.props.onDelete(this.props.phone.id);
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось удалить номер"])
        );
      });
  }

  private handleClose() {
    this.setState({
      isOpenForm: false,
      isOpenQuestion: false,
    });
  }

  public render() {
    return (
      <>
        <PhoneRedact
          index={this.props.index}
          phone={this.props.phone}
          onStartMove={this.props.onStartMove}
          onMouseEnter={this.props.onMouseEnter}
          onEndMove={this.props.onEndMove}
          onTryRedact={this.handleRedact.bind(this)}
          onTryDelete={this.handleTryDelete.bind(this)}
          updateCurrentPhone={this.props.updateCurrentPhone}
        />
        {this.state.isOpenForm && (
          <PhoneForm
            phone={this.props.phone}
            submit={this.handleUpdate.bind(this)}
            close={this.handleClose.bind(this)}
          />
        )}
        {this.state.isOpenQuestion && (
          <Question
            question="Вы действительно хотите удалить телефон?"
            onDisagree={this.handleClose.bind(this)}
            onAgree={this.handleDelete.bind(this)}
          />
        )}
      </>
    );
  }
}

export default PhoneRedactContainer;
