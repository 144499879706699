import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";

export interface TrunkData {
  name: string;
  code: number;
}

class TrunkService {
  public async getList(): Promise<App.ServiceResponse<Array<App.Trunk>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/trunks", {
      headers: { Authorization },
    }).then((response: AxiosResponse<Array<App.Trunk>>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
  public async create(
    data: TrunkData
  ): Promise<App.ServiceResponse<App.Trunk>> {
    const Authorization = await JWT.getAuth();
    return Axios.post("/trunk/create", data, {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Trunk>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
  public async update(
    id: number,
    data: TrunkData
  ): Promise<App.ServiceResponse<App.Trunk>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(`/trunk/update/${id}`, data, {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Trunk>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
  public async delete(id: number): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/trunk/delete/${id}`, {
      headers: { Authorization },
    }).then(() => {
      return {
        status: true,
        data: null,
      };
    });
  }
}

export default TrunkService;
