import React from "react";
import Time from "../../../../../UI/utils/Time";
import PlayIcon from "../../../../../assets/play-for-poltys.png";
import "./CallsFor_1cTablePossibleRecords.scss";

interface Props {
  call: App.Call;
  playAudio: (link?: string) => void;
  isOpen: boolean;
  hide: () => void;
}

export default class CallsFor_1cTablePossibleRecords extends React.Component<
  Props,
  any
> {
  private handleClose(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.hide();
  }

  render() {
    return (
      <div
        className="possible-records-container"
        onClick={this.handleClose.bind(this)}
      >
        <div
          onClick={(event) => event.stopPropagation()}
          className="possible-records-table-container"
        >
          <div className="possible-records-title">
            <div className="call-id">Звонок №{this.props.call.id}</div>
            <div
              className="close-button"
              onClick={this.handleClose.bind(this)}
            />
          </div>
          <table>
            <thead>
              <tr>
                <td colSpan={6}>Возможные аудиозаписи для данного звонка</td>
              </tr>
              <tr>
                <td>Время</td>
                <td>Номер абонента</td>
                <td>Внутренний</td>
                <td>Длительность</td>
                <td>Аудио</td>
              </tr>
            </thead>
            <tbody>
              {this.props.call.possible_records.map((r, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {Time.beautifyTime(r.start_timestamp * Time.second)}
                    </td>
                    <td>{r.caller_number}</td>
                    <td>{r.extension_number}</td>
                    <td>
                      {/^[0-9]+$/.test(String(r.duration))
                        ? `${r.duration} сек.`
                        : r.duration}
                    </td>
                    <td>
                      <img
                        onClick={() => this.props.playAudio(r.url || undefined)}
                        src={PlayIcon}
                        alt=""
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
