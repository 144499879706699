import "./SmsStatisticByDay.scss";

import React from "react";
import TriangleMark from "../../../../../assets/triangleMark/TriangleMark";

interface Props {
  amount: number;
  percent: number;
  name: string;
  color: string;
}
interface State {}

class SmsStatisticByDay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="sms-statistic-by-day">
        <div
          className="progress"
          style={{
            height: `${this.props.percent}%`,
            backgroundColor: this.props.color,
          }}
        >
          <div className="percent">{this.props.percent}%</div>
          <div className="amount">
            <div className="number">{this.props.amount}</div>
            <TriangleMark />
          </div>
        </div>
        <div className="name">{this.props.name}</div>
      </div>
    );
  }
}
export default SmsStatisticByDay;
