import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";
import StringMethods from "../UI/utils/StringMethods";
import Time from "../UI/utils/Time";
import { serialize } from "object-to-formdata";

export interface SmsListParams {
  page: number;
  amount: number;
  keyword?: string;
  brand?: string;
  year?: string;
  month?: string;
  sending?: string;
}

export interface AddSmsFileData {
  name: string;
  date: Date;
  brand: string;
  sending: string | null;
  text: string;
  file: App.IFile;
}

class SmsService {
  public async getById(
    id: number
  ): Promise<App.ServiceResponse<App.SmsSending>> {
    const Authorization = await JWT.getAuth();
    return Axios.get(`/sms-sending/get/${id}`, {
      headers: { Authorization },
    }).then((response: AxiosResponse<any>) => {
      response.data.date = new Date(response.data.datetime);
      delete response.data.datetime;
      return {
        status: true,
        data: response.data,
      };
    });
  }

  public async getList(
    params: SmsListParams
  ): Promise<App.ServiceResponse<Array<App.SmsSending>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/sms-sendings", {
      params,
      headers: { Authorization },
    }).then((response: AxiosResponse<Array<any>>) => {
      return {
        status: true,
        data: response.data.map((e: any) => {
          e.date = new Date(e.datetime);
          delete e.datetime;
          return e;
        }),
      };
    });
  }

  public async update(
    data: App.SmsSendingData,
    id: number
  ): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.put(`/sms-sending/${id}`, data, {
      headers: { Authorization },
    }).then(() => {
      return {
        status: true,
        data: null,
      };
    });
  }
  public async delete(id: number) {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/sms-sending/${id}`, {
      headers: { Authorization },
    }).then(() => {
      return {
        status: true,
        data: null,
      };
    });
  }

  public async addFile(
    data: AddSmsFileData
  ): Promise<App.ServiceResponse<Array<string> | null>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/sms-sendings/load",
      serialize({
        ...data,
        file: data.file.file,
        datetime: `${data.date.getFullYear()}-${(
          "0" +
          (data.date.getMonth() + 1)
        ).slice(-2)}-${("0" + data.date.getDate()).slice(-2)} ${(
          "0" + data.date.getHours()
        ).slice(-2)}:${("0" + data.date.getMinutes()).slice(-2)}`,
      }),
      { headers: { Authorization } }
    ).then((response: AxiosResponse<Array<string> | null>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
  private parseRecords(records: Array<any>): Array<App.Info> {
    return records.reduce((g, record) => {
      let type = "accept";
      if (
        (record.extensions || []).every(
          (e: { no_answer: 1 | 0 }) => e.no_answer === 1
        )
      ) {
        type = "loss";
      }
      g.push({
        id: "record-" + record.id,
        ID: record.id,
        isIncoming: record.is_incoming ? true : false,
        type,
        group: record.group || "",
        number: record.caller_number
          ? StringMethods.beautifyPhoneNumber(record.caller_number.slice(-9))
          : "",
        startDate: Time.beautifyDate(record.start_timestamp * Time.second || 0),
        startTime: Time.beautifyTime(record.start_timestamp * Time.second || 0),
        endTime: Time.beautifyTime(record.end_timestamp * Time.second || 0),
        operator: "",
        waitTime: (record.wait_duration || 0) + " сек.",
        callTime: Time.getDuration(
          (record.active_duration || 0) * Time.second || 0
        ),
        holdTime: (record.hold_duration || 0) + " сек.",
        description: [record.note_1, record.note_2, record.note_3]
          .join(" ")
          .trim(),
        audio: "",
        includeMissing: (record.extensions || []).some(
          (e: { no_answer: 1 | 0 }) => e.no_answer === 1
        ),
        isExtension: false,
        loadedAudio: true,
      });

      (record.extensions || []).forEach((extension: any) => {
        let extType = type;
        if (extType !== "loss") {
          extType = extension.no_answer ? "missing" : "accept";
        }
        g.push({
          id: extension.id,
          ID: "",
          isIncoming: record.is_incoming ? true : false,
          type: extType,
          group: extension.group || "",
          number: "",
          startDate: Time.beautifyDate(
            extension.start_timestamp * Time.second || 0
          ),
          startTime: Time.beautifyTime(
            extension.start_timestamp * Time.second || 0
          ),
          endTime: Time.beautifyTime(
            extension.end_timestamp * Time.second || 0
          ),
          operator: extension.extension_number,
          waitTime: (extension.wait_duration || 0) + " сек.",
          callTime: Time.getDuration(
            (extension.active_duration || 0) * Time.second
          ),
          holdTime: (extension.hold_duration || 0) + " сек.",
          description: [extension.agent_notes, extension.customer_request]
            .join(" ")
            .trim(),
          audio: "",
          includeMissing: false,
          isExtension: true,
          loadedAudio: false,
        });
      });
      return g;
    }, []);
  }
}
export default SmsService;
