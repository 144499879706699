import "./Trunk.scss";

import DeleteIcon from "../../../../UI/assets/icons/delete/Delete";
import React from "react";
import RedactIcon from "../../../../assets/redact.png";

interface Props {
  id: number;
  name: string;
  code: number;

  update: () => void;
  delete: () => void;
}
interface State {}

class Trunk extends React.Component<Props, State> {
  public render() {
    return (
      <li className="trunk-item">
        <div className="info">
          <div className="name">{this.props.name}</div>
          <div className="code">{this.props.code}</div>
        </div>
        <div className="buttons">
          <img
            src={RedactIcon}
            alt="R"
            title="Редактировать"
            onClick={this.props.update}
          />

          <DeleteIcon
            title="Удалить"
            onClick={this.props.delete}
            fill="#aa0000"
            stroke="#aa0000"
          />
        </div>
      </li>
    );
  }
}

export default Trunk;
