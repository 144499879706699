import { AxiosError } from "axios";
import LastSmsList from "../../components/sms/lastSms/LastSmsList";
import React from "react";
import SmsService from "../../services/SmsService";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {}
interface State {
  statistic: Array<App.SmsSending>;
}

class LastSmsListContainer extends React.Component<Props, State> {
  private smsService: SmsService;
  constructor(props: Props) {
    super(props);
    this.smsService = new SmsService();
    this.state = {
      statistic: [],
    };
  }

  public componentDidMount() {
    this.getSmsSending();
  }

  private getSmsSending() {
    this.smsService
      .getList({
        page: 1,
        amount: 3,
      })
      .then((response) => {
        this.setState({
          statistic: response.data,
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || [
              "Ошибка получения списка последних смс рассылок",
            ]
          )
        );
      });
  }

  public render() {
    return <LastSmsList statistic={this.state.statistic} />;
  }
}

export default LastSmsListContainer;
