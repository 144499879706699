import "./WeekPicker.scss";

import LabelDateTime from "../datetime/LabelDateTime";
import React from "react";

interface Props {
  selected?: Date;
  label?: string;
  className?: string;

  onChange: (date: Date) => void;
}
interface State {
  activeYear: number;
}

class WeekPicker extends React.Component<Props, State> {
  private interval: number;
  constructor(props: Props) {
    super(props);
    this.interval = 1;
    this.state = {
      activeYear: new Date().getFullYear(),
    };
  }

  public componentDidMount() {
    this.interval = window.setInterval(this.indicateWeek.bind(this), 100);
  }

  public componentWillUnmount() {
    window.clearInterval(this.interval);
    this.unpick();
  }

  private handleChange(date: Date | null) {
    if (!date) return;
    this.props.onChange(date);
  }

  private indicateWeek() {
    this.unpick();
    this.pick();
  }
  private pick() {
    // @ts-ignore
    const selectedDays: NodeList<HTMLDivElement> = document.querySelectorAll(
      ".react-datepicker__day--selected"
    );
    for (let day of selectedDays) {
      day.parentNode.classList.add("active-week");
    }
  }
  private unpick() {
    // @ts-ignore
    const weeks: NodeList<HTMLDivElement> = document.querySelectorAll(
      ".react-datepicker__week"
    );
    for (let week of weeks) {
      week.classList.remove("active-week");
    }
  }

  public render() {
    return (
      <LabelDateTime
        {...this.props}
        className="week-picker"
        onChange={this.handleChange.bind(this)}
        dateFormat={"dd.MM.yyyy"}
      />
    );
  }
}

export default WeekPicker;
