import { AxiosError } from "axios";
import PhoneService from "../../services/PhoneService";
import PhonesList from "../../components/phone/list/PhonesList";
import React from "react";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  redact: boolean;
  group: number;
  active?: number;
  phones?: Array<App.Phone>;
}

interface State {
  loading: boolean;
  phones: Array<App.Phone>;
}

class PhonesListContainer extends React.Component<Props, State> {
  private phoneService: PhoneService;
  constructor(props: Props) {
    super(props);
    this.phoneService = new PhoneService();

    this.state = {
      loading: false,
      phones: props.phones || [],
    };
  }

  public componentDidMount() {
    if (!this.props.phones) {
      this.loadPhones();
    }
  }
  public componentDidUpdate(prevProps: Props) {
    if (!this.props.phones && this.props.group !== prevProps.group) {
      this.loadPhones();
    }
  }

  private loadPhones() {
    this.phoneService
      .getList({
        group: this.props.group,
      })
      .then((response) => {
        this.setState({
          phones: response.data.sort((a, b) => (a.weight > b.weight ? 1 : -1)),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось загрузить номер"])
        );
      });
  }

  private create(phone: App.PhoneData): Promise<boolean> {
    phone.weight = this.state.phones.length;
    return this.phoneService
      .create(phone, this.props.group)
      .then((response) => {
        this.handleCreate(response.data);
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось добавить номер"])
        );
        return false;
      });
  }
  private handleCreate(data: App.Phone): void {
    this.setState({
      phones: this.state.phones
        .concat(data)
        .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
    });
  }
  private async handleUpdate(
    value: App.PhoneData,
    id: number
  ): Promise<boolean> {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.phoneService
      .update(value, id)
      .then((response) => {
        this.setState({
          phones: this.state.phones
            .map((a) => (a.id === response.data.id ? response.data : a))
            .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
          loading: false,
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось редактировть номер"]
          )
        );
        this.setState({ loading: false });
        return false;
      });
  }

  private updateCurrentPhone(phone: App.Phone) {
    this.setState({
      phones: this.state.phones
        .map((a) => (a.id === phone.id ? phone : a))
        .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
    });
  }
  private handleDelete(id: number) {
    this.setState({
      phones: this.state.phones.filter((a) => a.id !== id),
    });
  }

  public render() {
    return (
      <PhonesList
        active={this.props.active}
        redact={this.props.redact}
        phones={this.state.phones}
        loading={this.state.loading}
        onUpdate={this.handleUpdate.bind(this)}
        onCreate={this.create.bind(this)}
        onDelete={this.handleDelete.bind(this)}
        updateCurrentPhone={this.updateCurrentPhone.bind(this)}
      />
    );
  }
}

export default PhonesListContainer;
