import "./LabelFile.scss";

import React, { SyntheticEvent } from "react";

interface Props {
  value: File | string | null;
  onChange: (file: File | null) => void;
  label?: string;
}
interface State {
  isDragEnter: boolean;
  image?: string;
}

class LabelFile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDragEnter: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.presentImage(this.props.value);
    }
  }
  private presentImage(file: File | string | null) {
    if (!file || typeof file === "string") {
      this.setState({ image: file || undefined });
    } else {
      new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          if (fr.result) {
            resolve(fr.result);
          } else {
            reject();
          }
        };
      })
        .then((result: unknown): void => {
          this.setState({ image: "" + result });
        })
        .catch((): void => {
          this.setState({ image: "ERROR" });
        });
    }
  }

  private handleChange(event: SyntheticEvent<HTMLInputElement>) {
    const file = event.currentTarget.files
      ? event.currentTarget.files[0]
      : null;
    this.props.onChange(file && file.type.includes("image") ? file : null);
  }
  private handleDrop(event: SyntheticEvent<HTMLDivElement, DragEvent>) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isDragEnter: false });
    if (
      event.nativeEvent.dataTransfer &&
      event.nativeEvent.dataTransfer.files.length > 0
    ) {
      const file = event.nativeEvent.dataTransfer.files[0];
      this.props.onChange(file.type.includes("image") ? file : null);
    }
  }

  private handleDragEnter() {
    this.setState({ isDragEnter: true });
  }
  private handleDragLeave() {
    this.setState({ isDragEnter: false });
  }
  private handleDragOver(event: SyntheticEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
  }
  public render() {
    return (
      <label className="label-file">
        {this.props.label && <div className="label">{this.props.label}</div>}
        <div
          className="drag-container"
          data-drag={this.state.isDragEnter}
          onDragEnter={this.handleDragEnter.bind(this)}
          onDragLeave={this.handleDragLeave.bind(this)}
          onDragOver={this.handleDragOver.bind(this)}
          onDrop={this.handleDrop.bind(this)}
        >
          <input
            type="file"
            name=""
            id=""
            style={{ display: "none" }}
            onChange={this.handleChange.bind(this)}
          />
          {this.state.image ? (
            <img src={this.state.image} alt="" />
          ) : (
            <div className="how-it-work">
              Перетащите файл сюда или кликните чтобы загрузить его
            </div>
          )}
        </div>
      </label>
    );
  }
}
export default LabelFile;
