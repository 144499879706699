import "./View.scss";

import {Route, RouteComponentProps, Switch} from "react-router";
import {
  authPath,
  autocenterPath,
  autocentersPath,
  faqPath,
  groupPath,
  homePath,
  loadPath,
  operatorPath,
  searchPath,
  smsGroupPath,
  smsGroupsPath,
  smsPath,
  smsesPath,
  trunksPath,
  usersPath,
  undistributedExtensionNumber, missedRecords,
} from "./routes";

import AuthView from "./views/auth/Auth";
import AutocenterPage from "./views/autocenter/Autocenter";
import AutocentersView from "./views/autocenters/Autocenters";
import FaqView from "./views/faqView/FaqView";
import Home from "./views/home/Home";
import LoadFiles from "./views/loadFiles/LoadFiles";
import NotFound from "./views/notFound/NotFound";
import React from "react";
import Search from "./views/search/Search";
import SmsDetailsView from "./views/smsDetails/SmsDetailsView";
import SmsGroupView from "./views/smsGroup/SmsGroupView";
import SmsGroupsView from "./views/smsGroups/SmsGroupsView";
import SmsSendingsView from "./views/smsSendings/SmsSendingsView";
import TrunksViews from "./views/trunks/Trunks";
import Users from "./views/users/Users";
import {logout} from "./store/actions/session";
import store from "./store/store";
import UndistributedExtensionNumberView from "./views/undistributedExtensionNumber/UndistributedExtensionNumberView";
import MissedRecords from "./views/missedRecords/MissedRecords";

type Props = RouteComponentProps;

interface State {
  started: boolean;
}

class View extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      started: false,
    };
  }

  public componentDidMount() {
    store.subscribe(this.watchFromAuth.bind(this));
    if (!store.getState().session.user) {
      store.dispatch(logout());
    }
    this.setState({started: true});
  }

  private watchFromAuth() {
    const {user} = store.getState().session;
    if (!user && this.props.location.pathname !== authPath) {
      this.props.history.replace(authPath);
    }
  }

  public render() {
    return (
      <div className="App">
        {this.state.started && (
          <Switch>
            <Route path={authPath} component={AuthView}/>
            <Route path={autocentersPath} component={AutocentersView}/>
            <Route exact path={autocenterPath} component={AutocenterPage}/>
            <Route path={operatorPath} component={AutocenterPage}/>
            <Route path={groupPath} component={AutocenterPage}/>
            <Route path={homePath} exact component={Home}/>
            <Route path={loadPath} component={LoadFiles}/>
            <Route path={searchPath} component={Search}/>
            <Route path={smsGroupPath} exact component={SmsGroupView}/>
            <Route path={smsGroupsPath} component={SmsGroupsView}/>
            <Route path={smsesPath} exact component={SmsSendingsView}/>
            <Route path={smsPath} component={SmsDetailsView}/>
            <Route path={trunksPath} component={TrunksViews}/>
            <Route path={usersPath} component={Users}/>
            <Route path={faqPath} component={FaqView}/>
            <Route exact path={missedRecords} component={MissedRecords}/>
            <Route path={undistributedExtensionNumber} component={UndistributedExtensionNumberView}/>
            <Route component={NotFound}/>
          </Switch>
        )}
      </div>
    );
  }
}

export default View;
