import "./FaqQuestion.scss";

import React, { SyntheticEvent } from "react";

import RedactIcon from "../../assets/redact.png";
import RemoveIcon from "../../assets/remove.png";
import marked from "marked";

interface Props {
  question: App.Faq;
  isAdmin?: boolean;
  isOpen: boolean;
  onClick: (id: number) => void;
  openRedact: (faq: App.Faq) => void;
  openDelete: (id: number) => void;
}
interface State {}

class FaqQuestion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private toggleResponse(event: SyntheticEvent) {
    if (event.target === event.currentTarget) {
      this.props.onClick(this.props.question.id);
    } else if (!this.props.isOpen) {
      this.props.onClick(this.props.question.id);
    }
  }
  public render() {
    return (
      <div className="faq-question" onClick={this.toggleResponse.bind(this)}>
        <div className="question" onClick={this.toggleResponse.bind(this)}>
          <span onClick={this.toggleResponse.bind(this)}>
            {this.props.question.question}
          </span>

          {this.props.isAdmin && (
            <div className="buttons">
              <img
                src={RedactIcon}
                alt="r"
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.openRedact(this.props.question);
                }}
              />
              <img
                src={RemoveIcon}
                alt="d"
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.openDelete(this.props.question.id);
                }}
              />
            </div>
          )}
        </div>
        {this.props.isOpen && (
          <div className="answer">
            <div
              dangerouslySetInnerHTML={{
                __html: marked(this.props.question.answer),
              }}
            ></div>
            {this.props.question.image && (
              <img src={this.props.question.image} alt="" />
            )}
          </div>
        )}
      </div>
    );
  }
}
export default FaqQuestion;
