import "./CallsPlot.scss";

import Box from "../../custom/box/Box";
import CallsPlotFilters from "./filters/CallsPlotFilters";
import CallsPlotGraphContainer from "../../../containers/calls/CallsPlotGraphContainer";
import CallsPlotTabs from "./tabs/CallsPlotTabs";
import CallsStatisticContainer from "../../../containers/calls/CallsStatisticContainer";
import { NavLink } from "react-router-dom";
import React from "react";
import Time from "../../../UI/utils/Time";

interface Props {
  autocenters?: Array<App.Autocenter>;
  onChangeAutocenter: (autocenter: number | null) => void;
  autocenter?: number;
  group?: number;
  operator?: number;
}
interface State {
  tab: number;
  from: Date;
  to: Date;
  loading: boolean;
}

class CallsPlot extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const from = new Date();
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    from.setMilliseconds(0);

    const to = new Date();
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    to.setMilliseconds(0);

    this.state = {
      tab: 2,
      from: new Date(+from - Time.day * 60),
      to,
      loading: false,
    };
  }

  static defaultProps = {
    onChangeAutocenter() {},
  };

  static tabs: Array<{ id: number; name: string }> = [
    { id: 4, name: "По часам" },
    { id: 3, name: "По дням" },
    { id: 2, name: "По неделям" },
    { id: 1, name: "По месяцам" },
  ];

  private hanldeChangeTab(tab: number) {
    if (this.state.loading) return;
    this.setState({ tab });
  }
  private hanldeChangeFrom(from: Date) {
    if (this.state.loading) return;
    if (+from > +this.state.to) {
      this.setState({ to: from, from: this.state.to });
    } else {
      this.setState({ from });
    }
  }
  private hanldeChangeTo(to: Date) {
    if (this.state.loading) return;
    if (+to < +this.state.from) {
      this.setState({ to: this.state.from, from: to });
    } else {
      this.setState({ to });
    }
  }
  private changeLoading(loading: boolean) {
    this.setState({ loading });
  }

  public render() {
    return (
      <Box className="calls-plot" shadow={false}>
        <div className="calls-plot-title">
          <span>Звонки</span>
          <CallsStatisticContainer
            autocenter={this.props.autocenter}
            group={this.props.group}
            operator={this.props.operator}
          />
        </div>
        <div className="calls-plot-header">
          <CallsPlotTabs
            tabs={CallsPlot.tabs}
            tab={this.state.tab}
            onChange={this.hanldeChangeTab.bind(this)}
            disabled={this.state.loading}
          />
          {this.state.loading && (
            <h2 className="loading-plot-data">
              <span>Загрузка данных</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
            </h2>
          )}
        </div>

        <CallsPlotFilters
          disabled={this.state.loading}
          autocenter={this.props.autocenter}
          autocenters={this.props.autocenters}
          onChangeAutocenter={this.props.onChangeAutocenter}
          type={this.state.tab}
          from={this.state.from}
          to={this.state.to}
          onChangeFrom={this.hanldeChangeFrom.bind(this)}
          onChangeTo={this.hanldeChangeTo.bind(this)}
        />
        <CallsPlotGraphContainer
          type={this.state.tab}
          from={this.state.from}
          to={this.state.to}
          autocenter={this.props.autocenter}
          group={this.props.group}
          operator={this.props.operator}
          changeLoading={this.changeLoading.bind(this)}
        />
        {this.props.autocenters && this.props.autocenters.length > 0 && (
          <NavLink
            to={`/autocenter/${this.props.autocenter}`}
            className="open-autocenter"
          >
            <button disabled={!this.props.autocenter}>
              Открыть детализацию звонков
            </button>
          </NavLink>
        )}
      </Box>
    );
  }
}

export default CallsPlot;
