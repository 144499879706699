import { AxiosError } from "axios";
import { NOT_FOUND } from "http-status-codes";
import Question from "../../UI/components/popups/question/Question";
import React from "react";
import SmsDetailsBody from "../../components/sms/smsDetailsBody/SmsDetailsBody";
import SmsDetailsHead from "../../components/sms/smsDetailsHead/SmsDetailsHead";
import SmsGroupService from "../../services/SmsGroupService";
import SmsSendingCallsByCities from "../../components/calls/smsSendingCallsByCities/SmsSendingCallsByCities";
import SmsSendingForm from "../../components/sms/sendingForm/SmsSendingForm";
import SmsService from "../../services/SmsService";
import SmsStatisticPlotByDays from "../../components/sms/statistic/smsStatisticPlotByDays/SmsStatisticPlotByDays";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  id: number;
  setTitle: (name: string) => void;
  redirectToList: () => void;
  redirectToNotFound: () => void;
}
interface State {
  statistic?: App.SmsSending;
  loading: boolean;
  isOpenDeleteQuestion: boolean;
  isOpenUpdateForm: boolean;
  groupsList: Array<App.SmsGroup>;
}

class SmsDetailsContainer extends React.Component<Props, State> {
  private smsService: SmsService;
  private smsGroupService: SmsGroupService;
  constructor(props: Props) {
    super(props);
    this.smsService = new SmsService();
    this.smsGroupService = new SmsGroupService();
    this.state = {
      loading: false,
      isOpenDeleteQuestion: false,
      isOpenUpdateForm: false,
      groupsList: [],
    };
  }

  public componentDidMount() {
    this.getStatistic();
    this.getGroups();
  }
  public componentDidUpdate(props: Props) {
    if (this.props.id !== props.id) {
      this.getStatistic();
    }
  }

  private async getStatistic() {
    this.smsService
      .getById(this.props.id)
      .then((response) => {
        this.setState({ statistic: response.data });
        this.props.setTitle(response.data.name);
      })
      .catch((error: AxiosError<Array<string>>) => {
        if (error.response?.status === NOT_FOUND) {
          this.props.redirectToNotFound();
        } else {
          store.dispatch(
            displayErrors(
              error.response?.data || ["Не удалось загрузить смс рассылку"]
            )
          );
        }
      });
  }

  private async delete() {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.smsService
      .delete(this.props.id)
      .then(() => {
        this.props.redirectToList();
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Ошибка при редактировании sms рассылки"]
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  private async update(sms: App.SmsSendingData) {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.smsService
      .update(sms, this.props.id)
      .then(() => {
        this.setState({
          loading: false,
          statistic: Object.assign({}, this.state.statistic, {
            name: sms.name,
            date: sms.date,
            sending: sms.sending,
            brand: sms.brand,
            text: sms.text,
          }),
          isOpenUpdateForm: false,
        });
        this.props.setTitle(sms.name);
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Ошибка при редактировании sms рассылки"]
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private getGroups() {
    this.smsGroupService
      .getList({
        page: 1,
        amount: 10000,
      })
      .then((response) => {
        this.setState({ groupsList: response.data });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || [
              "Не удалось получить список груп смс рассылок",
            ]
          )
        );
      });
  }

  private async handleChangeGroups(groups: Array<number>) {
    // if (!this.state.statistic) return;
    // const s = this.state.statistic;
    // const flagIsAdd = groups.length > s.groups.length;
    // const groupId = flagIsAdd
    //   ? groups.find((g) => !s.groups.includes(g))
    //   : s.groups.find((g) => !groups.includes(g));
    // const group = this.state.groupsList.find((g) => g.id === groupId);
    // if (!group) return;
    // group.sendings = flagIsAdd
    //   ? group.sendings.concat(s.id)
    //   : group.sendings.filter((gs) => gs !== s.id);
    // const { status, errors } = await this.smsGroupService.update(
    //   { name: group.name, sms_sendings: group.sendings },
    //   group.id
    // );
    // if (status) {
    //   this.setState({
    //     groupsList: this.state.groupsList.map((g) =>
    //       g.id === group.id ? group : g
    //     ),
    //     statistic: Object.assign({}, s, { groups }),
    //   });
    // } else {
    //   store.dispatch(displayErrors(errors));
    // }
  }

  public render() {
    return (
      <>
        <SmsDetailsHead
          smsSending={this.state.statistic}
          openDeleteQuestion={() =>
            this.setState({ isOpenDeleteQuestion: true })
          }
          openUpdateForm={() => this.setState({ isOpenUpdateForm: true })}
        />
        <SmsDetailsBody smsSending={this.state.statistic} />
        <SmsStatisticPlotByDays
          totalSms={this.state.statistic?.totalSms || 0}
          calls={this.state.statistic?.calls || []}
        />
        {this.state.statistic && (
          <SmsSendingCallsByCities
            total={this.state.statistic.totalSms}
            cities={this.state.statistic.cities}
          />
        )}
        {this.state.isOpenDeleteQuestion && (
          <Question
            question="Вы действительно хотите удалить рассылку?"
            onAgree={this.delete.bind(this)}
            onDisagree={() => this.setState({ isOpenDeleteQuestion: false })}
          />
        )}

        {this.state.isOpenUpdateForm && (
          <SmsSendingForm
            default={this.state.statistic}
            loading={this.state.loading}
            submit={this.update.bind(this)}
            close={() => this.setState({ isOpenUpdateForm: false })}
          />
        )}
      </>
    );
  }
}

export default SmsDetailsContainer;
