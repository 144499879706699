type SelectAudioType = "SELECT_AUDIO";
type SelectAudiosListType = "SELECT_AUDIOS_LIST";

interface SelectAudioAction {
  type: SelectAudioType;
  active: string;
}
interface SelectAudiosListAction {
  type: SelectAudiosListType;
  list: Array<string>;
}

export type AudioActions = SelectAudioAction | SelectAudiosListAction;

export const selectAudioType: SelectAudioType = "SELECT_AUDIO";
export const selectAudiosListType: SelectAudiosListType = "SELECT_AUDIOS_LIST";

export function selectAudio(active: string): SelectAudioAction {
  return {
    type: selectAudioType,
    active,
  };
}
export function selectAudiosList(list: Array<string>): SelectAudiosListAction {
  return {
    type: selectAudiosListType,
    list,
  };
}
