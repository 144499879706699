import "./AutocenterInfo.scss";

import Box from "../../custom/box/Box";
import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  autocenter?: App.Autocenter;
}
interface State {}

class AutocenterInfo extends React.Component<Props, State> {
  public render() {
    return (
      <Box className="autocenter-info" shadow={false}>
        <NavLink
          to={`/autocenter/${this.props.autocenter?.id}`}
          className="autocenter-info-title"
        >
          {this.props.autocenter?.name || "Автоцентр"}
        </NavLink>
        {this.props.children}
      </Box>
    );
  }
}

export default AutocenterInfo;
