import AutocenterInfo from "../../components/autocenter/info/AutocenterInfo";
import AutocenterService from "../../services/AutocenterService";
import { AxiosError } from "axios";
import { NOT_FOUND } from "http-status-codes";
import React from "react";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  id: number;
  goToNotFoundPage: () => void;
  setPageTitle: (name: string) => void;
}
interface State {
  autocenter?: App.Autocenter;
}

class AutocenterInfoContainer extends React.Component<Props, State> {
  private autocenterService: AutocenterService;
  constructor(props: Props) {
    super(props);
    this.autocenterService = new AutocenterService();
    this.state = {};
  }
  public componentDidMount() {
    this.loadAutocenter();
  }
  public componentDidUpdate(prevProps: Props) {
    if (this.props.id !== prevProps.id) {
      this.loadAutocenter();
    }
  }

  private loadAutocenter() {
    this.autocenterService
      .getById(this.props.id)
      .then((response) => {
        this.props.setPageTitle(response.data.name);
        this.setState({ autocenter: response.data });
      })
      .catch((error: AxiosError<Array<string>>) => {
        if (error.response?.status === NOT_FOUND) {
          this.props.goToNotFoundPage();
        } else {
          this.setState({ autocenter: undefined });
          store.dispatch(
            displayErrors(
              error.response?.data || ["Ошибка загрузки автоцентра"]
            )
          );
        }
      });
  }
  public render() {
    return (
      <AutocenterInfo autocenter={this.state.autocenter}>
        {this.props.children}
      </AutocenterInfo>
    );
  }
}

export default AutocenterInfoContainer;
