import "./SmsGroupsView.scss";

import Page from "../../UI/utils/Page";
import React from "react";
import SmsGroupsContainer from "../../containers/sms/SmsGroupsContainer";

interface Props {}
interface State {}

class SmsGroupsView extends React.Component<Props, State> {
  public componentDidMount() {
    Page.setTitle("Группы смс рассылок");
  }
  public render() {
    return (
      <div className="sms-groups-view">
        <SmsGroupsContainer />
      </div>
    );
  }
}

export default SmsGroupsView;
