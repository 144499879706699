import sha256 from "sha256";

class StringMethods {
  static getPhoneNumber(phoneNumber: string): string {
    return phoneNumber
      .split("")
      .filter((symbol) => +symbol === parseInt(symbol))
      .join("")
      .slice(3);
  }

  static beautifyPhoneNumber(phone: string, delimeter: string = ""): string {
    if (phone.length !== 9) {
      return phone;
    }
    return `+375(${phone.slice(0, 2)})${phone.slice(
      2,
      5
    )}${delimeter}${phone.slice(5, 7)}${delimeter}${phone.slice(7, 9)}`;
  }

  static beautifyNumber(number: number | null): string {
    if (number) {
      return number
        .toString()
        .split("")
        .reverse()
        .map((e, i) => ((i + 1) % 3 ? e : " " + e))
        .reverse()
        .join("")
        .trim();
    } else {
      return "0";
    }
  }

  static sha256(str: string): string {
    return sha256(str);
  }

  static toBase64(str: string): string {
    return btoa(str);
  }
  static fromBase64(str: string): string {
    return atob(str);
  }
}

export default StringMethods;
