import "./PageLoader.scss";

import LDSSpinner from "../../UI/components/loaders/spinners/lds/LdsSpinner";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../store/store";

interface Props {}
interface State {
  isDisplay: boolean;
}

class PageLoader extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      isDisplay: false,
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }

  private handleUpdateStore() {
    const { numberActiveRequest } = store.getState().http;
    this.setState({ isDisplay: numberActiveRequest > 0 });
  }

  public render() {
    return (
      <div className="page-loader" data-display={this.state.isDisplay}>
        <LDSSpinner />
        <span>Загрузка...</span>
      </div>
    );
  }
}

export default PageLoader;
