import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";

class PhoneService {
  public async getList(params: {
    autocenter?: number;
    group?: number;
  }): Promise<App.ServiceResponse<Array<App.Phone>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/phones", { headers: { Authorization }, params }).then(
      (response: AxiosResponse<Array<App.Phone>>) => {
        return { status: true, data: response.data };
      }
    );
  }

  public async getById(id: number): Promise<App.ServiceResponse<App.Phone>> {
    const Authorization = await JWT.getAuth();
    return Axios.get(`/phone/${id}`, { headers: { Authorization } }).then(
      (response: AxiosResponse<App.Phone>) => {
        return { status: true, data: response.data };
      }
    );
  }

  public async create(
    phone: App.PhoneData,
    group_id: number
  ): Promise<App.ServiceResponse<App.Phone>> {
    const Authorization = await JWT.getAuth();
    return Axios.post("/phone/create", Object.assign(phone, { group_id }), {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Phone>) => {
      return { status: true, data: response.data };
    });
  }

  public async update(
    phone: App.PhoneData,
    id: number
  ): Promise<App.ServiceResponse<App.Phone>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(`/phone/update/${id}`, phone, {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Phone>) => {
      return { status: true, data: response.data };
    });
  }

  public async delete(id: number): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/phone/delete/${id}`, {
      headers: { Authorization },
    }).then(() => {
      return { status: true, data: null };
    });
  }
}

export default PhoneService;
