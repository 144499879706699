import { Actions, changeUndistributedExtensionNumbersType } from "../actions/undistributedExtensionNumbers";

export interface UndistributedExtensionNumbersStore {
  extensionNumbers: Array<string> | null;
  timestamp: number | null;
}

export const defaultUndistributedExtensionNumbersStore: UndistributedExtensionNumbersStore = getStateFromLocalStorage();

export default function undistributedExtensionNumbers(
  store: UndistributedExtensionNumbersStore,
  action: Actions
): UndistributedExtensionNumbersStore {
  switch (action.type) {
    case changeUndistributedExtensionNumbersType:
      store.extensionNumbers = action.extensionNumbers;
      store.timestamp = action.timestamp;
      localStorage.extensionNumbers = JSON.stringify(action.extensionNumbers);
      localStorage.timestamp = action.timestamp;
      break;
    default:
      break;
  }
  return store;
}

function getStateFromLocalStorage(): UndistributedExtensionNumbersStore {
  try {
    return {
      extensionNumbers: JSON.parse(localStorage.extensionNumbers),
      timestamp: +localStorage.timestamp,
    };
  } catch {
    return {
      extensionNumbers: null,
      timestamp: null,
    };
  }
}