import FaqService, { FaqData } from "../../services/FaqService";

import { AxiosError } from "axios";
import FaqForm from "../../components/faqForm/FaqForm";
import FaqQuestion from "../../components/faqQuestion/FaqQuestion";
import Question from "../../UI/components/popups/question/Question";
import React from "react";
import { Unsubscribe } from "redux";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {}
interface State {
  open: number;
  isOpenRedactForm: boolean;
  isOpenRemoveForm: boolean;
  questionForRedact?: App.Faq;
  forDelete?: number;
  questions: Array<App.Faq>;
  isAdmin: boolean;
  laoding: boolean;
}

class FaqListContainer extends React.Component<Props, State> {
  private faqService: FaqService;
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.faqService = new FaqService();
    this.unsubscribe = () => {};
    this.state = {
      open: 0,
      isOpenRedactForm: false,
      isOpenRemoveForm: false,
      questions: [],
      laoding: false,
      isAdmin: store.getState().session.user?.role === "admin",
    };
  }

  public componentDidMount() {
    this.getList();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState({ isAdmin: store.getState().session.user?.role === "admin" });
  }

  private getList() {
    this.faqService
      .getList()
      .then((response) => {
        this.setState({ questions: response.data });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось получить список вопросов"]
          )
        );
      });
  }

  private deleteQuestion(id: number) {
    if (!this.state.isAdmin || this.state.laoding) {
      return;
    }
    this.setState({ laoding: true });
    this.faqService
      .delete(id)
      .then(() => {
        this.setState({
          questions: this.state.questions.filter((q) => q.id !== id),
          isOpenRemoveForm: false,
          forDelete: undefined,
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось получить список вопросов"]
          )
        );
      })
      .finally(() => {
        this.setState({ laoding: false });
      });
  }
  private update(id: number, faqData: FaqData) {
    if (!this.state.isAdmin || this.state.laoding) {
      return;
    }
    this.setState({ laoding: true });
    this.faqService
      .update(id, faqData)
      .then((response) => {
        this.setState({
          isOpenRedactForm: false,
          questionForRedact: undefined,
          questions: this.state.questions.map((q) =>
            q.id === id ? response.data : q
          ),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось редактировать вопрос"]
          )
        );
      })
      .finally(() => {
        this.setState({ laoding: false });
      });
  }
  private create(faqData: FaqData) {
    if (!this.state.isAdmin || this.state.laoding) {
      return;
    }
    this.setState({ laoding: true });
    this.faqService
      .create(faqData)
      .then((response) => {
        this.setState({
          isOpenRedactForm: false,
          questions: this.state.questions.concat(response.data),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось создать вопрос"])
        );
      })
      .finally(() => {
        this.setState({ laoding: false });
      });
  }

  private handleSubmit(data: FaqData) {
    this.state.questionForRedact
      ? this.update(this.state.questionForRedact.id, data)
      : this.create(data);
  }

  public render() {
    return (
      <>
        {this.state.questions.map((q) => (
          <FaqQuestion
            isAdmin={this.state.isAdmin}
            key={q.id}
            question={q}
            isOpen={this.state.open === q.id}
            onClick={(id) => {
              this.setState({
                open: this.state.open === id ? 0 : id,
              });
            }}
            openDelete={(id) =>
              this.setState({ isOpenRemoveForm: true, forDelete: id })
            }
            openRedact={(faq) =>
              this.setState({ isOpenRedactForm: true, questionForRedact: faq })
            }
          />
        ))}
        {this.state.isAdmin && (
          <button onClick={() => this.setState({ isOpenRedactForm: true })}>
            Добавить вопрос
          </button>
        )}
        {this.state.isOpenRedactForm && (
          <FaqForm
            question={this.state.questionForRedact}
            close={() =>
              this.setState({
                isOpenRedactForm: false,
                questionForRedact: undefined,
              })
            }
            onSubmit={this.handleSubmit.bind(this)}
          />
        )}
        {this.state.isOpenRemoveForm && (
          <Question
            question={"Удалить гайд"}
            onAgree={() =>
              this.state.forDelete
                ? this.deleteQuestion(this.state.forDelete)
                : this.setState({ isOpenRemoveForm: false })
            }
            onDisagree={() =>
              this.setState({ isOpenRemoveForm: false, forDelete: undefined })
            }
          />
        )}
      </>
    );
  }
}
export default FaqListContainer;
