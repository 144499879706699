import Axios, { AxiosResponse } from "axios";

export interface SigninData {
  login: string;
  password: string;
  salt: string;
}
export interface SignupData {
  login: string;
  password: string;
}
export interface RefreshData {
  token: string;
}

class AuthService {
  public signin(
    data: SigninData
  ): Promise<App.ServiceResponse<{ user: App.User; session: App.Tokens }>> {
    return Axios.post("/auth/sign-in", data).then(
      (
        response: AxiosResponse<{ user: App.User; session: App.Tokens }>
      ): App.ServiceResponse<{ user: App.User; session: App.Tokens }> => ({
        status: true,
        data: response.data,
      })
    );
  }
  public signup(data: SignupData): Promise<App.ServiceResponse> {
    return Axios.post("/auth/sign-up", data).then(
      (): App.ServiceResponse => ({ status: true, data: null })
    );
  }
  public refresh(
    data: RefreshData
  ): Promise<
    AxiosResponse<{
      user: App.User;
      session: App.Tokens;
    }>
  > {
    return Axios.post("/auth/refresh", data);
  }
}

export default AuthService;
