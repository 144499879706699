import React from "react";
import Image from "./image/Image";
import HorisontalScroll from "../../horisontalScroll/HorisontalScroll";

interface Props {
  images: Array<App.IFile>;
  remove: (id: number) => void;
}

interface State {}

class ImageDisplay extends React.Component<Props, State> {
  public render() {
    return (
      <HorisontalScroll>
        {this.props.images.map(image => (
          <Image
            key={image.id}
            index={image.id}
            image={image.file || image.path}
            deleteImage={this.props.remove}
          />
        ))}
      </HorisontalScroll>
    );
  }
}

export default ImageDisplay;
