import "./CallsFor_1cTable.scss";

import CallsFor1cTableHead from "./callsFor_1cTableHead/CallsFor_1cTableHead";
import CallsFor1cTablePossibleRecords from "./callsFor_1cTablePossibleRecords/CallsFor_1cTablePossibleRecords";
import CallsFor1cTableRow from "./callsFor_1cTableRow/CallsFor_1cTableRow";
import React from "react";

interface Props {
  calls: Array<App.Call>;
  playAudio: (link?: string) => void;
  getWaves: (extensions: Array<number>, call: number) => void;
}

interface State {
  isOpen: boolean;
  callId?: number;
}

class CallsFor_1cTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  private showPossibleRecordsContainer(callId: number) {
    this.setState({
      isOpen: true,
      callId: callId,
    });
  }

  private hidePossibleRecordsContainer() {
    this.setState({
      isOpen: false,
      callId: undefined,
    });
  }

  public render() {
    let call = this.props.calls.find((c) => c.id === this.state.callId);
    return (
      <table className="calls-for-1c-table">
        <CallsFor1cTableHead/>
        <tbody>
        {
          this.props.calls.map((c) => (
            <CallsFor1cTableRow
              key={c.id}
              call={c}
              playAudio={this.props.playAudio}
              getWaves={this.props.getWaves}
              showPossibleRecords={this.showPossibleRecordsContainer.bind(this)}
            />
          ))
        }
        </tbody>
        {this.state.isOpen && call && (
          <CallsFor1cTablePossibleRecords
            call={call}
            playAudio={this.props.playAudio}
            isOpen={this.state.isOpen}
            hide={this.hidePossibleRecordsContainer.bind(this)}
          />
        )}
      </table>
    );
  }
}

export default CallsFor_1cTable;
