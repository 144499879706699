import SmsGroupService, { SmsGroupData } from "../../services/SmsGroupService";

import { AxiosError } from "axios";
import { NOT_FOUND } from "http-status-codes";
import Question from "../../UI/components/popups/question/Question";
import React from "react";
import SmsGroup from "../../components/sms/smsGroup/SmsGroup";
import SmsGroupForm from "../../components/sms/groupForm/SmsGroupForm";
import SmsService from "../../services/SmsService";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  id: number;
  onLoad?: (group?: App.SmsGroup) => void;
  redirectToList: () => void;
  redirectToNotFound: () => void;
}
interface State {
  loading: boolean;
  group?: App.SmsGroup;
  sms: Array<App.SmsSending>;
  isOpenQuestionDelete: boolean;
  isOpenFormUpdate: boolean;
}

class SmsGroupContainer extends React.Component<Props, State> {
  private smsGroupService: SmsGroupService;
  private smsService: SmsService;
  constructor(props: Props) {
    super(props);
    this.smsGroupService = new SmsGroupService();
    this.smsService = new SmsService();
    this.state = {
      sms: [],
      loading: false,
      isOpenQuestionDelete: false,
      isOpenFormUpdate: false,
    };
  }

  public componentDidMount() {
    this.getGroup();
  }
  public componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id && !isNaN(this.props.id)) {
      this.getGroup();
    }
  }

  private async getGroup() {
    this.setState({ group: undefined, sms: [], loading: true });
    this.smsGroupService
      .getById(this.props.id)
      .then((response) => {
        this.setState({ group: response.data });
        this.props.onLoad && this.props.onLoad(response.data);
        this.getSmses(response.data.sendings);
      })
      .catch((error: AxiosError<Array<string>>) => {
        if (error.response?.status === NOT_FOUND) {
          this.props.redirectToNotFound();
        } else {
          this.props.onLoad && this.props.onLoad();
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  private async getSmses(smsIds: Array<number>) {
    const sms: Array<App.SmsSending> = [];
    for (let id of smsIds) {
      await this.smsService.getById(id).then((response) => {
        sms.push(response.data);
      });
    }
    this.setState({ sms });
  }

  private handleClickDelete() {
    this.setState({ isOpenQuestionDelete: true });
  }
  private handleClickUpdate() {
    this.setState({ isOpenFormUpdate: true });
  }
  private async delete() {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.smsGroupService
      .delete(this.props.id)
      .then(() => {
        this.props.redirectToList();
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.request.data || ["Ошибка при удалении группы смс рассылок"]
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private update(group: SmsGroupData) {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.smsGroupService
      .update(group, this.props.id)
      .then((response) => {
        this.setState({
          group: response.data,
          isOpenFormUpdate: false,
          sms: [],
        });
        this.getSmses(response.data.sendings);
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Ошибка обновления группы смс рассылки"]
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  public render() {
    return (
      <>
        <SmsGroup
          group={this.state.group}
          sms={this.state.sms}
          onClickDelete={this.handleClickDelete.bind(this)}
          onClickUpdate={this.handleClickUpdate.bind(this)}
        />
        {!!this.state.isOpenFormUpdate && (
          <SmsGroupForm
            default={this.state.group}
            loading={this.state.loading}
            submit={this.update.bind(this)}
            close={() => this.setState({ isOpenFormUpdate: false })}
          />
        )}
        {this.state.isOpenQuestionDelete && (
          <Question
            question={"Вы действительно хотите удалить эту группу?"}
            onAgree={this.delete.bind(this)}
            onDisagree={() => this.setState({ isOpenQuestionDelete: false })}
          />
        )}
      </>
    );
  }
}

export default SmsGroupContainer;
