import "./SmsSendingsFilters.scss";

import { BRANDS, MONTHS, SENDINGS, YEARS } from "../../../../config";

import LabelText from "../../../custom/text/LabelText";
import React from "react";
import Select from "../../../custom/select/Select";
import SmsTabsForView from "../../tabsForView/SmsTabsForView";

interface Props {
  displayKeyword: boolean;
  displayBrand: boolean;
  displayYear: boolean;
  displayMonth: boolean;
  displaySending: boolean;
  displayTabs: boolean;

  keyword: string;
  brand: number | null;
  year: number | null;
  month: number | null;
  sending: number | null;
  disabled: boolean;
  onChangeKeyword: (value: string) => void;
  onChangeBrand: (value: number | null) => void;
  onChangeYear: (value: number | null) => void;
  onChangeMonth: (value: number | null) => void;
  onChangeSending: (value: number | null) => void;
}
interface State {
  brands: Array<{ id: number; name: string }>;
  years: Array<{ id: number; name: string }>;
  months: Array<{ id: number; name: string }>;
  sendings: Array<{ id: number; name: string }>;
}

class SmsSendingsFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      brands: BRANDS,
      years: YEARS,
      months: MONTHS,
      sendings: SENDINGS,
    };
  }

  public render() {
    return (
      <div className="sms-sendings-filters">
        <div className="filters">
          {this.props.displayBrand && (
            <Select
              options={this.state.brands}
              multiselect={false}
              onChange={this.props.onChangeBrand}
              selected={this.props.brand}
              disabled={this.props.disabled}
              label="Бренд"
            />
          )}
          {this.props.displayYear && (
            <Select
              options={this.state.years}
              multiselect={false}
              onChange={this.props.onChangeYear}
              selected={this.props.year}
              disabled={this.props.disabled}
              label="Год"
            />
          )}
          {this.props.displayMonth && (
            <Select
              options={this.state.months}
              multiselect={false}
              onChange={this.props.onChangeMonth}
              selected={this.props.month}
              disabled={this.props.disabled}
              label="Месяц"
            />
          )}
          {this.props.displaySending && (
            <Select
              options={this.state.sendings}
              multiselect={false}
              onChange={this.props.onChangeSending}
              selected={this.props.sending}
              disabled={this.props.disabled}
              label="Категория"
            />
          )}
          {this.props.displayKeyword && (
            <LabelText
              label="Поиск"
              value={this.props.keyword}
              onChange={this.props.onChangeKeyword}
            />
          )}
        </div>
        {this.props.displayTabs && <SmsTabsForView />}
      </div>
    );
  }
}

export default SmsSendingsFilters;
