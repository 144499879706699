import ErrorWindow from "../../UI/components/errorsWindow/ErrorWindow";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../store/store";

interface Props {}
interface State extends App.Store.Errors {
  isDisplay: boolean;
}

class ErrorWindowContainer extends React.Component<Props, State> {
  private unsubscribe?: Unsubscribe;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDisplay: false,
      id: 0,
      errors: [],
      callback() {},
    };
  }
  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }

  public componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  private handleUpdateStore() {
    const { error } = store.getState();

    if (error.id !== this.state.id) {
      if (
        error.errors &&
        error.errors.constructor === Array &&
        error.errors.every((error) => error && error.constructor === String)
      ) {
      } else {
        error.errors = ["Непредвиденная ошибка сервера"];
      }

      this.setState(Object.assign(error, { isDisplay: true }));
    }
  }

  private close() {
    this.setState({ isDisplay: false });
  }

  public render() {
    return (
      <>
        {this.state.isDisplay && (
          <ErrorWindow
            errors={this.state.errors}
            onClick={this.close.bind(this)}
          />
        )}
      </>
    );
  }
}

export default ErrorWindowContainer;
