import React from "react";
import HeadCell from "../cells/headCell/HeadCell";

class TableHead extends React.Component<UI.Table.TableHead> {
  private handleChangeSort(column: string, order: UI.Table.Order) {
    this.props.dispatchUpdate(
      Object.assign(this.props.filters, { column, order })
    );
  }

  public render() {
    return (
      <thead className="table-head">
        <tr>
          {this.props.columns.map((e) => {
            return (
              <HeadCell
                key={e.sortFlag}
                name={e.name}
                column={e.sortFlag}
                isAllowed={this.props.filterOptions.allowedColumns.includes(e.sortFlag)}
                active={this.props.filters.column === e.sortFlag}
                order={this.props.filters.order}
                onClick={this.handleChangeSort.bind(this)}
              />
            );
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHead;
