export default class GroupValidator {
  static aName = {
    minLength: 3,
    maxLength: 64,
    regExp: /^[A-zА-я0-9_. @-]{3,64}$/,
  };
  static code = {
    minLength: 1,
    maxLength: 16,
    regExp: /^[A-zА-я0-9_., @-]{1,16}$/,
  };
  static isCorrectName(name: string): boolean {
    return (
      name.length >= GroupValidator.aName.minLength &&
      name.length <= GroupValidator.aName.maxLength &&
      !!name.match(GroupValidator.aName.regExp)
    );
  }
  static isCorrectCode(code: string): boolean {
    return (
      code.length >= GroupValidator.code.minLength &&
      code.length <= GroupValidator.code.maxLength &&
      !!code.match(GroupValidator.code.regExp)
    );
  }
}
