import "./SmsDetailsBodyInfoGroups.scss";

import { NavLink } from "react-router-dom";
import React from "react";
import { smsGroupPath } from "../../../../../routes";

interface Props {
  groups: Array<App.SmsGroup>;
}
interface State {}

class SmsDetailsBodyInfoGroups extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    console.log(this.props.groups);
    return (
      <div className="sms-details-body-info-groups">
        <span>Группы:</span>
        {this.props.groups.length > 0 && (
          <NavLink
            to={smsGroupPath.replace(":id", this.props.groups[0].id.toString())}
          >
            {this.props.groups[0].name}
          </NavLink>
        )}
        {this.props.groups.length > 1 && (
          <NavLink
            to={smsGroupPath.replace(":id", this.props.groups[1].id.toString())}
          >
            {this.props.groups[1].name}
          </NavLink>
        )}
        {this.props.groups.length > 2 && (
          <div className="more-groups">
            <div className="amount">
              еще {this.props.groups.length - 2} групп
            </div>
            <div className="groups">
              {this.props.groups.slice(2).map((group) => (
                <NavLink
                  key={group.id}
                  to={smsGroupPath.replace(":id", group.id.toString())}
                >
                  {group.name}
                </NavLink>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default SmsDetailsBodyInfoGroups;
