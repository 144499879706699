import "./Autocenter.scss";

import AutocenterInfo from "../../containers/autocenter/AutocenterInfoContainer";
import CallsPlot from "../../components/calls/callsPlot/CallsPlot";
import CallsTableContainer from "../../containers/calls/CallsTableContainer";
import GroupsListContainer from "../../containers/group/GroupsListContainer";
import Page from "../../UI/utils/Page";
import PhonesListContainer from "../../containers/phone/PhonesListContainer";
import React from "react";
import { RouteComponentProps } from "react-router";
import { notFoundPath } from "../../routes";

interface Props
  extends RouteComponentProps<{
    autocenter: string;
    group: string;
    operator: string;
  }> {}
interface State {}

class AutocenterPage extends React.Component<Props, State> {
  public componentDidMount() {
    Page.setTitle(`Автоцентр №${this.props.match.params.autocenter}`);
  }

  private goToNotFoundPage() {
    this.props.history.replace(notFoundPath);
  }
  private setPageTitle(name: string) {
    Page.setTitle(name);
  }

  public render() {
    return (
      <div className="autocenter-view">
        <AutocenterInfo
          id={+this.props.match.params.autocenter}
          goToNotFoundPage={this.goToNotFoundPage.bind(this)}
          setPageTitle={this.setPageTitle.bind(this)}
        >
          <GroupsListContainer
            redact={false}
            autocenter={+this.props.match.params.autocenter}
            active={
              this.props.match.params.group
                ? +this.props.match.params.group
                : undefined
            }
          />
          {!!this.props.match.params.group && (
            <PhonesListContainer
              redact={false}
              active={
                this.props.match.params.operator
                  ? +this.props.match.params.operator
                  : undefined
              }
              group={+this.props.match.params.group}
            />
          )}
        </AutocenterInfo>

        <CallsPlot
          autocenter={+this.props.match.params.autocenter || undefined}
          group={+this.props.match.params.group || undefined}
          operator={+this.props.match.params.operator || undefined}
        />
        <CallsTableContainer
          autocenter={+this.props.match.params.autocenter}
          group={+this.props.match.params.group || undefined}
          operator={+this.props.match.params.operator || undefined}
        />
      </div>
    );
  }
}

export default AutocenterPage;
