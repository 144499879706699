import PlayIcon from "../../../../assets/play-for-poltys.png";
import React from "react";
import StringMethods from "../../../../UI/utils/StringMethods";
import Time from "../../../../UI/utils/Time";
import './MissedRecordsTableRow.scss'

interface Props {
  record: App.Record;
  playAudio: (link?: string) => void;
}

export default class MissedRecordsTableRow extends React.Component<Props, any> {
  public render() {
    return <>
      <tr className="missed-records-table-row">
        <td>{this.props.record.id}</td>
        <td>
          <div className={`img ${this.props.record.is_incoming ? "i" : "o"}`}/>
        </td>
        <td>{StringMethods.beautifyPhoneNumber(this.props.record.caller_number)}</td>
        <td>{this.props.record.number}</td>
        <td>{Time.beautifyDate(this.props.record.start_timestamp * Time.second)}</td>
        <td>{Time.beautifyTime(this.props.record.start_timestamp * Time.second)}</td>
        <td>{this.props.record.duration}</td>
        <td><img onClick={() => this.props.playAudio(this.props.record.src)} src={PlayIcon} alt=""/></td>
      </tr>
    </>
  }
}