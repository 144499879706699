import React from "react";
import "./HeadCell.scss";
class HeadCell extends React.Component<UI.Table.HeadCell> {
  private get order(): UI.Table.Order {
    if (!this.props.active) {
      return 1;
    } else {
      return this.props.order ? 0 : 1;
    }
  }

  private handleClick() {
    if (this.props.isAllowed) {
      this.props.onClick(this.props.column, this.order);
    }
    return;
  }

  private get sort() {
    if (this.props.active) {
      return this.props.order ? "asc" : "desc";
    } else {
      return "none";
    }
  }

  public render() {
    return (
      <th
        onClick={this.handleClick.bind(this)}
        className="table-head-cell"
        data-sort={this.sort}
        data-allowed={this.props.isAllowed}
      >
        <div>{this.props.name}</div>
      </th>
    );
  }
}

export default HeadCell;
