import "./Arrow.scss";

import React from "react";

interface Props {
  direction: UI.Arrow.Direction;
  onClick?: (event?: any) => void;
  onMouseDown?: (event?: any) => void;
  hidden?: boolean;
  disabled?: boolean;
}

class Arrow extends React.Component<Props> {
  static defaultProps = {
    direction: "forward"
  };
  public render() {
    return (
      <div {...this.props} className="arrow">
        <div></div>
      </div>
    );
  }
}

export default Arrow;
