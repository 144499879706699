import React from "react";
import Timer from "../../../time/timer/Timer";

class TimerCell extends React.Component<UI.Table.TimerCellProps> {
  public render() {
    return (
      <td onMouseOver={this.props.onMouseOver} className={this.props.className}>
        <Timer timestamp={this.props.end} hideTitle={true} />
      </td>
    );
  }
}

export default TimerCell;
