import "./SmsSendings.scss";

import React, { SyntheticEvent } from "react";

import SmsSendingCard from "../smsSendingCard/SmsSendingCard";
import SubTitle from "../../../UI/components/title/SubTitle";

interface Props {
  activeList?: Array<number>;

  smsStatistic: Array<App.SmsSending>;
  loading: boolean;
  loadMore: () => void;
  onClick?: (event: SyntheticEvent, smsSending: App.SmsSending) => void;
}

interface State {}

class SmsSending extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private handleScroll() {
    if (this.props.loading) return;
    const app = document.querySelector(".App");
    if (app && app.scrollHeight - app.scrollTop - window.innerHeight * 2 < 0) {
      this.props.loadMore();
    }
  }

  public render() {
    return (
      <div
        className="sms-sendings"
        data-loading={this.props.loading}
        onWheel={this.handleScroll.bind(this)}
      >
        {this.props.loading && (
          <SubTitle className="sms-sendings-title">Загрузка...</SubTitle>
        )}
        <div className="list">
          {this.props.smsStatistic.map((smsSending) => (
            <SmsSendingCard
              className={
                this.props.activeList?.includes(smsSending.id)
                  ? "active"
                  : undefined
              }
              key={smsSending.id}
              smsSending={smsSending}
              onClick={this.props.onClick}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default SmsSending;
