import "./SmsAdminPanel.scss";

import { AddSmsFileData } from "../../../services/SmsService";
import FileLoader from "../../../UI/components/fileLoader/FileLoader";
import LabelDateTime from "../../custom/datetime/LabelDateTime";
import LabelText from "../../custom/text/LabelText";
import React from "react";
import Select from "../../custom/select/Select";
import ShadowBack from "../../../UI/components/popups/shadowBack/ShadowBack";

interface Props {
  save: (data: AddSmsFileData) => void;
  loading: boolean;
  saved: number;
  errors: Array<string>;
  succesfulLoad: boolean;
  failedLoad: boolean;
  isDisplayErrors: boolean;
  closeErrorsList: () => void;
}
interface State {
  name: string;
  date: Date;
  files: Array<App.IFile>;
  brand: number | null;
  sending: number | null;
  text: string;
}

class SmsAdminPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      date: new Date(),
      files: [],
      brand: null,
      sending: null,
      text: "",
    };
  }

  private get disabled() {
    return !(
      !this.props.loading &&
      this.state.name &&
      this.state.date &&
      this.state.files &&
      this.state.brand
    );
  }

  public componentDidUpdate(props: Props) {
    if (this.props.saved && this.props.saved !== props.saved) {
      this.setState({
        name: "",
        date: new Date(),
        files: [],
        brand: null,
        sending: null,
        text: "",
      });
    }
  }

  private handleChangeInfo(files: Array<App.IFile>) {
    this.setState({ files });
  }
  private handleDeleteFile() {
    this.setState({ files: [] });
  }
  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangeDate(date: Date) {
    this.setState({ date });
  }
  private handleChangeText(text: string) {
    this.setState({ text });
  }
  private save() {
    if (this.props.loading) return;
    this.props.save({
      name: this.state.name,
      file: this.state.files[0],
      date: this.state.date,
      text: this.state.text,
      brand: this.getBrand(this.state.brand),
      sending: this.getSending(this.state.sending),
    });
  }

  private getBrand(brand: number | null): App.Brand {
    switch (brand) {
      case 1:
        return "LADA";
      case 2:
        return "RENAULT";
      case 3:
        return "MITSUBISHI";
      default:
        return "LADA";
    }
  }
  private getSending(sending: number | null): App.SendingType | null {
    switch (sending) {
      case 1:
        return "salon";
      case 2:
        return "service";
      default:
        return null;
    }
  }

  private handleChangeBrand(brand: number | null) {
    this.setState({ brand });
  }
  private handleChangeSending(sending: number | null) {
    this.setState({ sending });
  }

  public render() {
    return (
      <>
        <div className="sms-admin-panel">
          {this.props.loading && (
            <div className="status loading">
              <b>Загрузка...</b>
            </div>
          )}
          {this.props.succesfulLoad && (
            <div className="status succesful-end">
              <b>Файл успешно загружен</b>
            </div>
          )}
          {this.props.failedLoad && (
            <div className="status failed-end">
              <b>Ошибка загрузки</b>
              {this.props.errors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}

          <LabelText
            label="Название рассылки"
            value={this.state.name}
            onChange={this.handleChangeName.bind(this)}
            maxLength={256}
          />
          <LabelText
            area
            label="Текст рассылки"
            value={this.state.text}
            onChange={this.handleChangeText.bind(this)}
          />
          <LabelDateTime
            label="Дата рассылки"
            showTimeSelect
            selected={this.state.date}
            dateFormat="dd.MM.yyyy HH:mm"
            onChange={this.handleChangeDate.bind(this)}
          />
          <Select
            label="Бренд"
            onChange={this.handleChangeBrand.bind(this)}
            selected={this.state.brand}
            multiselect={false}
            options={[
              { id: 1, name: "LADA" },
              { id: 2, name: "RENAULT" },
              { id: 3, name: "MITSUBISHI" },
            ]}
          />
          <Select
            label="Рассылка"
            onChange={this.handleChangeSending.bind(this)}
            selected={this.state.sending}
            multiselect={false}
            options={[
              { id: 1, name: "Салон" },
              { id: 2, name: "Сервис" },
            ]}
          />
          <FileLoader
            title={"Добавить файл рассылки (excel)"}
            types={[
              "xls",
              "xlsx",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            handleChange={this.handleChangeInfo.bind(this)}
            deleteFile={this.handleDeleteFile.bind(this)}
            files={this.state.files}
            maxCountFiles={1}
            displayType="list"
          />
          <button disabled={this.disabled} onClick={this.save.bind(this)}>
            Сохранить
          </button>
        </div>
        {this.props.isDisplayErrors && (
          <ShadowBack onClick={this.props.closeErrorsList}>
            <div className="errors-list">
              <div className="errors-list-title">Ошибки</div>
              <ul className="list-container">
                {this.props.errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          </ShadowBack>
        )}
      </>
    );
  }
}
export default SmsAdminPanel;
