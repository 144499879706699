import "./SmsTabsForView.scss";

import { smsGroupsPath, smsesPath } from "../../../routes";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {}
interface State {}

class SmsTabsForView extends React.Component<Props, State> {
  public render() {
    return (
      <div className="sms-tabs-for-view">
        <NavLink to={smsesPath}>Все смс</NavLink>
        <NavLink to={smsGroupsPath}>Группы</NavLink>
      </div>
    );
  }
}

export default SmsTabsForView;
