import "./SmsDetailsBodyPieChartByCity.scss";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import React from "react";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface Props {
  smsSending?: App.SmsSending;
}
interface State {
  chartRef: React.RefObject<HTMLDivElement>;
  chart?: am4charts.XYChart;
}

class SmsDetailsBodyPieChartByCity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    am4core.useTheme(am4themes_animated);
    this.state = {
      chartRef: React.createRef(),
    };
  }
  static colors = [
    new am4core.Color(am4core.color("#AC0F46").rgb),
    new am4core.Color(am4core.color("#EB2330").rgb),
    new am4core.Color(am4core.color("#FDA100").rgb),
    new am4core.Color(am4core.color("#FCDE04").rgb),
    new am4core.Color(am4core.color("#7FB905").rgb),
    new am4core.Color(am4core.color("#009688").rgb),
    new am4core.Color(am4core.color("#01BBD3").rgb),
    new am4core.Color(am4core.color("#038ECF").rgb),
    new am4core.Color(am4core.color("#064973").rgb),
  ];

  public componentDidUpdate(prevProps: Props) {
    if (this.props.smsSending !== prevProps.smsSending) {
      if (this.props.smsSending) this.createChart();
    }
  }
  public componentWillUnmount() {
    this.state.chart?.dispose();
  }

  private createChart() {
    this.state.chart?.dispose();

    const tag = this.state.chartRef.current;
    const cities = this.props.smsSending?.cities;
    if (!tag || !cities) return;
    const chart = am4core.create(tag, am4charts.PieChart);
    chart.data = cities;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "amount";
    pieSeries.dataFields.category = "name";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.colors.list = SmsDetailsBodyPieChartByCity.colors;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    chart.hiddenState.properties.radius = am4core.percent(0);
  }

  public render() {
    return (
      <div
        className="sms-details-body-pie-chart-by-city"
        ref={this.state.chartRef}
      ></div>
    );
  }
}
export default SmsDetailsBodyPieChartByCity;
