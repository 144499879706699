import "./CallsPlotFor_1c.scss";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import React, { RefObject } from "react";

import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export type DataArray = Array<{
  value: number;
  date: Date;
  category: string;
}>;
interface Props {
  incoming: DataArray;
  outgoing: DataArray;
  sms: DataArray;
}
interface State {
  ref: RefObject<HTMLDivElement>;
  chart?: am4charts.XYChart;
}

class CallsPlotFor_1c extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    am4core.useTheme(am4themes_animated);
    this.state = {
      ref: React.createRef(),
    };
  }

  static colors = [
    new am4core.Color({ r: 110, g: 118, b: 255, a: 0.6 }),
    new am4core.Color({ r: 10, g: 214, b: 104, a: 0.6 }),
    new am4core.Color({ r: 255, g: 171, b: 0, a: 0.6 }),
  ];

  public componentDidMount() {
    this.createPlot();
  }
  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.incoming !== prevProps.incoming ||
      this.props.outgoing !== prevProps.outgoing
    ) {
      this.createPlot();
    }
  }
  public componentWillUnmount() {
    this.state.chart?.dispose();
  }

  private createPlot() {
    this.state.chart?.dispose();

    const el = this.state.ref.current;
    if (!el) return;
    let chart = am4core.create(el, am4charts.XYChart);
    chart.maskBullets = true;
    chart.language.locale = am4lang_ru_RU;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";
    chart.legend.scrollable = true;
    chart.colors.list = CallsPlotFor_1c.colors;
    chart.legend.fill = CallsPlotFor_1c.colors[0];
    let xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.baseInterval = {
      timeUnit: "month",
      count: 1,
    };

    const max = new Date();
    max.setDate(1);
    max.setHours(0, 0, 0, 0);
    xAxis.max = max.getTime();
    xAxis.min = new Date(2018, 0, 1, 0, 0, 0, 0).getTime();

    let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

    yAxis.dataFields.category = "category";
    xAxis.dataFields.date = "date";

    xAxis.renderer.grid.template.disabled = false;
    yAxis.renderer.grid.template.disabled = false;
    xAxis.renderer.axisFills.template.disabled = true;
    yAxis.renderer.axisFills.template.disabled = true;
    yAxis.renderer.ticks.template.disabled = true;
    xAxis.renderer.ticks.template.disabled = true;

    yAxis.renderer.inversed = true;

    chart.data = [{ category: "Sms" }, { category: "Звонки" }];

    this.createSeries(chart, this.props.incoming).name = "Входящие";
    this.createSeries(chart, this.props.outgoing).name = "Иcходящие";
    this.createSeries(chart, this.props.sms).name = "Sms";
  }

  private createSeries(chart: am4charts.XYChart, data: DataArray) {
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "category";
    series.dataFields.dateX = "date";
    series.dataFields.value = "value";
    series.stacked = true;
    series.columns.template.height = 0;

    series.heatRules.push({
      property: "radius",

      target: this.createBullet(series),
      min: 5,
      max: 20,
    });

    series.data = data;
    return series;
  }
  private createBullet(series: am4charts.ColumnSeries): am4core.Circle {
    let bullet = series.bullets.push(new am4core.Circle());
    bullet.tooltipText =
      "{category}, {date.formatDate('MMM yyyy')}: {value.workingValue.formatNumber('#.')}";
    bullet.strokeWidth = 3;
    bullet.stroke = am4core.color("#ffffff");
    bullet.strokeOpacity = 0;
    bullet.dx = -25;

    // bullet.adapter.add("tooltipY", function (tooltipY, target) {
    //   return -target.height / 2;
    // });

    bullet.hiddenState.properties.scale = 0.01;
    bullet.hiddenState.properties.opacity = 1;

    let hoverState = bullet.states.create("hover");
    hoverState.properties.strokeOpacity = 1;
    return bullet;
  }

  public render() {
    return <div className="calls-plot-for-1c" ref={this.state.ref}></div>;
  }
}
export default CallsPlotFor_1c;
