import "./SmsGroups.scss";

import React, { SyntheticEvent } from "react";

import SmsGroupCard from "./card/SmsGroupCard";
import SubTitle from "../../../UI/components/title/SubTitle";

interface Props {
  loading: boolean;
  groups: Array<App.SmsGroup>;
  loadMore: () => void;
}
interface State {}

class SmsGroups extends React.Component<Props, State> {
  private handleWheel(event: SyntheticEvent<HTMLDivElement>) {
    const { bottom } = event.currentTarget.getBoundingClientRect();
    console.log(bottom - 100, window.innerHeight);
    if (bottom - 100 < window.innerHeight) {
      this.props.loadMore();
    }
  }

  public render() {
    return (
      <div
        className="sms-groups"
        data-loading={this.props.loading}
        onWheel={this.handleWheel.bind(this)}
      >
        {this.props.loading && (
          <SubTitle className="sms-groups-title">Загрузка...</SubTitle>
        )}

        {this.props.groups.map((group) => (
          <SmsGroupCard key={group.id} group={group} />
        ))}
      </div>
    );
  }
}

export default SmsGroups;
