import "./SmsSendigCardFooter.scss";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  id: number;
  text: string;
}
interface State {
  isOpen: boolean;
}

class SmsSendigCardFooter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  private handleClickOnShowTextMessageButton() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  public render() {
    return (
      <div className="sms-sendig-card-footer">
        <div
          className="sms-message-button"
          onClick={this.handleClickOnShowTextMessageButton.bind(this)}
        >
          {this.state.isOpen ? "Скрыть" : "Текст сообщения"}
        </div>
        <NavLink className="sms-message-button" to={`/sms/${this.props.id}`}>
          Подробнее
        </NavLink>
        <div className="sms-message" data-active={this.state.isOpen}>
          {this.props.text}
        </div>
      </div>
    );
  }
}
export default SmsSendigCardFooter;
