import React from "react";
import WarningIcon from "../../assets/warning.png";
import Button from "../button/Button";
import "./ErrorWindow.scss";

interface IErrorWindowProps {
  errors: Array<string>;
  onClick?: () => void;
}

class ErrorWindow extends React.Component<IErrorWindowProps> {
  private get errors() {
    return this.props.errors.map((error, index) => {
      return <span key={index}>{error}</span>;
    });
  }

  public render() {
    return (
      <div className="error-window-shadow">
        <div className="error-window">
          <div className="error-window-title">
            <div>
              <img src={WarningIcon} alt="Ошибка" />
            </div>
            <div>Внимание</div>
          </div>
          <div className="error-window-errors">{this.errors}</div>
          <div className="error-window-close">
            <Button onClick={this.props.onClick}>ЗАКРЫТЬ</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorWindow;
