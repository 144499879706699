import "./SmsGroupCardHead.scss";

import React from "react";

interface Props {
  group: { name: string; date: Date };
}
interface State {}

class SmsGroupCardHead extends React.Component<Props, State> {
  public render() {
    return (
      <div className="sms-group-card-head">
        <div className="sms-group-card-title">
          <strong>{this.props.group.name}</strong>
        </div>
      </div>
    );
  }
}

export default SmsGroupCardHead;
