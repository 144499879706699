import "./PhoneCard.scss";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  active?: boolean;
  phone: App.Phone;
}
interface State {}

class PhoneCard extends React.Component<Props, State> {
  public render() {
    return (
      <div className="phone-card" data-active={this.props.active}>
        <NavLink
          to={`/autocenter/${this.props.phone.autocenter_id}/${this.props.phone.group_id}/${this.props.phone.id}`}
          className="main"
        >
          <div className="number">{this.props.phone.number}</div>
        </NavLink>
        {this.props.children}
      </div>
    );
  }
}

export default PhoneCard;
