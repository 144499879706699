import React from "react";
import "./ProgressBar.scss";

interface Props {
  percent: number;
  disabled?: boolean;
}
interface State {}

class ProgressBar extends React.Component<Props, State> {
  public render() {
    return (
      <div className="progress-bar">
        <div
          className="complited"
          style={{ width: this.props.percent + "%" }}
        ></div>
      </div>
    );
  }
}

export default ProgressBar;
