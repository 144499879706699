import "../AuthForm.scss";

import React, { SyntheticEvent } from "react";

import AuthValidator from "../../../utils/authValidator/AuthValidator";
import LabelText from "../../custom/text/LabelText";
import { SignupData } from "../../../services/AuthService";
import StringMethods from "../../../UI/utils/StringMethods";

interface Props {
  signup: (data: SignupData) => void;
}
interface State {
  login: string;
  password: string;
  passwordConfirmation: string;
}

class Signup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      login: "",
      password: "",
      passwordConfirmation: "",
    };
  }

  private onChangeLogin(login: string) {
    this.setState({
      login,
    });
  }
  private onChangePassword(password: string) {
    this.setState({
      password,
    });
  }
  private onChangePasswordConfirmation(passwordConfirmation: string) {
    this.setState({
      passwordConfirmation,
    });
  }

  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (
      AuthValidator.isCorrectLogin(this.state.login) &&
      AuthValidator.isCorrectPassword(this.state.password) &&
      AuthValidator.isCorrectPasswordConfirmation(
        this.state.password,
        this.state.passwordConfirmation
      )
    ) {
      const data: SignupData = {
        login: this.state.login,
        password: StringMethods.sha256(this.state.password),
      };
      this.props.signup(data);
    }
  }

  public render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <LabelText
          label="Логин"
          value={this.state.login}
          onChange={this.onChangeLogin.bind(this)}
          minLength={AuthValidator.login.minLength}
          maxLength={AuthValidator.login.maxLength}
          data-correct={AuthValidator.isCorrectLogin(this.state.login)}
        />
        <LabelText
          label="Пароль"
          type="password"
          value={this.state.password}
          onChange={this.onChangePassword.bind(this)}
          minLength={AuthValidator.password.minLength}
          maxLength={AuthValidator.password.maxLength}
          data-correct={AuthValidator.isCorrectPassword(this.state.password)}
        />
        <LabelText
          label="Подтвердите пароль"
          type="password"
          value={this.state.passwordConfirmation}
          onChange={this.onChangePasswordConfirmation.bind(this)}
          minLength={AuthValidator.password.minLength}
          maxLength={AuthValidator.password.maxLength}
          data-correct={AuthValidator.isCorrectPasswordConfirmation(
            this.state.password,
            this.state.passwordConfirmation
          )}
        />
        <button
          disabled={
            !(
              AuthValidator.isCorrectLogin(this.state.login) &&
              AuthValidator.isCorrectPassword(this.state.password) &&
              AuthValidator.isCorrectPasswordConfirmation(
                this.state.password,
                this.state.passwordConfirmation
              )
            )
          }
        >
          Регистрация
        </button>
      </form>
    );
  }
}

export default Signup;
