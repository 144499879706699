import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Unsubscribe } from "redux";
import UsersListContainer from "../../containers/user/UsersListContainer";
import { homePath } from "../../routes";
import store from "../../store/store";

interface Props extends RouteComponentProps {}
interface State {
  isAdmin: boolean;
}

class Users extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      isAdmin: this.isAdmin(),
    };
  }

  public componentDidMount() {
    Page.setTitle("Пользователи");
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState({
      isAdmin: this.isAdmin(),
    });
  }

  private isAdmin(): boolean {
    try {
      return store.getState().session.user?.role === "admin";
    } catch {
      return false;
    }
  }

  private goToHome() {
    this.props.history.replace(homePath);
  }

  public render() {
    return (
      <div className="users-view">
        {!this.state.isAdmin && <h2>У вас не достаточно прав</h2>}
        {this.state.isAdmin && (
          <UsersListContainer toHome={this.goToHome.bind(this)} />
        )}
      </div>
    );
  }
}

export default Users;
