import "./PhoneRedact.scss";

import PhoneCard from "../card/PhoneCard";
import React from "react";
import RedactIcon from "../../../assets/redact.png";
import RemoveIcon from "../../../assets/remove.png";

interface Props {
  index: number;
  phone: App.Phone;
  onTryRedact: () => void;
  onTryDelete: () => void;
  onStartMove: (index: number) => void;
  onMouseEnter: (index: number) => void;
  onEndMove: () => void;
  updateCurrentPhone: (phone: App.Phone) => void;
}
interface State {}

class PhoneRedact extends React.Component<Props, State> {
  private handleDragStart() {
    this.props.onStartMove(this.props.index);
  }
  private handleDragEnter() {
    this.props.onMouseEnter(this.props.index);
  }
  private handleDragEnd() {
    this.props.onEndMove();
  }
  public render() {
    return (
      <div
        className="phone-redact"
        draggable={true}
        onDragStart={this.handleDragStart.bind(this)}
        onDragEnter={this.handleDragEnter.bind(this)}
        onDragEnd={this.handleDragEnd.bind(this)}
      >
        <PhoneCard phone={this.props.phone}>
          <div className="buttons">
            <img src={RedactIcon} alt="R" onClick={this.props.onTryRedact} />
            <img src={RemoveIcon} alt="D" onClick={this.props.onTryDelete} />
          </div>
        </PhoneCard>
      </div>
    );
  }
}
export default PhoneRedact;
