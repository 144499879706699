import React from "react";
import './UndistributedExtensionNumberList.scss'
import store from "../../store/store";

interface Props {}
interface State {}

export default class UndistributedExtensionNumberList extends React.Component<Props, State> {
  private getCorrectTitleEndingByNumber(number: number) {
    if ((number >= 11 && number <= 20) || number % 10 >= 5 || number % 10 === 0)
      return "неизвестных номеров";
    else if (number === 1) return "неизвестный номер";
    else return "неизвестных номера";
  }

  render() {
    let extensionNumbers = store.getState().undistributedExtensionNumbers.extensionNumbers;
    let title: JSX.Element;
    if (!extensionNumbers)
      title = <></>;
    else if (!extensionNumbers.length)
      title = <h3>Нет неизвестных номеров</h3>;
    else
      title = <h3 className="undistributed-extension-number-title">
        Всего {extensionNumbers.length} {this.getCorrectTitleEndingByNumber(extensionNumbers.length)}
      </h3>;
    return <div className="undistributed-extension-number-list">
      {title}
      <div className="undistributed-extension-numbers">
        {
          extensionNumbers?.map((number) => {
            return <div key={number} className="undistributed-extension-number">{number}</div>
          })
        }
      </div>
    </div>
  }
}