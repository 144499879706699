import "./SmsDetailsBody.scss";

import React from "react";
import SmsDetailsBodyInfo from "./smsDetailsBodyInfo/SmsDetailsBodyInfo";
import SmsDetailsBodyPieChartByCity from "./smsDetailsBodyPieChartByCity/SmsDetailsBodyPieChartByCity";

interface Props {
  smsSending?: App.SmsSending;
}
interface State {}

class SmsDetailsBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="sms-details-body">
        <SmsDetailsBodyInfo smsSending={this.props.smsSending} />
        <SmsDetailsBodyPieChartByCity smsSending={this.props.smsSending} />
      </div>
    );
  }
}
export default SmsDetailsBody;
