import { AxiosError } from "axios";
import CallService from "../../../services/CallService";
import CallsFor1c from "../../../components/calls/callsFor_1c/CallsFor_1c";
import { DataArray } from "../../../components/calls/callsPlotFor_1c/CallsPlotFor_1c";
import React from "react";
import { RouteComponentProps } from "react-router";
import Time from "../../../UI/utils/Time";
import { displayErrors } from "../../../store/actions/error";
import { selectAudio } from "../../../store/actions/audio";
import store from "../../../store/store";

interface Props
  extends RouteComponentProps<{
    name: string;
    phone: string;
    timestamp: string;
    signature: string;
  }> {}
interface State {
  calls: Array<App.Call>;
  incoming: DataArray;
  outgoing: DataArray;
  error: string;
}

class CallsFor_1cContainer extends React.Component<Props, State> {
  private callService: CallService;
  constructor(props: Props) {
    super(props);
    this.callService = new CallService();
    this.state = {
      calls: [],
      incoming: [],
      outgoing: [],
      error: "",
    };
  }

  public componentDidMount() {
    this.getCalls();
  }

  private getPlotDataForCalls(calls: Array<App.Call>): DataArray {
    return Array.from(
      calls.reduce((g: Map<number, number>, e) => {
        const date = new Date(e.start_timestamp * Time.second);
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        const value = g.get(+date) || 0;
        g.set(+date, value + 1);
        return g;
      }, new Map())
    ).map((e) => ({
      date: new Date(e[0]),
      value: e[1],
      y: 1,
      category: "Звонки",
    }));
  }

  private get params() {
    const phone = this.props.location.search.match(/phone=([0-9]{7,9})/);
    const name = this.props.location.search.match(/name=([A-zА-я0-9%]*)&?/);
    const timestamp = this.props.location.search.match(/timestamp=([0-9]{10})/);
    const signature = this.props.location.search.match(
      /signature=([A-z0-9]{64})/
    );
    return {
      phone: phone ? phone[1] : "",
      name: name ? decodeURI(name[1]) : "",
      timestamp: timestamp ? timestamp[1] : "",
      signature: signature ? signature[1] : "",
    };
  }

  private async getCalls() {
    this.callService
      .getCallsFor1C(this.params)
      .then((response) => {
        this.setState({
          calls: response.data,
          incoming: this.getPlotDataForCalls(
            response.data.filter((e: any) => !!e.is_incoming)
          ),
          outgoing: this.getPlotDataForCalls(
            response.data.filter((e: any) => !e.is_incoming)
          ),
        });
      })
      .catch((err: AxiosError<Array<string>>) => {
        this.setState({
          error: err.response?.data.join(" ") || "Ошибка при загрузке звонков",
        });
      });
  }

  private async getWaves(extensions: Array<number>, call: number) {
    this.callService
      .getWavesFor1C(extensions, this.params)
      .then((response) => {
        this.setState({
          calls: this.state.calls.map((c) => {
            if (c.id === call) {
              c.extensions = c.extensions.map((e, i) => {
                e.wave = response.data.waves[e.id] || "";
                return e;
              });
              c.possible_records = response.data.possible_records;
            }
            return c;
          }),
        });
      })
      .catch((err) => {
        store.dispatch(displayErrors(err));
      });
  }

  private playAudio(audio?: string) {
    if (audio) {
      store.dispatch(selectAudio(audio));
    }
  }

  public render() {
    return (
      <CallsFor1c
        error={this.state.error}
        plotData={{
          incoming: this.state.incoming,
          outgoing: this.state.outgoing,
          sms: [],
        }}
        calls={this.state.calls}
        playAudio={this.playAudio.bind(this)}
        getWaves={this.getWaves.bind(this)}
      />
    );
  }
}
export default CallsFor_1cContainer;
