import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router";
import { homePath } from "../../routes";
import store from "../../store/store";

class Auth extends React.Component<RouteComponentProps> {
  public componentDidMount() {
    Page.setTitle("Вход/Регистрация");
    const { user } = store.getState().session;
    if (user) {
      this.props.history.push(homePath);
    }
  }
  public render() {
    return <div className="auth-view"></div>;
  }
}

export default Auth;
