import "./CallsPlotFilters.scss";

import LabelDateTime from "../../../custom/datetime/LabelDateTime";
import MounthPicker from "../../../custom/monthPicker/MonthPicker";
import React from "react";
import Select from "../../../custom/select/Select";
import Time from "../../../../UI/utils/Time";
import WeekPicker from "../../../custom/weekPicker/WeekPicker";

interface Props {
  disabled: boolean;
  autocenter?: number;
  autocenters?: Array<App.Autocenter>;
  onChangeAutocenter: (autocenter: number | null) => void;
  type: number;
  from: Date;
  to: Date;
  onChangeFrom: (date: Date) => void;
  onChangeTo: (date: Date) => void;
}
interface State {}

class CallsPlotFilters extends React.Component<Props, State> {
  public componentDidMount() {
    this.beautifyFromTo();
  }
  public componentDidUpdate(prevProps: Props) {
    if (this.props.type !== prevProps.type) {
      this.beautifyFromTo();
    } else if (
      this.props.from !== prevProps.from ||
      this.props.to !== prevProps.to
    ) {
      this.setState({
        from: this.props.from,
        to: this.props.to,
      });
    }
  }
  private beautifyFromTo() {
    switch (this.props.type) {
      case 1: {
        this.presentMonthFrom(this.props.from);
        this.presentMonthTo(this.props.to);
        break;
      }
      case 2: {
        this.presentWeekFrom(this.props.from);
        this.presentWeekTo(this.props.to);
        break;
      }
      case 3: {
        this.presentDayFrom(this.props.from);
        this.presentDayTo(this.props.to);
        break;
      }
      case 4: {
        this.presentHourFrom(this.props.from);
        this.presentHourTo(this.props.to);
        break;
      }
      default: {
        break;
      }
    }
  }
  private get datePickerFrom() {
    switch (this.props.type) {
      case 1: {
        return (
          <MounthPicker
            label="Начало интервала"
            date={this.props.from}
            onChange={this.presentMonthFrom.bind(this)}
          />
        );
      }
      case 2: {
        return (
          <WeekPicker
            label="Начало интервала"
            selected={this.props.from}
            onChange={this.presentWeekFrom.bind(this)}
          />
        );
      }
      case 3: {
        return (
          <LabelDateTime
            disabled={this.props.disabled}
            dateFormat="dd.MM.yyyy"
            label="Начало интервала"
            selected={this.props.from}
            onChange={this.presentDayFrom.bind(this)}
          />
        );
      }
      case 4: {
        return (
          <LabelDateTime
            disabled={this.props.disabled}
            dateFormat="dd.MM.yyyy HH:mm"
            label="Начало интервала"
            selected={this.props.from}
            onChange={this.presentHourFrom.bind(this)}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  }
  private get datePickerTo() {
    switch (this.props.type) {
      case 1: {
        return (
          <MounthPicker
            label="Конец интервала"
            date={this.props.to}
            onChange={this.presentMonthTo.bind(this)}
          />
        );
      }
      case 2: {
        return (
          <WeekPicker
            label="Конец интервала"
            selected={this.props.to}
            onChange={this.presentWeekTo.bind(this)}
          />
        );
      }
      case 3: {
        return (
          <LabelDateTime
            disabled={this.props.disabled}
            dateFormat="dd.MM.yyyy"
            label="Конец интервала"
            selected={this.props.to}
            onChange={this.presentDayTo.bind(this)}
          />
        );
      }
      case 4: {
        return (
          <LabelDateTime
            disabled={this.props.disabled}
            dateFormat="dd.MM.yyyy HH:mm"
            label="Конец интервала"
            selected={this.props.to}
            onChange={this.presentHourTo.bind(this)}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  }

  private presentMonthFrom(date: Date) {
    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.props.onChangeFrom(date);
  }
  private presentMonthTo(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setDate(31);
    date.setMilliseconds(0);
    while (date.getDate() < 10) {
      date = new Date(+date - Time.day);
    }
    this.props.onChangeTo(date);
  }
  private presentWeekFrom(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const minusDay = (date.getDay() || 7) - 1;
    date.setDate(date.getDate() - minusDay);
    this.props.onChangeFrom(date);
  }
  private presentWeekTo(date: Date) {
    const addDay = 6 - ((date.getDay() || 7) - 1);
    date.setDate(date.getDate() + addDay);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(0);
    this.props.onChangeTo(date);
  }
  private presentDayFrom(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.props.onChangeFrom(date);
  }
  private presentDayTo(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(0);
    this.props.onChangeTo(date);
  }
  private presentHourFrom(date: Date) {
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.props.onChangeFrom(date);
  }
  private presentHourTo(date: Date) {
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(0);
    this.props.onChangeTo(date);
  }

  public render() {
    return (
      <div className="calls-plot-filters">
        {this.props.autocenters && this.props.autocenters.length > 0 && (
          <Select
            label={"Автоцентр"}
            placeholder={"Все"}
            disabled={this.props.disabled}
            options={this.props.autocenters}
            selected={this.props.autocenter || null}
            onChange={this.props.onChangeAutocenter}
            multiselect={false}
          />
        )}
        {this.datePickerFrom}
        {this.datePickerTo}
      </div>
    );
  }
}
export default CallsPlotFilters;
