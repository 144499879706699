import "../AuthForm.scss";

import React, { SyntheticEvent } from "react";

import AuthValidator from "../../../utils/authValidator/AuthValidator";
import LabelText from "../../custom/text/LabelText";
import { SigninData } from "../../../services/AuthService";
import StringMethods from "../../../UI/utils/StringMethods";

interface Props {
  signin: (data: SigninData) => void;
}
interface State {
  login: string;
  password: string;
}

class Signin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      login: "",
      password: "",
    };
  }

  private onChangeLogin(login: string) {
    this.setState({
      login,
    });
  }
  private onChangePassword(password: string) {
    this.setState({
      password,
    });
  }

  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (
      AuthValidator.isCorrectLogin(this.state.login) &&
      AuthValidator.isCorrectPassword(this.state.password)
    ) {
      const salt = Math.floor(+new Date() / 1000).toString();
      const data: SigninData = {
        login: this.state.login,
        password: StringMethods.sha256(
          StringMethods.sha256(this.state.password) + salt
        ),
        salt,
      };
      this.props.signin(data);
    }
  }

  public render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <LabelText
          label="Логин"
          value={this.state.login}
          onChange={this.onChangeLogin.bind(this)}
          minLength={AuthValidator.login.minLength}
          maxLength={AuthValidator.login.maxLength}
          data-correct={AuthValidator.isCorrectLogin(this.state.login)}
        />
        <LabelText
          label="Пароль"
          type={"password"}
          value={this.state.password}
          onChange={this.onChangePassword.bind(this)}
          minLength={AuthValidator.password.minLength}
          maxLength={AuthValidator.password.maxLength}
          data-correct={AuthValidator.isCorrectPassword(this.state.password)}
        />
        <button
          disabled={
            !(
              AuthValidator.isCorrectLogin(this.state.login) &&
              AuthValidator.isCorrectPassword(this.state.password)
            )
          }
        >
          Войти
        </button>
      </form>
    );
  }
}

export default Signin;
