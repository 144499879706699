import "./LoadFiles.scss";

import { Route, RouteComponentProps, Switch } from "react-router";

import LoadInfoContainer from "../../containers/load/LoadInfoContainer";
import { NavLink } from "react-router-dom";
import React from "react";
import SmsAdminPanelContainer from "../../containers/sms/SmsAdminPanelContainer";

interface Props extends RouteComponentProps<{ type: string }> {}

interface State {}
class LoadFiles extends React.Component<Props, State> {
  public render() {
    return (
      <div className="load-files">
        <div className="select-page">
          <NavLink to="/load/info">Звонки</NavLink>
          <NavLink to="/load/sms">СМС</NavLink>
        </div>
        <Switch>
          <Route path="/load/info" component={LoadInfoContainer} />
          <Route path="/load/sms" component={SmsAdminPanelContainer} />
        </Switch>
      </div>
    );
  }
}

export default LoadFiles;
