import "../../../styles/labels.scss";

import React, { SyntheticEvent } from "react";
import Loup from "../../../assets/icons/loup/Loup";

class LabelSearch extends React.Component<UI.Label.Props<string>> {
  private handleChange(event: SyntheticEvent<HTMLInputElement>) {
    this.props.onChange(event.currentTarget.value);
  }

  public render() {
    return (
      <label className="label label-comment search">
        <input
          type="text"
          placeholder="Поиск"
          onChange={this.handleChange.bind(this)}
        />
        <div>
          <Loup />
        </div>
      </label>
    );
  }
}

export default LabelSearch;
