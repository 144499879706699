import NavMenu from "../../components/navMenu/NavMenu";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Unsubscribe } from "redux";
import { homePath } from "../../routes";
import store from "../../store/store";
import { toggleAutocentersLinksAction } from "../../store/actions/autocentersActions";

interface State {
  user: App.User | null;
  isOpenAutocentersList: boolean;
  disabledCallsLink: boolean;
}

class NavMenuContainer extends React.Component<RouteComponentProps, State> {
  private unsubscribe?: Unsubscribe;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      user: null,
      isOpenAutocentersList: false,
      disabledCallsLink: true,
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    this.handleUpdateStore();
  }

  public componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  private handleUpdateStore() {
    const {
      session: { user },
      autocenters: { isLoaded, isOpenLinks },
    } = store.getState();
    this.setState({
      user,
      isOpenAutocentersList: isOpenLinks,
      disabledCallsLink: !isLoaded,
    });
    if (user !== this.state.user) {
    }
  }

  private goToHome() {
    this.props.history.push(homePath);
  }

  private handleClickOnCallsLink() {
    store.dispatch(toggleAutocentersLinksAction());
  }

  public render() {
    return (
      <NavMenu
        user={this.state.user}
        isOpenAutocentersList={this.state.isOpenAutocentersList}
        disabledCallsLink={this.state.disabledCallsLink}
        toggleAutocentersLinks={this.handleClickOnCallsLink.bind(this)}
        goToHome={this.goToHome.bind(this)}
      />
    );
  }
}

export default NavMenuContainer;
