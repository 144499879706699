export const BASE_URL = "https://cm.renault-belarus.by/server";
// export const BASE_URL = "http://cj.jsonstateham.com/server";
export const TIMEOUT = 10000000;
export const COLORS_FOR_SMS_STATISTIC = {
  firstDay: "#FFAA00",
  secondDay: "#FF0000",
  otherDays: "#4DC7AD",
  notCalling: "#dadada",
};

export const BRANDS = [
  { id: 1, name: "Lada", value: "LADA" },
  { id: 2, name: "Renault", value: "RENAULT" },
  { id: 3, name: "Mitsubishi", value: "MITSUBISHI" },
];
export const YEARS = [
  { id: 2021, name: "2021", value: "2021" },
  { id: 2020, name: "2020", value: "2020" },
  { id: 2019, name: "2019", value: "2019" },
  { id: 2018, name: "2018", value: "2018" },
  { id: 2017, name: "2017", value: "2017" },
  { id: 2016, name: "2016", value: "2016" },
];
export const MONTHS = [
  { id: 1, name: "Январь", value: "01" },
  { id: 2, name: "Февраль", value: "02" },
  { id: 3, name: "март", value: "03" },
  { id: 4, name: "Апрель", value: "04" },
  { id: 5, name: "Май", value: "05" },
  { id: 6, name: "Июнь", value: "06" },
  { id: 7, name: "Июль", value: "07" },
  { id: 8, name: "Август", value: "08" },
  { id: 9, name: "Сентябрь", value: "09" },
  { id: 10, name: "Октябрь", value: "10" },
  { id: 11, name: "Ноябрь", value: "11" },
  { id: 12, name: "Декабрь", value: "12" },
];
export const SENDINGS = [
  { id: 1, name: "Салон", value: "salon" },
  { id: 2, name: "Сервис", value: "service" },
];
