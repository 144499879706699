import "./SmsGroupView.scss";

import { notFoundPath, smsGroupsPath } from "../../routes";

import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router";
import SmsGroupContainer from "../../containers/sms/SmsGroupContainer";

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {
  name: string;
  sms: Array<App.SmsSending>;
}

class SmsGroupView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "Загрузка",
      sms: [],
    };
  }
  public componentDidMount() {
    Page.setTitle(`Смс группа №${this.props.match.params.id}`);
  }
  public componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      Page.setTitle(`Смс группа №${this.props.match.params.id}`);
      this.setState({ sms: [] });
    }
  }

  private handleChangeGroup(group?: App.SmsGroup) {
    let name = "Ошибка";
    if (group) {
      name = group.name;
    }
    this.setState({ name });
    Page.setTitle(name);
  }

  private redirectToList() {
    this.props.history.replace(smsGroupsPath);
  }
  private redirectToNotFound() {
    this.props.history.replace(notFoundPath);
  }
  public render() {
    return (
      <div className="sms-group-view">
        <SmsGroupContainer
          id={+this.props.match.params.id}
          onLoad={this.handleChangeGroup.bind(this)}
          redirectToList={this.redirectToList.bind(this)}
          redirectToNotFound={this.redirectToNotFound.bind(this)}
        />
      </div>
    );
  }
}

export default SmsGroupView;
