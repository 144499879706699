import {
  AudioActions,
  selectAudioType,
  selectAudiosListType,
} from "../actions/audio";

export interface AudioStore {
  active: string;
  list: Array<string>;
}

export const defaultAudioStore: AudioStore = {
  active: "",
  list: [],
};

export default function (
  state: AudioStore,
  action: AudioActions
): App.Store.Audio {
  switch (action.type) {
    case selectAudioType:
      state.active = action.active;
      break;
    case selectAudiosListType:
      state.list = action.list;
      break;
    default:
      break;
  }

  return state;
}
