import "./SmsSendingCard.scss";

import React, { SyntheticEvent } from "react";

import SmsSendigCardFooter from "./smsSendigCardFooter/SmsSendigCardFooter";
import SmsSendingCalls from "../statistic/smsStatisticCalls/SmsSendingCalls";
import SmsSendingDaysPassed from "./smsSendingDaysPassed/SmsSendingDaysPassed";
import SmsSendingHead from "./smsSendingHead/SmsSendingHead";
import SmsStatisticByCities from "../statistic/smsStatisticByCities/SmsStatisticByCities";
import SmsStatisticByDays from "../statistic/smsStatisticByDays/SmsStatisticByDays";
import Time from "../../../UI/utils/Time";

interface Props {
  className?: string;
  smsSending: App.SmsSending;
  onClick?: (event: SyntheticEvent, smsSending: App.SmsSending) => void;
}
interface State {}

class SmsSendingCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private get totalCalls(): number {
    return this.props.smsSending.calls.reduce((g, e) => g + e, 0);
  }
  private get daysPassedAfterSending(): number {
    return Math.floor((+new Date() - +this.props.smsSending.date) / Time.day);
  }

  private handleClick(event: SyntheticEvent) {
    this.props.onClick && this.props.onClick(event, this.props.smsSending);
  }

  public render() {
    return (
      <div
        className={`sms-sending-card ${this.props.className || ""}`}
        onClick={this.handleClick.bind(this)}
      >
        <SmsSendingHead
          brand={this.props.smsSending.brand}
          sending={this.props.smsSending.sending}
          name={this.props.smsSending.name}
        />
        <SmsSendingCalls
          totalCalls={this.totalCalls}
          totalSms={this.props.smsSending.totalSms}
        />
        <div className="statistic">
          <SmsStatisticByCities
            totalCalls={this.totalCalls}
            cities={this.props.smsSending.cities.slice(0, 3)}
          />
          <SmsStatisticByDays
            totalCalls={this.totalCalls}
            calls={this.props.smsSending.calls}
          />
        </div>
        <SmsSendingDaysPassed
          dateSending={this.props.smsSending.date}
          daysPassedAfterSending={this.daysPassedAfterSending}
        />

        <SmsSendigCardFooter
          text={this.props.smsSending.text}
          id={this.props.smsSending.id}
        />
      </div>
    );
  }
}
export default SmsSendingCard;
