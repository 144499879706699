import SmsGroupService, {
  SmsGroupsListParams,
} from "../../services/SmsGroupService";

import { AxiosError } from "axios";
import React from "react";
import SmsGroupFormContainer from "./SmsGroupFormContainer";
import SmsGroups from "../../components/sms/groups/SmsGroups";
import SmsGroupsFilters from "../../components/sms/groups/filters/SmsGroupsFilters";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {}
interface State {
  isEnd: boolean;
  loading: boolean;
  page: number;
  groups: Array<App.SmsGroup>;
  isOpen: boolean;
  keyword: string;
  lastKeyword: string;
}

class SmsGroupsContainer extends React.Component<Props, State> {
  private smsGroupService: SmsGroupService;
  constructor(props: Props) {
    super(props);
    this.smsGroupService = new SmsGroupService();
    this.state = {
      isEnd: false,
      loading: false,
      page: 0,
      groups: [],
      isOpen: false,

      keyword: "",
      lastKeyword: "",
    };
  }

  public componentDidMount() {
    this.getList(0, "");
  }
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.page !== prevState.page ||
      this.state.keyword !== prevState.keyword
    ) {
      this.getList(this.state.page, this.state.keyword);
    }
  }
  private async getList(page: number, keyword: string) {
    if (this.state.loading || (page !== 0 && this.state.isEnd)) return;
    this.setState({ loading: true });
    const params: SmsGroupsListParams = {
      page,
      keyword,
      amount: 10,
    };

    this.smsGroupService
      .getList(params)
      .then((response) => {
        this.setState({
          isEnd: response.data.length === 0,
          page: page,
          keyword: keyword,
          groups: page
            ? this.state.groups.concat(response.data)
            : response.data,
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || [
              "Не удалось получить список груп смс рассылок",
            ]
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private handleChangeKeyword(keyword: string) {
    this.setState({ keyword, page: 0 });
  }
  private handleOpen() {
    this.setState({ isOpen: true });
  }
  private handleClose(group?: App.SmsGroup) {
    this.setState({
      groups: group ? [group].concat(this.state.groups) : this.state.groups,
      isOpen: false,
    });
  }
  private getNextPage() {
    if (this.state.loading || this.state.isEnd) return;
    this.getList(this.state.page + 1, this.state.keyword);
  }

  public render() {
    return (
      <>
        <SmsGroupsFilters
          open={this.handleOpen.bind(this)}
          keyword={this.state.keyword}
          onChangeKeyword={this.handleChangeKeyword.bind(this)}
          loading={this.state.loading}
        />

        <SmsGroups
          groups={this.state.groups}
          loadMore={this.getNextPage.bind(this)}
          loading={this.state.loading}
        />
        <SmsGroupFormContainer
          isOpen={this.state.isOpen}
          close={this.handleClose.bind(this)}
        />
      </>
    );
  }
}
export default SmsGroupsContainer;
