import Button from "../../../UI/components/button/Button";
import React from "react";
import Trunk from "./trunk/Trunk";
import { TrunkData } from "../../../services/TrunkService";
import TrunkForm from "../trunkForm/TrunkForm";

interface Props {
  trunks: Array<App.Trunk>;
  create: (trunk: TrunkData) => Promise<boolean>;
  update: (trunk: App.Trunk) => Promise<boolean>;
  delete: (id: number) => void;
}
interface State {
  isOpen: boolean;
  trunk?: App.Trunk;
}

class TrunkList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private getMethodForDelete(trunk: App.Trunk) {
    return () => {
      this.props.delete(trunk.id);
    };
  }
  private getMethodForUpdate(trunk: App.Trunk) {
    return () => {
      this.setState({
        trunk,
      });
      this.open();
    };
  }
  private open() {
    this.setState({ isOpen: true });
  }
  private close() {
    this.setState({ trunk: undefined, isOpen: false });
  }
  private submit(trunk: TrunkData) {
    if (this.state.trunk) {
      return this.props.update(
        Object.assign(trunk, { id: this.state.trunk.id })
      );
    }
    return this.props.create(trunk);
  }

  public render() {
    return (
      <div className="trunk-list">
        <Button onClick={this.open.bind(this)}>Добавить</Button>
        <ul>
          {this.props.trunks.map((trunk) => (
            <Trunk
              key={trunk.id}
              {...trunk}
              delete={this.getMethodForDelete(trunk)}
              update={this.getMethodForUpdate(trunk)}
            />
          ))}
        </ul>
        {this.state.isOpen && (
          <TrunkForm
            close={this.close.bind(this)}
            trunk={this.state.trunk}
            submit={this.submit.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default TrunkList;
