import "./SmsGroupCard.scss";

import React from "react";
import SmsGroupCardHead from "./head/SmsGroupCardHead";
import SmsGroupFooter from "./footer/SmsGroupFooter";
import SmsStatisticByDays from "../../statistic/smsStatisticByDays/SmsStatisticByDays";
import SmsStatisticCalls from "../../statistic/smsStatisticCalls/SmsSendingCalls";

interface Props {
  group: App.SmsGroup;
}
interface State {}

class SmsGroupCard extends React.Component<Props, State> {
  public render() {
    return (
      <div className="sms-group-card">
        <SmsGroupCardHead group={this.props.group} />
        <SmsStatisticCalls
          totalSms={this.props.group.totalSms}
          totalCalls={this.props.group.calls.reduce((g, e) => g + e)}
        />
        <SmsStatisticByDays
          totalCalls={this.props.group.calls.reduce((g, e) => g + e)}
          calls={this.props.group.calls}
        />

        <SmsGroupFooter
          amount={this.props.group.sendings.length}
          id={this.props.group.id}
        />
      </div>
    );
  }
}

export default SmsGroupCard;
