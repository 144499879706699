import "./AudioContainer.scss";
import "react-h5-audio-player/lib/styles.css";

import Arrow from "../../UI/components/arrow/Arrow";
import AudioPlayer from "react-h5-audio-player";
import Download from "../../UI/assets/download.png";
import React from "react";
import { Unsubscribe } from "redux";
import { selectAudio } from "../../store/actions/audio";
import store from "../../store/store";

interface Props {}
interface State {
  active_src: string;
  active: string;
  list: Array<string>;
  isOpen: boolean;
  playbackRate: number;
}

class AudioContainer extends React.Component<Props, State> {
  private unsubscribe?: Unsubscribe;
  private ref: React.RefObject<any>;
  static playbackRates: number[] = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5];

  constructor(props: Props) {
    super(props);
    this.state = {
      active_src: "",
      active: "",
      list: [],
      isOpen: false,
      playbackRate: 1,
    };
    this.ref = React.createRef();
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.active !== this.state.active)
      this.setState({ playbackRate: 1 });
    if (prevState.playbackRate !== this.state.playbackRate)
      this.ref.current.audio.current.playbackRate = this.state.playbackRate;
  }

  public componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  private handleUpdateStore() {
    const { audio } = store.getState();
    if (audio.active === this.state.active) {
      this.setState(audio);
    } else {
      this.setState({
        active: audio.active,
        active_src: audio.active,
        list: this.state.list.includes(audio.active)
          ? audio.list
          : this.state.list.concat(audio.active),
        isOpen: true,
      });
    }
  }
  private goNext() {
    const index = this.state.list.indexOf(this.state.active) + 1;
    const length = this.state.list.length;
    store.dispatch(
      selectAudio(
        index === length ? this.state.list[0] : this.state.list[index]
      )
    );
  }
  private goPrevious() {
    const index = this.state.list.indexOf(this.state.active) - 1;
    const length = this.state.list.length;
    store.dispatch(
      selectAudio(
        index <= 0
          ? this.state.list[Math.max(length - 1, 0)]
          : this.state.list[index]
      )
    );
  }

  private open() {
    this.setState({ isOpen: true });
  }
  private close() {
    this.setState({ isOpen: false });
  }

  private changePlaybackRate(event: React.MouseEvent<HTMLDivElement>) {
    let playbackRate = event.currentTarget.getAttribute("data-playback-rate");
    if (playbackRate) this.setState({ playbackRate: +playbackRate });
  }

  public render() {
    return (
      <div className="audio-container" data-open={this.state.isOpen}>
        <AudioPlayer
          src={this.state.active}
          autoPlay
          onClickNext={this.goNext.bind(this)}
          onClickPrevious={this.goPrevious.bind(this)}
          ref={this.ref}
          showJumpControls={false}
          showDownloadProgress
          header={
            this.state.isOpen ? (
              <Arrow direction="down" onClick={this.close.bind(this)} />
            ) : (
              <Arrow direction="up" onClick={this.open.bind(this)} />
            )
          }
          footer={
            <div className="player-footer">
              <div className="player-src">
                {this.state.active_src && (
                  <a title="Скачать" href={this.state.active_src} download>
                    <img src={Download} alt="D" />
                  </a>
                )}
              </div>
              {AudioContainer.playbackRates.map((pr: number) => (
                <div
                  key={pr}
                  className={`playback-rate${
                    this.state.playbackRate === pr ? " active" : ""
                  }`}
                  data-playback-rate={pr}
                  onClick={this.changePlaybackRate.bind(this)}
                >{`${pr}x`}</div>
              ))}
            </div>
          }
        />
      </div>
    );
  }
}

export default AudioContainer;
