import React from "react";
import "./Title.scss";
interface Props {
  className: string;
}
class Title extends React.Component<Props> {
  static defaultProps = {
    className: "title",
  };
  public render() {
    return <h1 className={this.props.className}>{this.props.children}</h1>;
  }
}

export default Title;
