import React from "react";

interface Props {
  fill: string;
  stroke: string;
  title?: string;
  onClick?: () => void;
}

class Calendar extends React.Component<Props> {
  public render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26"
        width="26px"
        height="26px"
        {...this.props}
      >
        <path d="M 7 0 C 6.449219 0 6 0.449219 6 1 L 6 4 C 6 4.550781 6.449219 5 7 5 C 7.550781 5 8 4.550781 8 4 L 8 1 C 8 0.449219 7.550781 0 7 0 Z M 19 0 C 18.449219 0 18 0.449219 18 1 L 18 4 C 18 4.550781 18.449219 5 19 5 C 19.550781 5 20 4.550781 20 4 L 20 1 C 20 0.449219 19.550781 0 19 0 Z M 3 2 C 1.34375 2 0 3.34375 0 5 L 0 23 C 0 24.65625 1.34375 26 3 26 L 23 26 C 24.65625 26 26 24.65625 26 23 L 26 5 C 26 3.34375 24.65625 2 23 2 L 21 2 L 21 4 C 21 5.105469 20.105469 6 19 6 C 17.894531 6 17 5.105469 17 4 L 17 2 L 9 2 L 9 4 C 9 5.105469 8.105469 6 7 6 C 5.894531 6 5 5.105469 5 4 L 5 2 Z M 2 9 L 24 9 L 24 23 C 24 23.550781 23.550781 24 23 24 L 3 24 C 2.449219 24 2 23.550781 2 23 Z M 9 12 L 9 14.3125 L 13.8125 14.3125 L 10.03125 21.96875 L 13.5 21.96875 L 16.96875 13.53125 L 16.96875 12 Z" />
      </svg>
    );
  }
}

export default Calendar;
