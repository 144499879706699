import "./SmsSendingByCity.scss";

import React from "react";

interface Props {
  name: string;
  percent: number;
}
interface State {}

class SmsSendingByCity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="sms-sending-by-city">
        <div className="city-name">{this.props.name}</div>
        <div className="start"></div>
        <div className="progress">
          <div
            className="complite"
            style={{ width: `${this.props.percent || 0}%` }}
          ></div>
        </div>
        <div className="end"></div>
        <div className="percent">{this.props.percent || 0}%</div>
      </div>
    );
  }
}
export default SmsSendingByCity;
