import "./SmsStatisticPlotByDays.scss";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import Box from "../../../custom/box/Box";
import React from "react";
import SmsSendingCalls from "../smsStatisticCalls/SmsSendingCalls";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface Props {
  calls: Array<number>;
  totalSms: number;
}
interface State {
  chartRef: React.RefObject<HTMLDivElement>;
  chart?: am4charts.XYChart;
}

class SmsStatisticPlotByDays extends React.Component<Props, State> {
  constructor(props: Props) {
    am4core.useTheme(am4themes_animated);
    super(props);
    this.state = {
      chartRef: React.createRef(),
    };
  }

  static colors = [
    new am4core.Color(am4core.color("#AC0F46").rgb),
    new am4core.Color(am4core.color("#EB2330").rgb),
    new am4core.Color(am4core.color("#FDA100").rgb),
    new am4core.Color(am4core.color("#FCDE04").rgb),
    new am4core.Color(am4core.color("#7FB905").rgb),
    new am4core.Color(am4core.color("#009688").rgb),
    new am4core.Color(am4core.color("#01BBD3").rgb),
    new am4core.Color(am4core.color("#038ECF").rgb),
    new am4core.Color(am4core.color("#064973").rgb),
  ];

  public componentDidMount() {
    this.createPlot();
  }
  public componentDidUpdate(prevProps: Props) {
    if (this.props.calls !== prevProps.calls) {
      this.createPlot();
    }
  }
  public componentWillUnmount() {
    this.state.chart?.dispose();
  }

  private get totalCalls() {
    return this.props.calls.reduce((g, e) => g + e, 0);
  }

  private createPlot() {
    this.state.chart?.dispose();

    const tag = this.state.chartRef.current;
    if (!tag) return;

    const chart = am4core.create(tag, am4charts.XYChart);
    chart.colors.list = SmsStatisticPlotByDays.colors;
    chart.data = this.props.calls.map((e, i) => {
      return {
        name: `День ${++i}`,
        value: e,
        percent: +((e / this.totalCalls) * 100).toFixed(0),
      };
    });

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.grid.template.disabled = false;
    xAxis.dataFields.category = "name";
    xAxis.renderer.minGridDistance = 60;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.fill = am4core.color("#666666");

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.extraMax = 0.1;
    yAxis.renderer.labels.template.fill = am4core.color("#666666");

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "name";
    series.dataFields.valueY = "value";
    // @ts-ignore
    series.dataFields.percent = "percent";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem?.index || 0);
    });

    chart.zoomOutButton.disabled = true;

    let bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.dy = -8;
    bullet.fontSize = 14;
    bullet.label.text = "{percent}% ({valueY})";
    bullet.label.fill = am4core.color("#666666");
  }

  public render() {
    return (
      <Box className="sms-statistic-plot-by-days" shadow={false}>
        <SmsSendingCalls
          totalSms={this.props.totalSms}
          totalCalls={this.totalCalls}
        />
        <div className="chart" ref={this.state.chartRef} />
      </Box>
    );
  }
}
export default SmsStatisticPlotByDays;
