import AutocenterForm from "../../components/autocenter/form/AutocenterForm";
import AutocenterRedact from "../../components/autocenter/redact/AutocenterRedact";
import { AxiosError } from "axios";
import GroupForm from "../../components/group/form/GroupForm";
import GroupService from "../../services/GroupService";
import Question from "../../UI/components/popups/question/Question";
import React from "react";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  index: number;
  autocenter: App.Autocenter;
  onStartMove: (index: number) => void;
  onMouseEnter: (index: number) => void;
  onEndMove: () => void;
  updateCurrentAutocenter: (autocenter: App.Autocenter) => void;
}
interface State {
  isOpenForm: boolean;
  isOpenFormGroup: boolean;
  isOpenQuestion: boolean;
}

class AutocenterRedactContainer extends React.Component<Props, State> {
  private groupService: GroupService;
  constructor(props: Props) {
    super(props);
    this.groupService = new GroupService();
    this.state = {
      isOpenForm: false,
      isOpenFormGroup: false,
      isOpenQuestion: false,
    };
  }

  private handleTryAddGroup() {
    this.setState({ isOpenFormGroup: true });
  }
  private handleAddGroup(group: App.GroupData) {
    group.weight = this.props.autocenter.groups.length;
    this.groupService
      .create(group, this.props.autocenter.id)
      .then((response) => {
        this.setState({ isOpenFormGroup: false });
        const autocenter = this.props.autocenter;
        autocenter.groups = autocenter.groups
          .concat(response.data)
          .sort((a, b) => (a.weight > b.weight ? 1 : -1));
        this.props.updateCurrentAutocenter(autocenter);
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Не удалось создать группу"])
        );
      });
  }

  private handleUpdate() {}
  private handleDelete() {}
  private handleClose() {
    this.setState({
      isOpenForm: false,
      isOpenFormGroup: false,
      isOpenQuestion: false,
    });
  }

  private updateCurrentGroup(group: App.Group) {
    const autocenter = this.props.autocenter;
    autocenter.groups = autocenter.groups
      .map((g) => (g.id === group.id ? group : g))
      .sort((a, b) => (a.weight > b.weight ? 1 : -1));
    this.props.updateCurrentAutocenter(autocenter);
  }

  public render() {
    return (
      <>
        <AutocenterRedact
          index={this.props.index}
          autocenter={this.props.autocenter}
          onStartMove={this.props.onStartMove}
          onMouseEnter={this.props.onMouseEnter}
          onEndMove={this.props.onEndMove}
          onTryAddGroup={this.handleTryAddGroup.bind(this)}
          updateCurrentGroup={this.updateCurrentGroup.bind(this)}
        />
        {this.state.isOpenForm && (
          <AutocenterForm
            autocenter={this.props.autocenter}
            submit={this.handleUpdate.bind(this)}
            close={this.handleClose.bind(this)}
          />
        )}
        {this.state.isOpenFormGroup && (
          <GroupForm
            group={null}
            submit={this.handleAddGroup.bind(this)}
            close={this.handleClose.bind(this)}
          />
        )}

        {this.state.isOpenQuestion && (
          <Question
            question="Вы действительно хотите удалить автоцентр?"
            onAgree={this.handleDelete.bind(this)}
            onDisagree={this.handleClose.bind(this)}
          />
        )}
      </>
    );
  }
}

export default AutocenterRedactContainer;
