import "./SmsSendingForm.scss";

import React, { SyntheticEvent } from "react";

import LabelDateTime from "../../custom/datetime/LabelDateTime";
import LabelText from "../../custom/text/LabelText";
import Select from "../../custom/select/Select";
import ShadowBack from "../../../UI/components/popups/shadowBack/ShadowBack";

interface Props {
  close: () => void;
  submit: (data: App.SmsSendingData) => void;
  loading: boolean;
  default?: App.SmsSending;
}
interface State {
  name: string;
  text: string;
  date: Date | null;
  brand: number | null;
  sending: number | null;
}

class SmsSendingForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: props.default?.name || "",
      text: props.default?.text || "",
      date: props.default?.date ? props.default.date : null,
      brand: props.default?.brand
        ? this.getBrandIndex(props.default.brand)
        : null,
      sending: props.default?.sending
        ? this.getSendingIndex(props.default.sending)
        : null,
    };
  }
  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();

    if (
      !this.props.loading &&
      this.state.name &&
      this.state.date &&
      this.state.brand
    ) {
      this.props.submit({
        name: this.state.name,
        text: this.state.text,
        date: this.state.date,
        brand: this.getBrand(this.state.brand),
        sending: this.getSending(this.state.sending),
      });
    }
  }

  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangeText(text: string) {
    this.setState({ text });
  }

  private getSendingIndex(sending: App.SendingType | null) {
    switch (sending) {
      case "salon":
        return 1;
      case "service":
        return 2;
      default:
        return null;
    }
  }
  private getBrandIndex(brand: App.Brand) {
    switch (brand) {
      case "LADA":
        return 1;
      case "RENAULT":
        return 2;
      case "MITSUBISHI":
        return 3;
      default:
        return null;
    }
  }
  private getBrand(brand: number | null): App.Brand {
    switch (brand) {
      case 1:
        return "LADA";
      case 2:
        return "RENAULT";
      case 3:
        return "MITSUBISHI";
      default:
        return "LADA";
    }
  }
  private getSending(sending: number | null): App.SendingType | null {
    switch (sending) {
      case 1:
        return "salon";
      case 2:
        return "service";
      default:
        return null;
    }
  }

  private handleChangeBrand(brand: number | null) {
    this.setState({ brand });
  }
  private handleChangeSending(sending: number | null) {
    this.setState({ sending });
  }
  private handleChangeDate(date: Date) {
    this.setState({ date });
  }

  public render() {
    return (
      <ShadowBack onClick={this.props.close}>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          className="form sms-sending-form"
        >
          <h2>Редактирование рассылки</h2>
          <LabelText
            label="Название"
            value={this.state.name}
            onChange={this.handleChangeName.bind(this)}
            maxLength={256}
          />
          <LabelText
            area
            label="Текст"
            value={this.state.text}
            onChange={this.handleChangeText.bind(this)}
          />
          <LabelDateTime
            label="Дата рассылки"
            showTimeSelect
            selected={this.state.date}
            dateFormat="dd.MM.yyyy HH:mm"
            onChange={this.handleChangeDate.bind(this)}
          />

          <Select
            label="Бренд"
            onChange={this.handleChangeBrand.bind(this)}
            selected={this.state.brand}
            multiselect={false}
            options={[
              { id: 1, name: "LADA" },
              { id: 2, name: "RENAULT" },
              { id: 3, name: "MITSUBISHI" },
            ]}
          />
          <Select
            label="Рассылка"
            onChange={this.handleChangeSending.bind(this)}
            selected={this.state.sending}
            multiselect={false}
            options={[
              { id: 1, name: "Салон" },
              { id: 2, name: "Сервис" },
            ]}
          />

          <div className="buttons">
            <button
              onClick={(event) => {
                event.preventDefault();
                this.props.close();
              }}
            >
              Отмена
            </button>
            <button disabled={this.props.loading || !this.state.name}>
              Сохранить
            </button>
          </div>
        </form>
      </ShadowBack>
    );
  }
}

export default SmsSendingForm;
