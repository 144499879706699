import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";
import Time from "../UI/utils/Time";

export interface StatisticParams {
  autocenter?: number;
  group?: number;
  operator?: number;
  type?: "incoming" | "lost" | "not_answered" | "outgoing" | "sms_sendings";
  from: number;
  to: number;
  flag?: "month" | "week" | "day" | "hour";
}
export interface StatisticResponse {
  incoming: any;
  outgoing: any;
  not_answered: any;
  lost: any;
  sms_sendings: any;
}
export interface ParamsFilter {
  autocenter?: number;
  group?: number;
  operator?: number;
}
export interface ParamsFilterForList extends ParamsFilter {
  page: number;
  sort: string;
  order: "ASC" | "DESC";
  keyword?: string;
  from: string;
  to: string;
}
export interface ParamsFilterForPlot extends ParamsFilter {
  from: number;
  to: number;
}

export interface ParamsForCallsList {
  page: number;
  date?: number;
  number?: number;
  id?: number;
}

export interface ParamsFilterForPlotGroups extends ParamsFilterForPlot {
  incoming: string;
  outgoing: string;
}

export interface WaveResponse {
  waves: {
    [key: number]: string;
  };
  possible_records: Array<App.PossibleRecord>;
}

class CallService {
  public async getStaticByMonth(
    params: StatisticParams
  ): Promise<App.ServiceResponse<StatisticResponse>> {
    params.flag = "month";
    const Authorization = await JWT.getAuth();
    return Axios.get("/calls/new-statistic", {
      params,
      headers: { Authorization, DontDisplayLoader: true },
    }).then((response: AxiosResponse<StatisticResponse>) => {
      return {
        data: response.data,
        status: true,
      };
    });
  }
  public async getStaticByWeek(
    params: StatisticParams
  ): Promise<App.ServiceResponse<StatisticResponse>> {
    params.flag = "week";
    const Authorization = await JWT.getAuth();
    return Axios.get("/calls/new-statistic", {
      params,
      headers: { Authorization, DontDisplayLoader: true },
    }).then((response: AxiosResponse<StatisticResponse>) => {
      return {
        data: response.data,
        status: true,
      };
    });
  }
  public async getStaticByDay(
    params: StatisticParams
  ): Promise<App.ServiceResponse<StatisticResponse>> {
    params.flag = "day";
    const Authorization = await JWT.getAuth();
    return Axios.get("/calls/new-statistic", {
      params,
      headers: { Authorization, DontDisplayLoader: true },
    }).then((response: AxiosResponse<StatisticResponse>) => {
      return {
        data: response.data,
        status: true,
      };
    });
  }
  public async getStaticByHour(
    params: StatisticParams
  ): Promise<App.ServiceResponse<StatisticResponse>> {
    params.flag = "hour";
    const Authorization = await JWT.getAuth();
    return Axios.get("/calls/new-statistic", {
      params,
      headers: { Authorization, DontDisplayLoader: true },
    }).then((response: AxiosResponse<StatisticResponse>) => {
      return {
        data: response.data,
        status: true,
      };
    });
  }
  public async getCallsBorders(
    params: ParamsFilter
  ): Promise<
    App.ServiceResponse<{ minTimestamp: number; maxTimestamp: number }>
  > {
    const Authorization = await JWT.getAuth();
    return Axios.get("/calls/borders", {
      params,
      headers: { Authorization, DontDisplayLoader: true },
    }).then(
      (
        response: AxiosResponse<{
          min_timestamp: number;
          max_timestamp: number;
        }>
      ) => {
        return {
          status: true,
          data: {
            minTimestamp: response.data.min_timestamp * Time.second,
            maxTimestamp: response.data.max_timestamp * Time.second,
          },
        };
      }
    );
  }

  public async getPoltysRecords(
    params: ParamsForCallsList
  ): Promise<App.ServiceResponse<Array<App.Call>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/calls", {
      headers: { Authorization, DontDisplayLoader: true },
      params,
    }).then(
      (
        response: AxiosResponse<Array<App.Call>>
      ): App.ServiceResponse<Array<App.Call>> => {
        return {
          status: true,
          data: response.data,
        };
      }
    );
  }
  public async loadWaves(
    extensions: Array<number>
  ): Promise<App.ServiceResponse<WaveResponse>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/waves", {
      headers: { Authorization, DontDisplayLoader: true },
      params: { extensions: JSON.stringify(extensions) },
    }).then((response: AxiosResponse<WaveResponse>) => {
      return { status: true, data: response.data };
    });
  }
  public getCallsFor1C(params: {
    name: string;
    phone: string;
    timestamp: string;
    signature: string;
  }): Promise<App.ServiceResponse<Array<App.Call>>> {
    return Axios.get("/calls-for-1c", {
      params,
    }).then(
      (
        response: AxiosResponse<Array<App.Call>>
      ): App.ServiceResponse<Array<App.Call>> => {
        return {
          status: true,
          data: response.data,
        };
      }
    );
  }
  public getWavesFor1C(
    extensions: Array<number>,
    params: {
      name: string;
      phone: string;
      timestamp: string;
      signature: string;
    }
  ): Promise<App.ServiceResponse<WaveResponse>> {
    return Axios.get("/waves-for-1c", {
      params: { extensions: JSON.stringify(extensions), ...params },
    }).then((response: AxiosResponse<WaveResponse>) => {
      return { status: true, data: response.data };
    });
  }
  public async getUndistributedExtensionNumbers(): Promise<App.ServiceResponse<Array<string>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/unknown-extension-numbers", {
      headers: { Authorization },
    }).then((response: AxiosResponse<Array<string>>) => {
      return { status: true, data: response.data };
    });
  }
}

export default CallService;
