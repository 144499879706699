import React from "react";

class TextCell extends React.Component<UI.Table.TextCellProps> {
  public render() {
    return (
      <td onMouseOver={this.props.onMouseOver} className={this.props.className}>
        {this.props.text || "-"}
      </td>
    );
  }
}

export default TextCell;
