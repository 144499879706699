import React from "react";
import Time from "../../../../utils/Time";

class DateCell extends React.Component<UI.Table.DateCellProps> {
  public render() {
    return (
      <td onMouseOver={this.props.onMouseOver} className={this.props.className}>
        {Time.beautifyDateTime(this.props.date)}
      </td>
    );
  }
}

export default DateCell;
