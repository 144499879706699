import "./BrandName.scss";

import React from "react";

interface Props {
  brand: App.Brand;
}
interface State {}

class BrandName extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className={`brand-name ${this.props.brand.toLocaleLowerCase()}`}>
        {this.props.brand.toLowerCase()}
      </div>
    );
  }
}
export default BrandName;
