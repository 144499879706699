import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";

export interface ParamsForTotalNumber {
  autocenter?: number;
  group?: number;
}
export interface ParamsForList {
  autocenter?: number;
  group?: number;
  keyword?: string;

  page: number;
  sort: string;
  order: "ASC" | "DESC";
  type: "all" | "clients" | "employees";
}

class AbonentService {
  public async getTotalNumberAbonents(
    params: ParamsForTotalNumber
  ): Promise<
    App.ServiceResponse<{
      clients: number;
      employees: number;
    }>
  > {
    const Authorization = await JWT.getAuth();
    return Axios.get("/client/total", {
      params,
      headers: { Authorization, DontDisplayLoader: true },
    }).then(
      (
        response: AxiosResponse<{
          clients: number;
          employees: number;
        }>
      ): App.ServiceResponse<{
        clients: number;
        employees: number;
      }> => {
        return {
          status: true,
          data: response.data,
        };
      }
    );
  }
}

export default AbonentService;
