import React from "react";
import StringMethods from "../../../../utils/StringMethods";

class NumberCell extends React.Component<UI.Table.NumberCellProps> {
  public render() {
    return (
      <td onMouseOver={this.props.onMouseOver} className={this.props.className}>
        {StringMethods.beautifyNumber(this.props.value)}
      </td>
    );
  }
}

export default NumberCell;
