import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";

export interface Filters {
  page: number;
  type: string; //"all" | "users" | "admins";
  keyword: string;
}
export interface UserData {
  autocenters: Array<number>;
  role: string;
}

class UserService {
  public async getList(
    params: Filters
  ): Promise<App.ServiceResponse<Array<App.UserInfo>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/users", {
      params,
      headers: { Authorization },
    }).then((response: AxiosResponse<Array<App.UserInfo>>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }

  public async update(
    user: UserData,
    id: number
  ): Promise<App.ServiceResponse<App.UserInfo>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(`/user/${id}`, user, {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.UserInfo>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
}

export default UserService;
