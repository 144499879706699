import "./Box.scss";

import React, { SyntheticEvent } from "react";

interface Props {
  title?: string;
  className?: string;
  shadow: boolean;
  onClick?: (event: SyntheticEvent<HTMLDivElement>) => void;
}
interface State {}

class Box extends React.Component<Props, State> {
  static defaultProps = {
    shadow: true,
  };
  private get className() {
    let className = "box";
    if (this.props.shadow) {
      className += " shadow";
    }
    if (this.props.className) {
      className += " " + this.props.className;
    }
    return className;
  }
  public render() {
    return (
      <div className={this.className} onClick={this.props.onClick}>
        {!!this.props.title && (
          <div className="box-title">{this.props.title}</div>
        )}
        <div className="box-container">{this.props.children}</div>
      </div>
    );
  }
}

export default Box;
