import React from "react";
import MissedRecordsTableHead from "./missedRecordsTableHead/MissedRecordsTableHead";
import MissedRecordsTableRow from "./missedRecordsTableRow/MissedRecordsTableRow";
import './MissedRecordsTable.scss'

interface Props {
  records: Array<App.Record>
  playAudio: (audio?: string) => void
}

export default class MissedRecordsTable extends React.Component<Props, any> {
  public render() {
    return <table className="missed-records-table">
      <MissedRecordsTableHead/>
      <tbody>
      {
        this.props.records.map((r) => (
          <MissedRecordsTableRow
            key={r.id}
            record={r}
            playAudio={this.props.playAudio}
          />
        ))
      }
      </tbody>
    </table>
  }
}