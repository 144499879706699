import "./TrunkForm.scss";

import React, { SyntheticEvent } from "react";

import Button from "../../../UI/components/button/Button";
import LabelText from "../../../UI/components/labels/text/LabelText";
import ShadowBack from "../../../UI/components/popups/shadowBack/ShadowBack";
import { TrunkData } from "../../../services/TrunkService";
import { prevent } from "../../../UI/utils/EventMethods";

interface Props {
  close: () => void;
  trunk?: App.Trunk;
  submit: (trunk: TrunkData) => Promise<boolean>;
}
interface State {
  code: string;
  name: string;
}

class TrunkForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      code: "",
      name: "",
    };
  }

  public componentDidMount() {
    const { trunk } = this.props;
    if (!!trunk) {
      this.setState({
        code: trunk.code.toString(),
        name: trunk.name,
      });
    }
  }

  private get isValide() {
    return (
      +this.state.code > 0 &&
      +this.state.code <= 99999999 &&
      this.state.name.length > 0 &&
      this.state.name.length <= 128
    );
  }

  private close(event: SyntheticEvent) {
    prevent(event);
    this.props.close();
  }
  private async handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    prevent(event);
    if (!this.isValide) return;
    const res = await this.props.submit({
      code: +this.state.code,
      name: this.state.name,
    });
    if (res) {
      this.props.close();
    }
  }

  private handleChangeCode(code: string) {
    this.setState({ code: (+code).toString() });
  }
  private handleChangeName(name: string) {
    this.setState({ name });
  }
  public render() {
    return (
      <ShadowBack onClick={this.props.close}>
        <form className="trunk-form" onSubmit={this.handleSubmit.bind(this)}>
          <LabelText
            required
            data-number
            label="Код"
            value={this.state.code}
            onChange={this.handleChangeCode.bind(this)}
            maxLength={8}
            placeholder="0000"
          />
          <LabelText
            required
            label="Название"
            value={this.state.name}
            onChange={this.handleChangeName.bind(this)}
            maxLength={128}
          />
          <div className="buttons">
            <Button>Сохранить</Button>
            <Button onClick={this.close.bind(this)}>Отмена</Button>
          </div>
        </form>
      </ShadowBack>
    );
  }
}

export default TrunkForm;
