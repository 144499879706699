import "./AutocenterForm.scss";

import React, { SyntheticEvent } from "react";

import AutocenterValidator from "../../../utils/autocenterValidator/AutocenterValidator";
import LabelText from "../../custom/text/LabelText";
import ShadowBack from "../../../UI/components/popups/shadowBack/ShadowBack";

interface Props {
  autocenter: App.Autocenter | null;
  submit: (autocenter: App.AutocenterData) => void;
  close: () => void;
}
interface State {
  name: string;
  address: string;
}

class AutocenterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      address: "",
    };
  }

  public componentDidMount() {
    if (this.props.autocenter) {
      this.setState({
        name: this.props.autocenter.name,
        address: this.props.autocenter.address,
      });
    }
  }
  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangeAddress(address: string) {
    this.setState({ address });
  }
  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (
      !AutocenterValidator.isCorrectAddress(this.state.address) ||
      !AutocenterValidator.isCorrectName(this.state.name)
    )
      return;
    this.props.submit(this.state);
  }
  private close(event: SyntheticEvent) {
    event.preventDefault();
    this.props.close();
  }

  public render() {
    return (
      <ShadowBack onClick={this.props.close}>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          className="autocenter-form form"
        >
          <h1>Добавить автоцентр</h1>
          <LabelText
            label="Название"
            value={this.state.name}
            onChange={this.handleChangeName.bind(this)}
            maxLength={AutocenterValidator.aName.maxLength}
            minLength={AutocenterValidator.aName.minLength}
            correct={AutocenterValidator.isCorrectName(this.state.name)}
            required
          />
          <LabelText
            label="Адресс"
            value={this.state.address}
            onChange={this.handleChangeAddress.bind(this)}
            maxLength={AutocenterValidator.address.maxLength}
            minLength={AutocenterValidator.address.minLength}
            correct={AutocenterValidator.isCorrectAddress(this.state.address)}
            required
          />

          <div className="buttons">
            <button
              disabled={
                !AutocenterValidator.isCorrectAddress(this.state.address) ||
                !AutocenterValidator.isCorrectName(this.state.name)
              }
            >
              Сохранить
            </button>
            <button onClick={this.close.bind(this)}>Отмена</button>
          </div>
        </form>
      </ShadowBack>
    );
  }
}

export default AutocenterForm;
