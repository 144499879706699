import "./UndistributedExtensionNumberView.scss";

import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Unsubscribe } from "redux";
import store from "../../store/store";
import UndistributedExtensionNumberContainer from "../../containers/undistributedExtensionNumber/UndistributedExtensionNumberContainer";

interface Props extends RouteComponentProps {}
interface State {
  isAdmin: boolean;
}

export default class UndistributedExtensionNumberView extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      isAdmin: this.isAdmin(),
    };
  }

  public componentDidMount() {
    Page.setTitle("Нераспред. номера");
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState({
      isAdmin: this.isAdmin(),
    });
  }

  private isAdmin(): boolean {
    try {
      return store.getState().session.user?.role === "admin";
    } catch {
      return false;
    }
  }

  public render() {
    return (
      <div className="undistributed-extension-number-view">
        <h1 className="undistributed-extension-number-view-title">Список номеров нераспределенных по автоцентрам</h1>
        {!this.state.isAdmin && <h2>У вас не достаточно прав</h2>}
        {this.state.isAdmin && <UndistributedExtensionNumberContainer />}
      </div>
    );
  }
}