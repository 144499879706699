import SmsService, { AddSmsFileData } from "../../services/SmsService";

import { AxiosError } from "axios";
import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router";
import SmsAdminPanel from "../../components/sms/adminPanel/SmsAdminPanel";

interface Props extends RouteComponentProps {}
interface State {
  saved: number;
  loading: boolean;
  loaded: boolean;
  failed: boolean;
  errors: Array<string>;

  isDisplayErrors: boolean;
}

class SmsAdminPanelContainer extends React.Component<Props, State> {
  private smsService: SmsService;

  constructor(props: Props) {
    super(props);
    this.smsService = new SmsService();
    this.state = {
      saved: 0,
      loading: false,
      errors: [],
      loaded: false,
      failed: false,
      isDisplayErrors: false,
    };
  }
  public componentDidMount() {
    Page.setTitle("Загрузка смс рассылок");
  }

  private async save(dataForSave: AddSmsFileData) {
    if (this.state.loading) return;
    this.setState({ loading: true, loaded: false, failed: false, errors: [] });

    this.smsService
      .addFile(dataForSave)
      .then((response) => {
        this.setState({
          errors: response.data || [],
          isDisplayErrors: (response.data || []).length > 0,
          saved: this.state.saved + 1,
          loaded: true,
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        this.setState({
          errors: error.response?.data || ["Ошибка сохранения файла"],
          failed: true,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private closeErrorsList() {
    this.setState({ isDisplayErrors: false });
  }
  public render() {
    return (
      <>
        <SmsAdminPanel
          loading={this.state.loading}
          saved={this.state.saved}
          save={this.save.bind(this)}
          errors={this.state.errors}
          succesfulLoad={this.state.loaded}
          failedLoad={this.state.failed}
          isDisplayErrors={this.state.isDisplayErrors}
          closeErrorsList={this.closeErrorsList.bind(this)}
        />
      </>
    );
  }
}
export default SmsAdminPanelContainer;
