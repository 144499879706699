// import LdsSpinner from "../../loaders/spinners/lds/LdsSpinner";
import React, { SyntheticEvent } from "react";

interface Prop {
  image: string | File;
  index: number;
  deleteImage: (index: number) => void;
}
interface State {
  image: string;
}

class Image extends React.Component<Prop, State> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      image: ""
    };
  }

  public componentDidMount() {
    if (this.props.image instanceof File) {
      this.parseImageToDataUrl(this.props.image);
    } else {
      this.setState({ image: this.props.image });
    }
  }

  private parseImageToDataUrl(file: File): void {
    new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => {
        if (fr.result) {
          resolve(fr.result);
        } else {
          reject();
        }
      };
    })
      .then((result: unknown): void => {
        this.setState({ image: "" + result });
      })
      .catch((): void => {
        this.setState({ image: "ERROR" });
      });
  }

  private handleClick(event: SyntheticEvent) {
    event.stopPropagation();
    this.props.deleteImage(this.props.index);
  }

  public render() {
    return (
      <div className="image">
        {this.state.image && this.state.image !== "ERROR" && (
          <>
            <img src={this.state.image} alt="" />
            <div className="delete" onClick={this.handleClick.bind(this)}>
              <span>Удалить</span>
            </div>
          </>
        )}

        {this.state.image === "ERROR" && <div className="error-load"></div>}
        {/* {this.state.image === "" && <LdsSpinner />} */}
        {this.props.children}
      </div>
    );
  }
}

export default Image;
