import React from "react";
import ListImg from "./assets/list.png";

export const defaultButtonsPackForMarkdown: Array<UI.Markdown.Command> = [
  {
    cmd: "**",
    name: "I",
    children: <i>I</i>,
  },
  {
    cmd: "*****",
    name: "B",
    children: <b>B</b>,
  },
  {
    cmd: "~~~~",
    name: "S",
    children: <del>S</del>,
  },
  {
    cmd: "#",
    name: "тТ",
  },
  {
    cmd: "*",
    name: "ul",
    children: <img src={ListImg} alt="" />,
  },
  {
    cmd: "1.",
    name: "0l",
    children: <img src={ListImg} alt="" />,
  },
];

export const defaultSvgProps: UI.SVG.Props = {
  fill: "#000000",
  stroke: "#000000",
};

export const defaultCurrency: string = "BYN";
