import "./CallsTableContainer.scss";

import { AxiosError } from "axios";
import CallService from "../../services/CallService";
import CallsFor1cTable from "../../components/calls/callsFor_1c/callsFor_1cTable/CallsFor_1cTable";
import LabelDateTime from "../../components/custom/datetime/LabelDateTime";
import LabelText from "../../components/custom/text/LabelText";
import LabelWithButton from "../../components/custom/labelWithButton/LabelWithButton";
import React from "react";
import Select from "../../components/custom/select/Select";
import Time from "../../UI/utils/Time";
import { Unsubscribe } from "redux";
import { displayErrors } from "../../store/actions/error";
import { selectAudio } from "../../store/actions/audio";
import store from "../../store/store";
import LDSSpinner from "../../UI/components/loaders/spinners/lds/LdsSpinner";

interface Props {
  autocenter?: number;
  group?: number;
  operator?: number;
  search?: boolean;
}
interface State {
  loading: boolean;
  types: Array<number>;

  groups: Array<{ id: number; name: string }>;
  group: { id: number; name: string } | null;
  date: Date;
  number: string;
  id: string;
  withWaves: number;
  find: "number" | "date" | "id" | "none";
  active: string;
  calls: Array<App.Call>;
}

class CallsTableContainer extends React.Component<Props, State> {
  private callService: CallService;
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.callService = new CallService();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    this.state = {
      loading: false,
      find: props.search ? "none" : "date",
      types: [],
      groups: [],
      group: null,
      date: new Date(),
      number: "",
      id: "",
      active: "",
      withWaves: 0,
      calls: [],
    };
  }

  static numberLoadingAudio = 10;
  static columns: Array<UI.Table.Column> = [
    { name: "ID", sortFlag: "id" },
    { name: "", sortFlag: "type" },
    { name: "Куда пришёл", sortFlag: "place" },
    { name: "Номер абонента", sortFlag: "caller_number" },
    { name: "Дата", sortFlag: "date_call" },
    { name: "Начало", sortFlag: "time_start" },
    { name: "Конец", sortFlag: "time_end" },
    { name: "Ответ", sortFlag: "answer" },
    { name: "Внутренний", sortFlag: "number" },
    { name: "Время ожидания", sortFlag: "expectation_time" },
    { name: "Время разговора", sortFlag: "duration" },
    { name: "Время удержания", sortFlag: "retention_time" },
    { name: "Примечание", sortFlag: "comment" },
    { name: "Аудио", sortFlag: "is_audio" },
  ];
  static titles = {
    date: "Поиск по дате",
    number: "Поиск по номер",
    id: "Поиск по ID",
    none: "Поиск",
  };
  static types = [
    { id: 1, type: "incoming", name: "Входящие" },
    { id: 2, type: "outgoing", name: "Исходящие" },
    { id: 3, type: "missing", name: "Пропущеные" },
    { id: 4, type: "loss", name: "Потерянные" },
  ];
  static itemsOnPage = 300;

  public componentDidMount() {
    this.load();
  }
  public componentDidUpdate(props: Props) {
    if (
      this.props.operator !== props.operator ||
      this.props.group !== props.group ||
      this.props.autocenter !== props.autocenter
    ) {
      this.load(true);
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private get params() {
    const params: any = {
      autocenter: this.props.autocenter,
      group: this.props.group,
      operator: this.props.operator,
      number: this.state.number ? this.state.number : undefined,
      id: this.state.id,
      date: Math.floor(+this.state.date / Time.second),
      with_waves: this.state.withWaves,
    };
    if (!params.autocenter) delete params.autocenter;
    if (!params.group) delete params.group;
    if (!params.operator) delete params.operator;

    switch (this.state.find) {
      case "number":
        delete params.id;
        delete params.date;
        break;
      case "id":
        delete params.number;
        delete params.date;
        break;
      default:
        delete params.number;
        delete params.id;
        break;
    }

    return params;
  }
  private handleUpdateStore() {
    this.setState({ active: store.getState().audio.active });
  }
  private findByNumber() {
    this.setState({ find: "number" });
    setTimeout(() => this.load());
  }
  private findByDate() {
    this.setState({ find: "date" });
    setTimeout(() => this.load());
  }
  private findById() {
    this.setState({ find: "id" });
    setTimeout(() => this.load());
  }

  private async load(forcibly?: boolean) {
    if (
      this.state.find === "none" ||
      (!forcibly && this.state.loading) ||
      (this.state.find === "number" && this.state.number.length < 4)
    )
      return;
    this.setState({ loading: true });

    this.callService
      .getPoltysRecords(this.params)
      .then((response) => {
        const names: Set<string> = new Set();
        response.data.forEach((call) => {
          names.add(call.group);
        });
        if (this.params.with_waves)
          response.data.forEach((call) => (call.hasAudio = true));
        this.setState({
          calls: response.data,
          group: null,
          groups: Array.from(names).map((name, id) => ({ id, name })),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Ошибка загрузки данных"])
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private async getWaves(extensions: Array<number>, call: number) {
    this.callService
      .loadWaves(extensions)
      .then((response) => {
        this.setState({
          calls: this.state.calls?.map((c) => {
            if (c.id === call) {
              c.extensions = c.extensions.map((e) => {
                e.wave = response.data.waves[e.id] || "";
                return e;
              });
              c.possible_records = response.data.possible_records;
              c.hasAudio = true;
            }
            return c;
          }),
        });
      })
      .catch((err) => {
        store.dispatch(displayErrors(err));
      });
  }

  private playAudio(audio?: string) {
    if (audio) {
      store.dispatch(selectAudio(audio));
    }
  }
  private filter(calls: Array<App.Call>): Array<App.Call> {
    const checkIncoming: boolean = this.state.types.includes(
      CallsTableContainer.types.find((e) => e.type === "incoming")?.id || -1
    );
    const checkOutgoing: boolean = this.state.types.includes(
      CallsTableContainer.types.find((e) => e.type === "outgoing")?.id || -1
    );
    const checkMissing: boolean = this.state.types.includes(
      CallsTableContainer.types.find((e) => e.type === "missing")?.id || -1
    );
    const checkLoss: boolean = this.state.types.includes(
      CallsTableContainer.types.find((e) => e.type === "loss")?.id || -1
    );
    const checkGgoup: boolean = !!this.state.group;

    return calls.filter((call) => {
      if (checkGgoup && call.group !== this.state.group?.name) {
        return false;
      }

      if (
        (checkIncoming || checkOutgoing || checkMissing || checkLoss) === false
      ) {
        return true;
      }

      if (checkIncoming && !!call.is_incoming) {
        return true;
      }
      if (checkOutgoing && !call.is_incoming) {
        return true;
      }
      if (
        checkMissing &&
        call.extensions.some((e) => e.active_duration === 0)
      ) {
        return true;
      }
      if (checkLoss && call.active_duration === 0 && !!call.is_incoming) {
        return true;
      }

      return false;
    });
  }

  private changeDate(date: Date) {
    this.setState({ date });
  }
  private changeNumber(number: string) {
    this.setState({ number });
  }
  private changeId(id: string) {
    this.setState({ id });
  }
  private handleChangeType(types: Array<number>) {
    this.setState({ types });
  }
  private handleChangeGroup(
    groupId: number | null,
    group?: { id: number; name: string }
  ) {
    this.setState({ group: group || null });
  }

  public render() {
    return (
      <div className="records-poltys-table">
        <div className="records-poltys-table-search-controlls">
          <LabelWithButton
            className={this.state.find === "date" ? "active" : undefined}
            onClick={this.findByDate.bind(this)}
            disabled={this.state.loading}
            buttonText={"Искать по дате"}
          >
            <LabelDateTime
              label="Выберите день"
              selected={this.state.date}
              onChange={this.changeDate.bind(this)}
              dateFormat="dd.MM.yyyy"
              maxDate={new Date()}
            />
          </LabelWithButton>
          <LabelWithButton
            className={this.state.find === "number" ? "active" : undefined}
            onClick={this.findByNumber.bind(this)}
            buttonText={"Искать по номеру"}
            disabled={this.state.loading || this.state.number.length < 4}
          >
            <LabelText
              label="Введите номер"
              type="number"
              value={this.state.number}
              onChange={this.changeNumber.bind(this)}
            />
          </LabelWithButton>

          <Select
            label="Тип"
            multiselect={true}
            options={CallsTableContainer.types}
            selected={this.state.types}
            onChange={this.handleChangeType.bind(this)}
          />
          <Select
            label="Группа"
            options={this.state.groups}
            selected={this.state.group ? this.state.group.id : this.state.group}
            multiselect={false}
            onChange={this.handleChangeGroup.bind(this)}
          />
          <LabelWithButton
            className={this.state.find === "id" ? "active" : undefined}
            onClick={this.findById.bind(this)}
            disabled={this.state.loading}
            buttonText={"Искать по ID"}
          >
            <LabelText
              label="Введите ID"
              data-number
              value={this.state.id}
              onChange={this.changeId.bind(this)}
            />
          </LabelWithButton>
          <div className="with-waves">
            <div className="label">С аудио</div>
            <input
              type="checkbox"
              onChange={() =>
                this.setState({ withWaves: this.state.withWaves ? 0 : 1 })
              }
            />
          </div>
        </div>
        <CallsFor1cTable
          calls={this.filter(this.state.calls)}
          getWaves={this.getWaves.bind(this)}
          playAudio={this.playAudio.bind(this)}
        />
        {this.state.loading && (
          <div className="calls-for-1c-table-loader">
            <LDSSpinner />
          </div>
        )}

        <div className="amount-calls">
          <span>
            {this.state.find === "date"
              ? Time.beautifyDate(this.state.date)
              : ""}{" "}
            всего звонков:
          </span>
          <b>{this.state.calls?.length || 0}</b>
        </div>
      </div>
    );
  }
}

export default CallsTableContainer;
