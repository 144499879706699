import "./AutocentersList.scss";

import AutocenterCard from "../card/AutocenterCard";
import AutocenterForm from "../form/AutocenterForm";
import AutocenterRedactContainer from "../../../containers/autocenter/AutocenterRedactContainer";
import React from "react";

interface Props {
  redact: boolean;
  autocenters: Array<App.Autocenter>;
  loading: boolean;
  onCreate: (autocenter: App.AutocenterData) => Promise<boolean>;
  onUpdate: (autocenter: App.AutocenterData, id: number) => void;
  updateCurrentAutocenter: (autocenter: App.Autocenter) => void;
}

interface State {
  autocenters: Array<App.Autocenter>;
  dragIndex: number | null;
  isOpenForm: boolean;
}

class AutocentersList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      autocenters: props.autocenters,
      dragIndex: null,
      isOpenForm: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.autocenters !== prevProps.autocenters) {
      this.setState({ autocenters: this.props.autocenters });
    }
  }
  private handleStartMove(dragIndex: number) {
    this.setState({ dragIndex });
  }
  private handleMouseEnter(index: number) {
    if (this.state.dragIndex !== null && index !== this.state.dragIndex) {
      const dragIndex: number = this.state.dragIndex;
      const newA = this.state.autocenters.map((e) => e);
      newA[index] = this.state.autocenters[dragIndex];
      newA[dragIndex] = this.state.autocenters[index];

      this.setState({
        autocenters: newA,
        dragIndex: index,
      });
    }
  }
  private hanldeEndMove() {
    this.setState({ dragIndex: null });
    for (let index = 0; index < this.state.autocenters.length; index++) {
      if (this.state.autocenters[index].weight !== index) {
        const autocenter = this.state.autocenters[index];
        autocenter.weight = index;
        this.props.onUpdate(autocenter, autocenter.id);
      }
    }
  }
  private async handleCreateAutocenter(autocenter: App.AutocenterData) {
    autocenter.weight = this.props.autocenters.length;
    const result = await this.props.onCreate(autocenter);
    this.setState({ isOpenForm: !result });
  }
  private openFormCreateAnnouncement() {
    this.setState({ isOpenForm: true });
  }
  private closeFormCreateAnnouncement() {
    this.setState({ isOpenForm: false });
  }

  public render() {
    return (
      <div className="autocenters-list">
        {this.props.redact && (
          <button
            className="create-autocenter-button"
            onClick={this.openFormCreateAnnouncement.bind(this)}
          >
            Добавить автоцентр
          </button>
        )}
        <div className="list">
          {this.state.autocenters.map((autocenter, index) =>
            this.props.redact ? (
              <AutocenterRedactContainer
                key={autocenter.id}
                index={index}
                autocenter={autocenter}
                updateCurrentAutocenter={this.props.updateCurrentAutocenter}
                onStartMove={this.handleStartMove.bind(this)}
                onMouseEnter={this.handleMouseEnter.bind(this)}
                onEndMove={this.hanldeEndMove.bind(this)}
              />
            ) : (
              <AutocenterCard key={autocenter.id} autocenter={autocenter} />
            )
          )}
        </div>
        {this.state.isOpenForm && (
          <AutocenterForm
            autocenter={null}
            submit={this.handleCreateAutocenter.bind(this)}
            close={this.closeFormCreateAnnouncement.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default AutocentersList;
