import "./PhonesList.scss";

import PhoneCard from "../card/PhoneCard";
import PhoneForm from "../form/PhoneForm";
import PhoneRedactContainer from "../../../containers/phone/PhoneRedactContainer";
import React from "react";

interface Props {
  active?: number;
  redact: boolean;
  phones: Array<App.Phone>;
  loading: boolean;
  onUpdate: (value: App.PhoneData, id: number) => void;
  onDelete: (id: number) => void;
  onCreate: (phone: App.PhoneData) => Promise<boolean>;
  updateCurrentPhone: (phone: App.Phone) => void;
}

interface State {
  phones: Array<App.Phone>;
  isOpenForm: boolean;
  dragIndex: number | null;
}

class PhonesList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phones: props.phones,
      dragIndex: null,
      isOpenForm: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.phones !== prevProps.phones) {
      this.setState({ phones: this.props.phones });
    }
  }
  private handleStartMove(dragIndex: number) {
    this.setState({ dragIndex });
  }
  private handleMouseEnter(index: number) {
    if (this.state.dragIndex !== null && index !== this.state.dragIndex) {
      const dragIndex: number = this.state.dragIndex;
      const newA = this.state.phones.map((e) => e);
      newA[index] = this.state.phones[dragIndex];
      newA[dragIndex] = this.state.phones[index];

      this.setState({
        phones: newA,
        dragIndex: index,
      });
    }
  }
  private hanldeEndMove() {
    this.setState({ dragIndex: null });
    for (let index = 0; index < this.state.phones.length; index++) {
      if (this.state.phones[index].weight !== index) {
        const phone = this.state.phones[index];
        phone.weight = index;
        this.props.onUpdate(phone, phone.id);
      }
    }
  }

  private async handleSubmitCreatePhoneForm(phone: App.PhoneData) {
    const result = await this.props.onCreate(phone);
    this.setState({ isOpenForm: !result });
  }
  private openFormCreatePhone() {
    this.setState({ isOpenForm: true });
  }
  private closeFormCreatePhone() {
    this.setState({ isOpenForm: false });
  }

  public render() {
    return (
      <>
        <div className="phones-list">
          <div className="list" data-redact={this.props.redact}>
            {this.state.phones.map((phone, index) =>
              this.props.redact ? (
                <PhoneRedactContainer
                  key={phone.id}
                  index={index}
                  phone={phone}
                  onDelete={this.props.onDelete}
                  updateCurrentPhone={this.props.updateCurrentPhone}
                  onStartMove={this.handleStartMove.bind(this)}
                  onMouseEnter={this.handleMouseEnter.bind(this)}
                  onEndMove={this.hanldeEndMove.bind(this)}
                />
              ) : (
                <PhoneCard
                  key={phone.id}
                  phone={phone}
                  active={this.props.active === phone.id}
                />
              )
            )}
          </div>
          {this.props.redact && (
            <button
              className="create-phone-button"
              onClick={this.openFormCreatePhone.bind(this)}
            >
              +
            </button>
          )}
        </div>
        {this.state.isOpenForm && (
          <PhoneForm
            phone={null}
            submit={this.handleSubmitCreatePhoneForm.bind(this)}
            close={this.closeFormCreatePhone.bind(this)}
          />
        )}
      </>
    );
  }
}

export default PhonesList;
