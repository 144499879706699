import "./SmsSendingDaysPassed.scss";

import React from "react";
import Time from "../../../../UI/utils/Time";

interface Props {
  daysPassedAfterSending: number;
  dateSending: Date;
}
interface State {}

class SmsSendingDaysPassed extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static maxDaysPassedDay = 10;

  public render() {
    return (
      <div className="sms-sending-days-passed">
        {this.props.daysPassedAfterSending >
        SmsSendingDaysPassed.maxDaysPassedDay ? (
          <span>{Time.beautifyDate(this.props.dateSending)}</span>
        ) : (
          <span>
            {this.props.daysPassedAfterSending} дня назад (
            {Time.beautifyDate(this.props.dateSending)})
          </span>
        )}
      </div>
    );
  }
}
export default SmsSendingDaysPassed;
