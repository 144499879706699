import AuthService, {
  SigninData,
  SignupData,
} from "../../services/AuthService";
import { changeTokens, changeUser } from "../../store/actions/session";

import { AxiosError } from "axios";
import React from "react";
import Signin from "../../components/auth/signin/Signin";
import Signup from "../../components/auth/signup/Signup";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  goToHome: () => void;
}
interface State {
  loading: boolean;
  isSignup: boolean;
}

class AuthFormContainer extends React.Component<Props, State> {
  private authService: AuthService;
  constructor(props: Props) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      loading: false,
      isSignup: false,
    };
  }
  static tabs = [
    {
      id: 0,
      name: "Вход",
    },
    {
      id: 1,
      name: "Регистрация",
    },
  ];

  private get loading(): boolean {
    return this.state.loading;
  }
  private set loading(loading: boolean) {
    this.setState({ loading });
  }

  private async signin(form: SigninData) {
    if (this.loading) return;
    this.loading = true;
    this.authService
      .signin(form)
      .then((response) => {
        store.dispatch(changeUser(response.data.user));
        store.dispatch(changeTokens(response.data.session));
        this.props.goToHome();
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Неверный логин или пароль"])
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
  private async signup(form: SignupData) {
    if (this.loading) return;
    this.loading = true;
    this.authService
      .signup(form)
      .then(() => {
        this.setState({ isSignup: false });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(error.response?.data || ["Ошибка регистрации"])
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
  private toggleForm() {
    this.setState({ isSignup: !this.state.isSignup });
  }

  public render() {
    return (
      <div className="auth-form">
        {this.state.isSignup ? (
          <Signup signup={this.signup.bind(this)} />
        ) : (
          <Signin signin={this.signin.bind(this)} />
        )}

        <button
          disabled={this.state.loading}
          className="form-toggler"
          onClick={this.toggleForm.bind(this)}
        >
          {this.state.isSignup ? "Вход" : "Регистрация"}
        </button>

        {this.state.isSignup && (
          <div className="tip">
            <span>Разрешены символы: </span>
            <b>{"@ . _ , * & ? ^ : ;  # % $ -"}</b>
          </div>
        )}
      </div>
    );
  }
}

export default AuthFormContainer;
