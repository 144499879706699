import "./LabelWithButton.scss";

import React, { SyntheticEvent } from "react";

interface Props {
  className?: string;
  disabled?: boolean;
  buttonText: string;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}
interface State {}

class LabelWithButton extends React.Component<Props, State> {
  public render() {
    return (
      <div
        className={
          "with-button" +
          (this.props.className ? ` ${this.props.className}` : "")
        }
      >
        {this.props.children}
        <button onClick={this.props.onClick} disabled={this.props.disabled}>
          {this.props.buttonText}
        </button>
      </div>
    );
  }
}

export default LabelWithButton;
