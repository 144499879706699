type ChangeTokensType = "CHANGE_TOKENS";
type ChangeUserType = "CHANGE_USER";
type LogoutType = "LOGOUT";

interface ChangeTokensAction {
  type: ChangeTokensType;
  tokens: App.Tokens | null;
}
interface ChangeUserAction {
  type: ChangeUserType;
  user: App.User | null;
}
interface LogoutAction {
  type: LogoutType;
}

export type SessionActions =
  | ChangeTokensAction
  | ChangeUserAction
  | LogoutAction;
export const changeTokensType: ChangeTokensType = "CHANGE_TOKENS";
export const changeUserType: ChangeUserType = "CHANGE_USER";
export const logoutType: LogoutType = "LOGOUT";

export function changeTokens(tokens: App.Tokens | null): ChangeTokensAction {
  return {
    type: changeTokensType,
    tokens,
  };
}
export function changeUser(user: App.User | null): ChangeUserAction {
  return {
    type: changeUserType,
    user,
  };
}
export function logout(): LogoutAction {
  return {
    type: logoutType,
  };
}
