import { AxiosError } from "axios";
import { CONFLICT } from "http-status-codes";
import FileParser from "../../UI/utils/FileParser";
import LoadInfo from "../../components/load/info/LoadInfo";
import Page from "../../UI/utils/Page";
import React from "react";
import RecordService from "../../services/RecordService";
import { RouteComponentProps } from "react-router";
import StringMethods from "../../UI/utils/StringMethods";
import Time from "../../UI/utils/Time";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props extends RouteComponentProps {}
interface State {
  info: Array<App.LoadRecordsInfoAboutFiles>;
  rows: Array<UI.Table.RowData>;
  records: Array<App.IFile>;
  total: number;
  complite: number;
  filters: UI.Filters.TableFilters;
}

class LoadInfoContainer extends React.Component<Props, State> {
  private recordService: RecordService;
  constructor(props: Props) {
    super(props);
    this.recordService = new RecordService();

    this.state = {
      info: [],
      rows: [],
      records: [],
      total: 0,
      complite: 0,
      filters: {
        page: 0,
        isEnd: false,
        tab: 0,
        keyword: "",
        column: "",
        order: 1,
      },
    };
  }

  static columns: Array<UI.Table.Column> = [
    { sortFlag: "status", name: "Статус" },
    { sortFlag: "start_timestamp", name: "Начало разговора" },
    { sortFlag: "number", name: "Номер оператора" },
    { sortFlag: "client", name: "Номер абонента" },
    { sortFlag: "dialed", name: "Набранный" },
    { sortFlag: "duration", name: "Продолжительность" },
    { sortFlag: "wave_name", name: "Имя файла" },
  ];
  static options: UI.Filters.TableFiltersOptions = {
    tabs: [
      { id: -1, name: "Все" },
      { id: 2, name: "Загружены" },
      { id: 3, name: "Ошибки" },
      { id: 0, name: "Не загружены" },
    ],
    allowedColumns: [],
  };
  static rules: UI.Filters.Rules = {
    autoload: false,
    search: false,
    tab: true,
  };

  public componentDidMount() {
    Page.setTitle("Загрузка звонков");
  }

  private get complite(): number {
    return this.state.complite;
  }
  private set complite(complite: number) {
    this.setState({ complite });
  }

  private async handleChangeInfo(info: Array<App.IFile>) {
    if (info[0] && info[0].file) {
      const list = await FileParser.read(info[0].file);
      if (list.status) {
        this.parseInfoFile(list.data);
        window.setTimeout(this.saveCallsInfo.bind(this));
      }
    }
  }

  private parseInfoFile(str: string) {
    try {
      const rows = str.split("\n");
      rows.pop();
      const map = this.parseHeadRow(rows.shift());
      if (this.checkMap(map)) {
        let baseId = +new Date();
        let notAdded = 0;
        const info: Array<App.LoadRecordsInfoAboutFiles> = [];
        rows.forEach((row) => {
          try {
            const columns = row.split(",").map((column) => column.trim());
            info.push({
              id: baseId++,
              start_timestamp: +new Date(
                columns[map["Start Date"]].split(".").reverse().join("-") +
                  "T" +
                  columns[map["Start Time"]]
              ),
              extension: columns[map["Extension"]],
              caller_id: columns[map["Caler ID"]].slice(-9),
              dialed_number: columns[map["Dialed Number"]].slice(-9),
              duration: columns[map["Duration"]],
              wave: columns[map["WaveName"]].split("/").pop() || "",
              status: 0,
            });
          } catch {
            notAdded++;
          }
        });
        if (notAdded) {
          store.dispatch(
            displayErrors([`Не удалось добавить ${notAdded} записей.`])
          );
        }
        this.setState({ info, rows: this.getRows(info) });
      }
    } catch (err) {
      store.dispatch(displayErrors(["Некорректный формат данных."]));
    }
  }
  private parseHeadRow(headRow: string = ""): App.LoadRecordsMapHead {
    const head = headRow.split(",").map((e) => e.trim());
    return {
      "Start Date": head.indexOf("Start Date"),
      "Start Time": head.indexOf("Start Time"),
      Extension: head.indexOf("Extension"),
      "Caler ID": head.indexOf("Caller ID"),
      "Dialed Number": head.indexOf("Dialed Number"),
      Duration: head.indexOf("Duration"),
      WaveName: head.indexOf("WaveName"),
    };
  }
  private checkMap(map: App.LoadRecordsMapHead) {
    const errors: Array<string> = [];
    for (let field in map) {
      // @ts-ignore
      if (map[field] === -1 || map[field] === undefined) {
        errors.push(`В загружаемом файле отсутствует обязательная колонка "${field}".`);
      }
    }
    if (errors.length) {
      errors.unshift("Некорректный формат данных.");
      store.dispatch(displayErrors(errors));
      return false;
    }
    return true;
  }

  private getRows(
    info: Array<App.LoadRecordsInfoAboutFiles>
  ): Array<UI.Table.RowData> {
    return info.map((row) => {
      return {
        cells: [
          { type: "text", props: this.getStatus(row.status) },
          { type: "text", props: this.getStartTimestamp(row.start_timestamp) },
          { type: "text", props: this.getText(row.extension.toString()) },
          { type: "text", props: this.getPhoneNumber(row.caller_id) },
          { type: "text", props: this.getPhoneNumber(row.dialed_number) },
          { type: "text", props: this.getText(row.duration) },
          { type: "text", props: this.getText(row.wave) },
        ],
      };
    });
  }
  private getStatus(status: number): UI.Table.TextCellProps {
    switch (status) {
      case 1:
        return { text: "Загружается", className: "loading" };
      case 2:
        return { text: "Загружен", className: "loaded" };
      case 3:
        return { text: "Ошибка", className: "error" };
      case 4:
        return { text: "Дубликат", className: "dublicate" };
      default:
        return { text: "Не выбран" };
    }
  }
  private getStartTimestamp(date: number): UI.Table.TextCellProps {
    return { text: Time.beautifyDateTime(date) };
  }
  private getPhoneNumber(number: string): UI.Table.TextCellProps {
    if (number) {
      return {
        text: StringMethods.beautifyPhoneNumber(number),
      };
    }
    return { text: "-" };
  }
  private getText(text: string): UI.Table.TextCellProps {
    return { text };
  }

  private handleRestart() {
    this.setState({ info: [], rows: [] });
  }

  private async handleChangeRecords(records: Array<App.IFile>) {
    this.setState({
      total: records.length,
      filters: Object.assign({}, this.state.filters, { tab: 3 }),
    });
    const infoWaveNames = this.indicateLoadingInfo(records);
    for (let record of records) {
      const data = this.getDataForSave(record);
      if (!data || !infoWaveNames.has(record.name)) {
        this.complite++;
        continue;
      }

      await this.recordService
        .saveFile(data)
        .then((response) => {
          this.handleLoadFile(record, response.status);
        })
        .catch((error: AxiosError) => {
          this.handleLoadFile(record, error.response?.status);
        })
        .finally(() => {
          this.complite++;
        });
    }
  }
  private getDataForSave(record: App.IFile) {
    const fileInfo = this.state.info.find((i) => i.wave === record.name);
    if (!fileInfo) {
      return false;
    }
    const data = new FormData();
    for (let field in fileInfo) {
      // @ts-ignore
      data.append(field, fileInfo[field] || "");
    }

    data.set(
      "start_timestamp",
      "" + Math.floor(fileInfo.start_timestamp / Time.second)
    );
    data.delete("status");
    // @ts-ignore
    data.set("wave", record.file);
    return data;
  }
  private handleLoadFile(record: App.IFile, status?: number | boolean) {
    const info = this.state.info.map((i) => {
      if (i.wave === record.name) {
        if (status === true) {
          i.status = 2;
        } else if (status === CONFLICT) {
          i.status = 4;
        } else {
          i.status = 3;
        }
      }
      return i;
    });

    const rows = this.getRows(info);
    this.setState({
      info,
      rows,
    });
  }

  private async saveCallsInfo() {
    const data = this.state.info.map((e) => {
      return {
        start_timestamp: (e.start_timestamp / Time.second).toString(),
        extension: e.extension.toString(),
        caller_id: e.caller_id.toString(),
        dialed_number: e.dialed_number.toString(),
        duration: e.duration,
      };
    });
    const step = 40;
    for (let i = 0; i < data.length / step; i++) {
      await this.recordService
        .saveCallsInfo(data.slice(i * step, i * step + step))
        .catch((error: AxiosError<Array<string>>) => {
          store.dispatch(
            displayErrors(error.response?.data || ["Ошибка загрузки файла"])
          );
        });
    }
  }

  private indicateLoadingInfo(records: Array<App.IFile>) {
    const recordsNames = new Set();
    const infoWaveNames = new Set();
    records.forEach((record) => {
      recordsNames.add(record.name);
    });
    this.state.info.forEach((info) => {
      infoWaveNames.add(info.wave);
    });

    const info = this.state.info.map((info) => {
      if (recordsNames.has(info.wave)) {
        info.status = 1;
      }
      return info;
    });
    const rows = this.getRows(info);

    this.setState({ info, rows });

    return infoWaveNames;
  }

  private handleClosePopup() {
    if (this.state.total === this.state.complite) {
      this.setState({
        total: 0,
        complite: 0,
        rows: this.getRowsByTab(this.state.filters.tab),
      });
    }
  }

  private handleChangeFilters(filters: UI.Filters.TableFilters) {
    this.setState({ filters, rows: this.getRowsByTab(filters.tab) });
  }
  private getRowsByTab(tab: number) {
    return this.getRows(
      this.state.info.filter((i) => tab < 0 || i.status === tab)
    );
  }

  public render() {
    return (
      <LoadInfo
        complite={this.state.complite}
        filters={this.state.filters}
        info={this.state.info}
        total={this.state.total}
        records={this.state.records}
        rows={this.state.rows}
        columns={LoadInfoContainer.columns}
        options={LoadInfoContainer.options}
        rules={LoadInfoContainer.rules}
        onRestart={this.handleRestart.bind(this)}
        onChangeFilters={this.handleChangeFilters.bind(this)}
        onClosePopup={this.handleClosePopup.bind(this)}
        onChangeRecors={this.handleChangeRecords.bind(this)}
        onChangeInfo={this.handleChangeInfo.bind(this)}
      />
    );
  }
}

export default LoadInfoContainer;
