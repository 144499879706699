import "./SmsGroupForm.scss";

import React, { SyntheticEvent } from "react";

import LabelText from "../../custom/text/LabelText";
import ShadowBack from "../../../UI/components/popups/shadowBack/ShadowBack";
import { SmsGroupData } from "../../../services/SmsGroupService";
import SmsSendingsContainer from "../../../containers/sms/SmsSendingsContainer";

interface Props {
  default?: App.SmsGroup;
  loading: boolean;
  close: () => void;
  submit: (data: SmsGroupData) => void;
}
interface State {
  name: string;
  sendings: Array<number>;
}

class SmsGroupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    console.log(props.default);
    this.state = {
      name: props.default?.name || "",
      sendings: props.default?.sendings || [],
    };
  }
  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (this.props.loading) return;
    this.props.submit({
      name: this.state.name,
      sms_sendings: this.state.sendings,
    });
  }
  private handleClickOnSmsSending(
    event: SyntheticEvent,
    smsSending: App.SmsSending
  ) {
    event.preventDefault();
    if (this.state.sendings.includes(smsSending.id)) {
      this.setState({
        sendings: this.state.sendings.filter((id) => id !== smsSending.id),
      });
    } else {
      this.setState({
        sendings: this.state.sendings.concat(smsSending.id),
      });
    }
  }

  public render() {
    return (
      <ShadowBack onClick={this.props.close}>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          className="sms-group-form form"
        >
          <LabelText
            label="Название"
            value={this.state.name}
            onChange={this.handleChangeName.bind(this)}
          />

          <SmsSendingsContainer
            activeList={this.state.sendings}
            keyword
            onClick={this.handleClickOnSmsSending.bind(this)}
          />
          <div className="footer">
            <div className="selected-amount">
              Выбрано объектов: {this.state.sendings.length}
            </div>
            <div className="buttons">
              <button
                className="close"
                onClick={(event) => {
                  event.preventDefault();
                  this.props.close();
                }}
              >
                Отмена
              </button>
              <button disabled={!this.state.name}>Сохранить</button>
            </div>
          </div>
        </form>
      </ShadowBack>
    );
  }
}

export default SmsGroupForm;
