import "./NavMenu.scss";

import React, {SyntheticEvent} from "react";
import {
  autocenterPath,
  autocentersPath,
  faqPath, missedRecords,
  searchPath,
  smsesPath,
  trunksPath,
  undistributedExtensionNumber,
  usersPath,
} from "../../routes";

import AuthFormContainer from "../../containers/auth/AuthFormContainer";
import AutocentersImage from "./assets/autocenters-img.png";
import AutocentersImageActive from "./assets/autocenters-img-active.png";
import CallsImage from "./assets/calls-img.png";
import CallsImageActive from "./assets/calls-img-active.png";
import FaqActiveImage from "./assets/faq-img-active.png";
import FaqImage from "./assets/faq-img.png";
import Footer from "./footer/Footer";
import LoadImage from "./assets/load-img.png";
import LoadImageActive from "./assets/load-img-active.png";
import Logo from "./logo/Logo";
import NavMenuLink from "./navMenuLink/NavMenuLink";
import SearchImage from "./assets/search-img.png";
import SearchImageActive from "./assets/search-img-active.png";
import SmsImage from "./assets/sms-img.png";
import SmsImageActive from "./assets/sms-img-active.png";
import TrunksImage from "./assets/trunks-img.png";
import TrunksImageActive from "./assets/trunks-img-active.png";
import UsersImage from "./assets/users-img.png";
import UsersImageActive from "./assets/users-img-active.png";

interface Props {
  user: App.User | null;
  isOpenAutocentersList: boolean;
  disabledCallsLink: boolean;
  toggleAutocentersLinks: () => void;
  goToHome: () => void;
}

interface State {
}

class NavMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private handleClickOnCallsLink(event: SyntheticEvent) {
    event.preventDefault();
    if (!this.props.disabledCallsLink) {
      this.props.toggleAutocentersLinks();
    }
  }

  private get callsClassName(): string | undefined {
    if (this.props.disabledCallsLink) {
      return "disabled";
    } else if (this.props.isOpenAutocentersList) {
      return "active";
    }
    return undefined;
  }

  public render() {
    return (
      <div className="nav-menu" data-full={!this.props.user}>
        <Logo/>
        {this.props.user && (
          <div className="menu">
            <NavMenuLink
              to={autocenterPath}
              name="Звонки"
              defaultImage={CallsImage}
              className={this.callsClassName}
              onClick={this.handleClickOnCallsLink.bind(this)}
              activeImage={CallsImageActive}
            />

            <NavMenuLink
              to={smsesPath}
              name="SMS"
              defaultImage={SmsImage}
              activeImage={SmsImageActive}
            />
            <NavMenuLink
              to={searchPath}
              name="Поиск"
              defaultImage={SearchImage}
              activeImage={SearchImageActive}
            />
            {this.props.user.role === "admin" && (
              <>
                <NavMenuLink
                  to={"/load/info"}
                  name="Загрузить файл"
                  defaultImage={LoadImage}
                  activeImage={LoadImageActive}
                />
                <NavMenuLink
                  to={usersPath}
                  name="Пользователи"
                  defaultImage={UsersImage}
                  activeImage={UsersImageActive}
                />
                <NavMenuLink
                  to={autocentersPath}
                  name="Автоцентры"
                  defaultImage={AutocentersImage}
                  activeImage={AutocentersImageActive}
                />
                <NavMenuLink
                  to={trunksPath}
                  name="Транки"
                  defaultImage={TrunksImage}
                  activeImage={TrunksImageActive}
                />
                <NavMenuLink
                  to={undistributedExtensionNumber}
                  name="Нераспред. номера"
                  defaultImage={TrunksImage}
                  activeImage={TrunksImageActive}
                />
                <NavMenuLink
                  to={missedRecords}
                  name="Потерянные аудио"
                  defaultImage={FaqImage}
                  activeImage={FaqActiveImage}
                />
              </>
            )}
            <NavMenuLink
              to={faqPath}
              name="FAQ"
              defaultImage={FaqImage}
              activeImage={FaqActiveImage}
            />
          </div>
        )}
        {!this.props.user && (
          <AuthFormContainer goToHome={this.props.goToHome}/>
        )}
        <Footer isAuth={!!this.props.user}/>
      </div>
    );
  }
}

export default NavMenu;
