import AutocenterService from "../../services/AutocenterService";
import AutocentersList from "../../components/autocenter/list/AutocentersList";
import { AxiosError } from "axios";
import React from "react";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  redact: boolean;
}

interface State {
  loading: boolean;
  autocenters: Array<App.Autocenter>;
}

class AutocentersListContainer extends React.Component<Props, State> {
  private autocenterService: AutocenterService;
  constructor(props: Props) {
    super(props);
    this.autocenterService = new AutocenterService();

    this.state = {
      loading: false,
      autocenters: [],
    };
  }

  public componentDidMount() {
    this.loadAutocenters();
  }

  private loadAutocenters() {
    this.autocenterService
      .getList()
      .then((response) => {
        this.setState({
          autocenters: response.data.sort((a, b) =>
            a.weight > b.weight ? 1 : -1
          ),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось загрузить автоцентры"],
            this.loadAutocenters.bind(this)
          )
        );
      });
  }

  private create(value: App.AutocenterData): Promise<boolean> {
    return this.autocenterService
      .create(value)
      .then((response) => {
        this.setState({
          autocenters: this.state.autocenters
            .concat(response.data)
            .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось создать автоцентр"]
          )
        );
        return false;
      });
  }

  private async handleUpdate(
    value: App.AutocenterData,
    id: number
  ): Promise<boolean> {
    if (this.state.loading) return false;
    this.setState({ loading: true });

    return this.autocenterService
      .update(value, id)
      .then((response) => {
        this.setState({
          autocenters: this.state.autocenters
            .map((a) => (a.id === response.data.id ? response.data : a))
            .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
          loading: false,
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        this.setState({ loading: false });
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось редактировать автоцентр"]
          )
        );
        return false;
      });
  }

  private async handleDelete(id: number): Promise<boolean> {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.autocenterService
      .delete(id)
      .then((response) => {
        this.setState({
          loading: false,
          autocenters: this.state.autocenters.filter((a) => a.id !== id),
        });
        return true;
      })
      .catch((error: AxiosError<Array<string>>) => {
        this.setState({ loading: false });
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось Удалить автоцентр"]
          )
        );
        return false;
      });
  }

  private updateCurrentAutocenter(autocenter: App.Autocenter) {
    this.setState({
      autocenters: this.state.autocenters
        .map((a) => (a.id === autocenter.id ? autocenter : a))
        .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
    });
  }

  public render() {
    return (
      <>
        <AutocentersList
          redact={this.props.redact}
          autocenters={this.state.autocenters}
          loading={this.state.loading}
          onCreate={this.create.bind(this)}
          onUpdate={this.handleUpdate.bind(this)}
          updateCurrentAutocenter={this.updateCurrentAutocenter.bind(this)}
        />
      </>
    );
  }
}

export default AutocentersListContainer;
