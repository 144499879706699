import "./MonthPicker.scss";

import Calendar from "../assets/Calendar";
import React from "react";

interface Props {
  date?: Date;
  label?: string;
  className?: string;

  onChange: (date: Date) => void;
}
interface State {
  activeYear: number;
}

class MonthPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeYear: new Date().getFullYear(),
    };
  }

  static mounths: Array<string> = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  private get value() {
    if (!this.props.date) {
      return "";
    }
    return `${("0" + this.props.date.getDate()).slice(-2)}.${(
      "0" +
      (1 + this.props.date.getMonth())
    ).slice(-2)}.${this.props.date.getFullYear()}`;
  }
  private get year(): number | void {
    return this.props.date?.getFullYear();
  }
  private get month(): number | void {
    return this.props.date?.getMonth();
  }

  private handleClickOnMonth(month: number) {
    const date = this.props.date ? new Date(this.props.date) : new Date();
    date.setFullYear(this.state.activeYear);
    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setMonth(month);
    this.props.onChange(date);
  }

  private setPreviousYear() {
    this.setState({ activeYear: this.state.activeYear - 1 });
  }
  private setNextYear() {
    this.setState({ activeYear: this.state.activeYear + 1 });
  }

  public render() {
    return (
      <label className={"mouth-picker " + (this.props.className || "")}>
        {!!this.props.label && <div className="label">{this.props.label}</div>}
        <input type="text" value={this.value} readOnly={true}/>
        <div className="mouth-picker-container-position">
          <div className="mouth-picker-container">
            <div className="years">
              <div
                className="year-arrow"
                onClick={this.setPreviousYear.bind(this)}
              ></div>
              <div className="year">{this.state.activeYear}</div>
              <div
                className="year-arrow"
                onClick={this.setNextYear.bind(this)}
              ></div>
            </div>
            <div className="months">
              {MonthPicker.mounths.map((month, index) => (
                <div
                  onClick={() => this.handleClickOnMonth(index)}
                  className="month"
                  data-active={
                    this.year === this.state.activeYear && this.month === index
                  }
                  key={index}
                >
                  {month}
                </div>
              ))}
            </div>
          </div>
        </div>
        <img src="" alt="" />
        <Calendar stroke="none" fill="#959595" />
      </label>
    );
  }
}

export default MonthPicker;
