import "./SmsDetailsHead.scss";

import React from "react";

interface Props {
  smsSending?: App.SmsSending;
  openDeleteQuestion: () => void;
  openUpdateForm: () => void;
}
interface State {}

class SmsDetailsHead extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="sms-details-head">
        <div className="sms-details-head-title">
          {this.props.smsSending?.name || "Загрузка..."}
        </div>
        <div className="buttons">
          <button onClick={this.props.openDeleteQuestion}>Удалить</button>
          <button onClick={this.props.openUpdateForm}>Редактировать</button>
        </div>
      </div>
    );
  }
}
export default SmsDetailsHead;
