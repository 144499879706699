import ".//SmsStatisticByCities.scss";

import React from "react";
import SmsSendingByCity from "./smsSendingByCity/SmsSendingByCity";

interface Props {
  totalCalls: number;
  cities: Array<App.City>;
}
interface State {}

class SmsStatisticByCities extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="sms-statistic-by-cities">
        {this.props.cities.map((c) => (
          <SmsSendingByCity
            key={c.name}
            name={c.name}
            percent={+((c.amount / this.props.totalCalls) * 100).toFixed(0)}
          />
        ))}
      </div>
    );
  }
}
export default SmsStatisticByCities;
