import { AxiosError } from "axios";
import CallService from "../../../services/CallService";
import CallsFor1cTable from "../../../components/calls/callsFor_1c/callsFor_1cTable/CallsFor_1cTable";
import React from "react";
import { displayErrors } from "../../../store/actions/error";
import { selectAudio } from "../../../store/actions/audio";
import store from "../../../store/store";

interface Props {
  calls: Array<App.Call>;
}
interface State {
  loadedCalls: Array<number>;
  calls: Array<App.Call>;
  loading: boolean;
}

class CallsTableDefaultContainerContainer extends React.Component<
  Props,
  State
> {
  private callService: CallService;
  constructor(props: Props) {
    super(props);
    this.callService = new CallService();
    this.state = { loadedCalls: [], calls: props.calls, loading: false };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.calls !== prevProps.calls) {
      this.setState({
        calls: this.props.calls,
        loadedCalls: [],
      });
    }
  }

  private playAudio(link?: string) {
    if (link) store.dispatch(selectAudio(link));
  }
  private async getWaves(extensions: Array<number>, callId: number) {
    if (this.state.loading || this.state.loadedCalls.includes(callId)) {
      return;
    }
    this.setState({ loading: true });

    this.callService
      .loadWaves(extensions)
      .then((response) => {
        this.setState({
          loadedCalls: this.state.loadedCalls.concat(callId),
          calls: this.state.calls.map((call) => {
            if (call.id === callId) {
              call.extensions = call.extensions.map((extension, index) => {
                extension.wave = response.data.waves[extension.id] || "";
                return extension;
              });
              call.possible_records = response.data.possible_records;
            }

            return call;
          }),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        error.response && store.dispatch(displayErrors(error.response.data));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  public render() {
    return (
      <CallsFor1cTable
        calls={this.state.calls}
        playAudio={this.playAudio.bind(this)}
        getWaves={this.getWaves.bind(this)}
      />
    );
  }
}
export default CallsTableDefaultContainerContainer;
