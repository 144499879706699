import "./Table.scss";

import React, { SyntheticEvent } from "react";

import TableFilters from "../filters/TableFilters";
import TableHead from "../head/TableHead";
import TableHover from "../hover/TableHover";
import TablePagination from "../pagination/TablePagination";
import TableRow from "../row/TableRow";
import { getHandleWheel } from "../../../utils/EventMethods";

interface Props {
  title?: string;
  columns: Array<UI.Table.Column>;
  rows: Array<UI.Table.RowData>;

  rules: UI.Filters.Rules;
  filters: UI.Filters.TableFilters;
  filterOptions: UI.Filters.TableFiltersOptions;
  dispatchUpdate: (filters: UI.Filters.TableFilters) => void;

  // have default
  cellSpacing: number;
  className: string;
  dispatchLoadMore: () => void;
  maxRows: number;
  onChnagePage: (page: number) => void;
}
interface State {
  isOpenPopup: boolean;
  hover: UI.Table.Hover;
  children: JSX.Element;
  page: number;
  ref: React.RefObject<HTMLTableSectionElement>;
}

class Table extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpenPopup: false,
      hover: {
        bottom: 0,
        left: 0,
        triangle: 0,
      },
      page: 1,
      children: <></>,
      ref: React.createRef(),
    };
  }

  static defaultProps = {
    className: "",
    cellSpacing: 0,
    dispatchUpdate: () => {},
    dispatchLoadMore: () => {},
    onChnagePage: () => {},
    filters: {
      page: 0,
      column: "",
      order: 0,
      tab: 0,
      keyword: "string",
      isEnd: false,
    },
    rules: {
      tab: false,
      search: false,
      autoload: false,
    },
    filterOptions: {
      tabs: [],
      allowedColumns: [],
    },
    maxRows: 400,
  };

  private get rows() {
    return this.props.rows
      .slice(
        (this.state.page - 1) * this.props.maxRows,
        this.state.page * this.props.maxRows
      )
      .map((row, index) => {
        return (
          <TableRow
            key={row.id || index}
            className={row.className}
            cells={row.cells}
            openPopup={this.openPopup.bind(this)}
          />
        );
      });
  }
  private get percentForUpdate(): number {
    return this.props.filterOptions.percentForAutoload || 70;
  }

  private openPopup(
    event: SyntheticEvent<HTMLTableCellElement>,
    children: JSX.Element
  ) {
    if (event.currentTarget) {
      this.setState({
        isOpenPopup: true,
        hover: this.getHoverProps(event.currentTarget),
        children,
      });
    }
  }
  private closePopup(event?: SyntheticEvent<HTMLElement, MouseEvent>) {
    // @ts-ignore
    if (event?.nativeEvent.relatedTarget?.hasAttribute("data-table-hover")) {
      return;
    }
    this.setState({
      isOpenPopup: false,
      children: <></>,
    });
  }
  private getHoverProps(target: HTMLTableCellElement) {
    const rect = target.getBoundingClientRect();
    let triangle = 0;
    let left = rect.left - (125 - rect.width / 2);
    const bottom = window.innerHeight - rect.bottom + rect.height - 5;

    if (left < 10) {
      left = rect.left;
      triangle = -1;
    } else if (left + 250 > window.innerWidth - 10) {
      left = rect.right - 250;
      triangle = 1;
    }
    return {
      bottom,
      left,
      triangle,
    };
  }

  private handleWheel(percent: number) {
    if (this.props.rules.autoload && percent > this.percentForUpdate) {
      this.props.dispatchLoadMore();
    }
  }

  private handleChangePage(page: number) {
    this.setState({ page });
    this.props.onChnagePage(page);
    const el = this.state.ref.current;
    if (el) {
      el.scrollTop = 0;
    }
  }

  public render() {
    return (
      <div className={" table " + this.props.className}>
        <TableFilters
          title={this.props.title}
          rules={this.props.rules}
          filters={this.props.filters}
          filterOptions={this.props.filterOptions}
          dispatchUpdate={this.props.dispatchUpdate}
          children={this.props.children}
        />
        <TablePagination
          pageNumber={Math.ceil(this.props.rows.length / this.props.maxRows)}
          active={this.state.page}
          hadleSelectPage={this.handleChangePage.bind(this)}
        />
        <table
          className="ui-table"
          cellSpacing={this.props.cellSpacing}
          onMouseOut={this.closePopup.bind(this)}
        >
          <TableHead
            columns={this.props.columns}
            filterOptions={this.props.filterOptions}
            filters={this.props.filters}
            dispatchUpdate={this.props.dispatchUpdate}
          />
          <tbody
            ref={this.state.ref}
            onWheel={getHandleWheel(this.handleWheel.bind(this))}
          >
            {this.rows}
          </tbody>
        </table>
        <TablePagination
          pageNumber={Math.ceil(this.props.rows.length / this.props.maxRows)}
          active={this.state.page}
          hadleSelectPage={this.handleChangePage.bind(this)}
        />
        {this.state.isOpenPopup && (
          <TableHover
            {...this.state.hover}
            closePopup={this.closePopup.bind(this)}
          >
            {this.state.children}
          </TableHover>
        )}
      </div>
    );
  }
}

export default Table;
