import React, { DragEvent, RefObject, SyntheticEvent } from 'react';
import './DragAndDrop.scss';

interface IDragAndDropProps {
  onDrop?: (event: DragEvent<HTMLDivElement>) => void,
  onClick?: (event: SyntheticEvent) => void,
}
interface IDragAndDropState {
  isDragEnter: boolean,
  ref: RefObject<HTMLDivElement>
}

class DragAndDrop extends React.Component<IDragAndDropProps, IDragAndDropState> {
  constructor(props: IDragAndDropProps) {
    super(props);

    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      isDragEnter: false,
      ref: React.createRef(),
    }
  }

  private handleDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({isDragEnter: false});
    if (this.props.onDrop) {
      this.props.onDrop(event)
    }
  }
  private handleDragEnter() {
    this.setState({isDragEnter: true});
  }
  private handleDragLeave() {
    this.setState({isDragEnter: false});
  }
  private handleDragOver(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
  }
  private handleClick(event: SyntheticEvent<HTMLDivElement, MouseEvent>) {
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  public render() {
    return <div
      ref={this.state.ref} 
      className="drag-and-drop-container"
      onDrop={this.handleDrop}
      onDragEnter={this.handleDragEnter}
      onDragLeave={this.handleDragLeave}
      onDragOver={this.handleDragOver}
      data-active={this.state.isDragEnter}
      onClick={this.handleClick}
    >
      {this.props.children}
    </div>
  }
}

export default DragAndDrop;