import "./LastSmsList.scss";

import React from "react";
import SmsSendingCard from "../smsSendingCard/SmsSendingCard";

interface Props {
  statistic: Array<App.SmsSending>;
}
interface State {}

class LastSmsList extends React.Component<Props, State> {
  public render() {
    return (
      <div className="last-sms-list">
        {this.props.statistic.map((smsSending) => (
          <SmsSendingCard key={smsSending.id} smsSending={smsSending} />
        ))}
      </div>
    );
  }
}

export default LastSmsList;
