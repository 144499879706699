import './TableHover.scss';

import React, { SyntheticEvent } from 'react';

class TableHover extends React.Component<UI.Table.Hover & {closePopup: () => void}> {

  private get triangleStyles() {
    if (this.props.triangle === -1) {
      return {
        left: 10,
      }
    } else if (this.props.triangle === 1) {
      return {
        left: 220,
      }
    } else {
      return {
        left: 115,
      }
    }
  }
  private get style() {
    return {
      left: this.props.left,
      bottom: this.props.bottom
    }
  }

  private handleMouseOut(event: SyntheticEvent<Element, MouseEvent>) {
    // @ts-ignore
    if (!this.isParent(event.nativeEvent.relatedTarget, event.currentTarget)) {
      this.props.closePopup();
    }
  }

  private isParent(target: Element | Node, container: Element): boolean {
    if (!target) return false;
    const parent = target.parentNode;
    if (parent === container) {return true}
    else if (parent) {return this.isParent(parent, container)}
    else {return false}
  }


  public render() {
    return <div
      className="table-hover"
      style={this.style}
      data-table-hover
      onMouseOut={this.handleMouseOut.bind(this)}
    >
      <div
        data-table-hover-content
      >
        {this.props.children}
      </div>
      <div
        className="table-hover-triangle"
        style={this.triangleStyles}
        data-table-hover
      />
    </div>
  }
}

export default TableHover;