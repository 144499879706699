import "./NotFound.scss";

import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Title from "../../UI/components/title/Title";

class NotFound extends React.Component<RouteComponentProps> {
  public componentDidMount() {
    Page.setTitle("Ничего не найдено");
  }
  public render() {
    return (
      <div className="not-found">
        <Title>
          <p>404</p>
          <span>С</span>траница не найдена
        </Title>
      </div>
    );
  }
}

export default NotFound;
