import React, { SyntheticEvent } from "react";

import ExitImage from "../assets/exit-img.png";
import NavMenuLink from "../navMenuLink/NavMenuLink";
import { changeLoadedStatusAction } from "../../../store/actions/autocentersActions";
import { logout } from "../../../store/actions/session";
import store from "../../../store/store";

interface Props {
  isAuth: boolean;
}
interface State {}

class Footer extends React.Component<Props, State> {
  private handleClickOnLogout(event: SyntheticEvent) {
    event.preventDefault();
    store.dispatch(logout());
    store.dispatch(changeLoadedStatusAction(false));
  }

  public render() {
    return (
      <footer>
        {this.props.isAuth && (
          <NavMenuLink
            to={"/logout"}
            name="Выход"
            onClick={this.handleClickOnLogout.bind(this)}
            defaultImage={ExitImage}
            activeImage={ExitImage}
          />
        )}
      </footer>
    );
  }
}

export default Footer;
