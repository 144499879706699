import "./AutocenterRedact.scss";

import GroupsListContainer from "../../../containers/group/GroupsListContainer";
import React from "react";

interface Props {
  index: number;
  autocenter: App.Autocenter;
  onTryAddGroup: () => void;
  onStartMove: (index: number) => void;
  onMouseEnter: (index: number) => void;
  onEndMove: () => void;
  updateCurrentGroup: (group: App.Group) => void;
}

interface State {
  isDrag: boolean;
  isOpenBody: boolean;
}

class AutocenterRedact extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDrag: false,
      isOpenBody: false,
    };
  }

  private handleClickOnHeader() {
    this.setState({ isOpenBody: !this.state.isOpenBody });
  }

  private handleDragStart() {
    this.props.onStartMove(this.props.index);
  }
  private handleDragEnter() {
    this.props.onMouseEnter(this.props.index);
  }
  private handleDragEnd() {
    this.props.onEndMove();
  }

  public render() {
    return (
      <div
        className="autocenter-redact"
        draggable={true}
        onDragStart={this.handleDragStart.bind(this)}
        onDragEnter={this.handleDragEnter.bind(this)}
        onDragEnd={this.handleDragEnd.bind(this)}
      >
        <div
          className="autocenter-redact-header"
          onClick={this.handleClickOnHeader.bind(this)}
        >
          <div className="info">
            <div className="name">{this.props.autocenter.name}</div>
            <div className="address">{this.props.autocenter.address}</div>
          </div>
          <div className="open-close">
            {this.state.isOpenBody ? "Свернуть" : "Развернуть"}
          </div>
        </div>

        {this.state.isOpenBody && (
          <GroupsListContainer
            redact={true}
            autocenter={this.props.autocenter.id}
            groups={this.props.autocenter.groups}
          />
        )}
      </div>
    );
  }
}

export default AutocenterRedact;
