import React, { SyntheticEvent } from "react";

import AutocenterService from "../../services/AutocenterService";
import { AxiosError } from "axios";
import { NOT_FOUND } from "http-status-codes";
import Time from "../../UI/utils/Time";
import UserCard from "../../components/user/userCard/UserCard";
import UserFilters from "../../components/user/userFilters/UserFilters";
import UserService from "../../services/UserService";
import { changeUser } from "../../store/actions/session";
import { displayErrors } from "../../store/actions/error";
import store from "../../store/store";

interface Props {
  toHome: () => void;
}
interface State {
  keyword: string;
  tab: string;
  page: number;
  isEnd: boolean;
  loading: boolean;

  users: Array<App.UserInfo>;
  autocenters: Array<App.Autocenter>;
}

class UsersListContainer extends React.Component<Props, State> {
  private userService: UserService;
  private autocenterService: AutocenterService;
  private delayForSearch: number;
  constructor(props: Props) {
    super(props);

    this.userService = new UserService();
    this.autocenterService = new AutocenterService();
    this.delayForSearch = 0;
    this.state = {
      keyword: "",
      tab: "all",
      page: 0,
      isEnd: false,

      users: [],
      autocenters: [],

      loading: false,
    };
  }

  public componentDidMount() {
    this.search();
    this.loadAutocenters();
  }

  private loadAutocenters() {
    this.autocenterService
      .getList()
      .then((response) => {
        this.setState({
          autocenters: response.data.sort((a, b) =>
            a.weight > b.weight ? 1 : -1
          ),
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось загрузить автоцентры"],
            this.loadAutocenters.bind(this)
          )
        );
      });
  }

  private handleChangeFilters(tab: string, keyword: string) {
    this.setState({ keyword, tab });
    clearTimeout(this.delayForSearch);
    this.delayForSearch = window.setTimeout(
      () => this.search(0, tab, keyword),
      Time.second
    );
  }
  private search(page: number = 0, tab: string = "all", keyword: string = "") {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.userService
      .getList({
        page,
        type: tab,
        keyword,
      })
      .then((response) => {
        this.setState({
          users:
            page === 0 ? response.data : this.state.users.concat(response.data),
          page: page + 1,
          isEnd: response.data.length === 0,
        });
      })
      .catch((error: AxiosError<Array<string>>) => {
        if (error.response?.status === NOT_FOUND) {
        } else {
          store.dispatch(
            displayErrors(
              error.response?.data || [
                "Не удалось загрузить список пользователей",
              ]
            )
          );
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private handleScroll(event: SyntheticEvent<HTMLDivElement>) {
    const { bottom } = event.currentTarget.getBoundingClientRect();
    if (
      bottom - 100 < window.innerHeight &&
      !this.state.isEnd &&
      !this.state.loading
    ) {
      this.search(this.state.page, this.state.tab, this.state.keyword);
    }
  }

  private async updateUser(user: App.UserInfo) {
    if (this.state.loading) return;
    this.setState({ loading: true });

    this.userService
      .update({ role: user.role, autocenters: user.autocenters }, user.id)
      .then((response) => {
        this.setState({
          users: this.state.users.map((user) =>
            user.id === response.data.id ? response.data : user
          ),
        });
        const u = store.getState().session.user;
        if (user.login === u?.login && user.role !== u?.role) {
          store.dispatch(changeUser({ login: u.login, role: user.role }));
          this.props.toHome();
        }
      })
      .catch((error: AxiosError<Array<string>>) => {
        store.dispatch(
          displayErrors(
            error.response?.data || ["Не удалось редактировать пользователя"]
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  public render() {
    return (
      <>
        <UserFilters
          keyword={this.state.keyword}
          activeTab={this.state.tab}
          onChange={this.handleChangeFilters.bind(this)}
        />
        <div className="user-list" onWheel={this.handleScroll.bind(this)}>
          {this.state.users.map((user) => (
            <UserCard
              key={user.login}
              user={user}
              autocenters={this.state.autocenters}
              disabled={this.state.loading}
              update={this.updateUser.bind(this)}
            />
          ))}
        </div>
      </>
    );
  }
}

export default UsersListContainer;
