import React from "react";

class AudioCell extends React.Component<UI.Table.AudioCellProps> {
  public render() {
    return (
      <td onMouseOver={this.props.onMouseOver} className={this.props.className}>
        <audio src={this.props.src} controls />
      </td>
    );
  }
}

export default AudioCell;
