export default class PhoneValidator {
  static number = {
    minLength: 4,
    maxLength: 4,
    regExp: /^[0-9]{4}$/,
  };
  static description = {
    minLength: 0,
    maxLength: 256,
    regExp: /^[A-zА-я0-9_., @-]{0,256}$/,
  };
  static isCorrectNumber(number: string): boolean {
    return (
      number.length >= PhoneValidator.number.minLength &&
      number.length <= PhoneValidator.number.maxLength &&
      !!number.match(PhoneValidator.number.regExp)
    );
  }
  static isCorrectDescription(description: string): boolean {
    return (
      description.length >= PhoneValidator.description.minLength &&
      description.length <= PhoneValidator.description.maxLength &&
      !!description.match(PhoneValidator.description.regExp)
    );
  }
}
