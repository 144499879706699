import "./CallsFor_1c.scss";

import CallsPlotFor1C, { DataArray } from "../callsPlotFor_1c/CallsPlotFor_1c";

import CallsFor1cTable from "./callsFor_1cTable/CallsFor_1cTable";
import React from "react";
import Time from "../../../UI/utils/Time";

interface Props {
  error: string;
  plotData: {
    incoming: DataArray;
    outgoing: DataArray;
    sms: DataArray;
  };
  calls: Array<App.Call>;
  playAudio: (link?: string) => void;
  getWaves: (extensions: Array<number>, call: number) => void;
}
interface State {}

class CallsFor_1c extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="calls-for-1c">
        {!!this.props.error && (
          <div className="error">
            <div className="title">Возникла ошибка</div>
            <div className="message">{this.props.error}</div>
          </div>
        )}
        {!this.props.error && (
          <>
            <CallsPlotFor1C
              incoming={this.props.plotData.incoming}
              outgoing={this.props.plotData.outgoing}
              sms={[]}
            />
            {Array.from(
              this.props.calls.reduce(
                (g: Map<number, Array<App.Call>>, call) => {
                  const year = new Date(
                    call.start_timestamp * Time.second
                  ).getFullYear();
                  if (g.has(year)) {
                    g.get(year)?.push(call);
                  } else {
                    g.set(year, [call]);
                  }

                  return g;
                },
                new Map()
              )
            ).map((e) => (
              <div key={e[0]} className="calls-for-1c-by-year">
                <div className="year">{e[0]}</div>
                <CallsFor1cTable
                  calls={e[1]}
                  playAudio={this.props.playAudio}
                  getWaves={this.props.getWaves}
                />
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}
export default CallsFor_1c;
