import "./LdsSpinner.scss";

import React from "react";

class LDSSpinner extends React.Component {
  public render() {
    return (
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}

export default LDSSpinner;
