import "./CallsStatistic.scss";

import React from "react";

interface Props {
  borders: string;
  employers: string;
  clients: string;
}
interface State {}

class CallsStatistic extends React.Component<Props, State> {
  public render() {
    return (
      <div className="calls-statistic">
        <div className="state">Доступные данные: {this.props.borders}</div>
        <div className="border"></div>
        <div className="state">Сотрудников: {this.props.employers}</div>
        <div className="border"></div>
        <div className="state">Клиентов: {this.props.clients}</div>
      </div>
    );
  }
}

export default CallsStatistic;
