type ChangeUndistributedExtensionNumbersType = "CHANGE_UNDISTRIBUTED_EXTENSION_NUMBERS";

interface ChangeUndistributedExtensionNumbersAction {
  type: ChangeUndistributedExtensionNumbersType;
  extensionNumbers: Array<string> | null;
  timestamp: number | null;
}

export type Actions = ChangeUndistributedExtensionNumbersAction;
export const changeUndistributedExtensionNumbersType: ChangeUndistributedExtensionNumbersType = "CHANGE_UNDISTRIBUTED_EXTENSION_NUMBERS";

export function changeUndistributedExtensionNumbers(extensionNumbers: Array<string> | null, timestamp: number | null): ChangeUndistributedExtensionNumbersAction {
  return {
    type: changeUndistributedExtensionNumbersType,
    extensionNumbers,
    timestamp
  };
}