import React from "react";
import MissedRecordsTableContainer from "../../../../containers/records/missed/MissedRecordsTableContainer";
import './MissedRecordsTableHead.scss'

export default class MissedRecordsTableHead extends React.Component<any, any> {
  public render() {
    return (
      <thead className="missed-records-table-head">
      <tr>
        {MissedRecordsTableContainer.columns.map((e) => <th key={e.sortFlag}>{e.name}</th>)}
      </tr>
      </thead>
    );
  }
}