import "./Question.scss";

import Button from "../../button/Button";
import React from "react";

class Question extends React.Component<UI.Popups.Props.Question> {
  public render() {
    return (
      <div className="question shadow-back">
        <div className="form">
          <p>{this.props.question}</p>
          <div className="buttons">
            <Button onClick={this.props.onAgree}>Ок</Button>
            <Button onClick={this.props.onDisagree}>Отмена</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Question;
