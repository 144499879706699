import "./Message.scss";

import Button from "../../button/Button";
import React from "react";
import ShadowBack from "../shadowBack/ShadowBack";
import WarningIcon from "../../../assets/warning.png";

interface Props {
  title: string;
  text: string;
  icon: string;
  onClick: () => void;
}

class Message extends React.Component<Props> {
  static defaultProps = {
    title: "Внимание",
    onClick() {},
    icon: WarningIcon,
  };

  public render() {
    return (
      <ShadowBack onClick={this.props.onClick}>
        <div className="window-message">
          <div className="message-title">
            <div>
              <img src={this.props.icon} alt="!" />
            </div>
            <div>{this.props.title}</div>
          </div>
          <div className="message-text">{this.props.text}</div>
          {this.props.children}
          <div className="message-close">
            <Button onClick={this.props.onClick}>ЗАКРЫТЬ</Button>
          </div>
        </div>
      </ShadowBack>
    );
  }
}

export default Message;
