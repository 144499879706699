import React from "react";
import CallService from "../../services/CallService";
import {AxiosError} from "axios";
import store from "../../store/store";
import {displayErrors} from "../../store/actions/error";
import UndistributedExtensionNumberList from "../../components/undistributedExtensionNumberList/UndistributedExtensionNumberList";
import {
  changeUndistributedExtensionNumbers,
} from "../../store/actions/undistributedExtensionNumbers";
import Time from "../../UI/utils/Time";

interface Props {}

interface State {}

export default class UndistributedExtensionNumberContainer extends React.Component<Props, State> {
  private callService: CallService;
  constructor(props: Props) {
    super(props);
    this.callService = new CallService();
  }

  private loadList() {
    this.callService.getUndistributedExtensionNumbers().then((response) => {
      store.dispatch(
        changeUndistributedExtensionNumbers(response.data, +new Date())
      );
    }).catch((error: AxiosError<Array<string>>) => {
      store.dispatch(
        displayErrors(
          error.response?.data || ["Не удалось загрузить список нераспределенных по автоцентрам номеров"]
        )
      );
    })
  }

  render() {
    let extensionNumbers = store.getState().undistributedExtensionNumbers.extensionNumbers;
    let timestamp = store.getState().undistributedExtensionNumbers.timestamp;
    return <>
      <div className="update-undistributed-extension-numbers">
        <span>
          {
            extensionNumbers?.length && timestamp
            ? `Обновлено ${Time.beautifyDateTime(new Date(timestamp))}`
            : 'Обновите данные'
          }
        </span>
        <button onClick={this.loadList.bind(this)}>Обновить данные</button>
      </div>
      <UndistributedExtensionNumberList />
    </>;
  }
}