import "./TriangleMark.scss";

import React from "react";

interface Props {
  isUp?: boolean;
}
interface State {}

class TriangleMark extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <svg
        className="triangle-mark"
        data-up={!!this.props.isUp}
        version="1.1"
        viewBox="0 0 50 50"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="m0,0 h50 l-25,45 z" />
      </svg>
    );
  }
}
export default TriangleMark;
