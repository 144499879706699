interface ChangeLoadedStatusAction {
  status: boolean;
  type: "ChangeLoadedStatusAction";
}

interface ToggleAutocentersLinksAction {
  type: "ToggleAutocentersLinksAction";
}

export type Actions = ChangeLoadedStatusAction | ToggleAutocentersLinksAction;

export function changeLoadedStatusAction(
  status: boolean
): ChangeLoadedStatusAction {
  return {
    status,
    type: "ChangeLoadedStatusAction",
  };
}

export function toggleAutocentersLinksAction(): ToggleAutocentersLinksAction {
  return {
    type: "ToggleAutocentersLinksAction",
  };
}
