import "./SmsGroupsFilters.scss";

import LabelText from "../../../custom/text/LabelText";
import LabelWithButton from "../../../custom/labelWithButton/LabelWithButton";
import React from "react";
import SmsTabsForView from "../../tabsForView/SmsTabsForView";

interface Props {
  keyword: string;
  loading: boolean;
  onChangeKeyword: (keyword: string) => void;
  open: () => void;
}
interface State {
  keyword: string;
}

class SmsGroupsFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      keyword: props.keyword,
    };
  }

  private handleChangeKeyword(keyword: string) {
    this.setState({ keyword });
  }
  private handleClickOnSearch() {
    this.props.onChangeKeyword(this.state.keyword);
  }

  public render() {
    return (
      <>
        <div className="sms-groups-filters">
          <div className="filters">
            <LabelWithButton
              buttonText="Найти"
              className={
                this.state.keyword !== this.props.keyword
                  ? "not-active"
                  : undefined
              }
              disabled={
                this.props.loading || this.state.keyword === this.props.keyword
              }
              onClick={this.handleClickOnSearch.bind(this)}
            >
              <LabelText
                label="Поиск"
                value={this.state.keyword}
                onChange={this.handleChangeKeyword.bind(this)}
              />
            </LabelWithButton>
            <button onClick={this.props.open}>Добавить группу</button>
          </div>
          <SmsTabsForView />
        </div>
      </>
    );
  }
}
export default SmsGroupsFilters;
