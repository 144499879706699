import { Actions } from "../actions/httpActions";

export interface HttpStore {
  numberActiveRequest: number;
}
export const defaultHttpStore: HttpStore = { numberActiveRequest: 0 };
export function http(store: HttpStore, action: Actions): HttpStore {
  switch (action.type) {
    case "OpenRequestAction": {
      store.numberActiveRequest += 1;
      break;
    }
    case "CloseRequestAction": {
      store.numberActiveRequest -= 1;
      break;
    }
    default:
      break;
  }

  return store;
}
