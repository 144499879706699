import "./SmsSendingCalls.scss";

import React from "react";

interface Props {
  totalSms: number;
  totalCalls: number;
}
interface State {}

class SmsSendingCalls extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="sms-sending-calls">
        <div className="number">
          <span className="calls">{this.props.totalCalls}</span>
          <span className="delimeter">/</span>
          <span className="sms">{this.props.totalSms}</span>
        </div>
        <div className="percent">
          {this.props.totalSms ? (
            <>
              <span>
                {((this.props.totalCalls / this.props.totalSms) * 100).toFixed(
                  2
                )}
              </span>
              <span>%</span>
            </>
          ) : (
            <span>Нет звонков</span>
          )}
        </div>
      </div>
    );
  }
}
export default SmsSendingCalls;
