import "./Button.scss";

import React, { RefObject, SyntheticEvent } from "react";

import Checkmark from "../../assets/icons/checkmark/Checkmark";

interface Props {
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
  onDoubleClick?: (event: SyntheticEvent) => void;
  onMouseDown?: (event: SyntheticEvent) => void;
  onMouseUp?: (event: SyntheticEvent) => void;
  onMouseOver?: (event: SyntheticEvent) => void;
  onMouseOut?: (event: SyntheticEvent) => void;
  "data-icon"?: string;
  "data-indicate"?: string;
  "data-active"?: boolean;
  "data-saled"?: boolean;
  disabled?: boolean;
}
interface State {
  button: RefObject<HTMLButtonElement>;
}

class Button extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      button: React.createRef(),
    };
  }

  private get icon() {
    switch (this.props["data-icon"]) {
      case "checkmark":
        return <Checkmark />;
      default:
        return <></>;
    }
  }

  private handleClick(event: SyntheticEvent) {
    if (this.props.onClick) {
      this.props.onClick(event);
    } else if (
      event.target === event.currentTarget &&
      this.state.button.current
    ) {
      this.state.button.current.click();
    }
  }

  public render() {
    return (
      <div
        {...this.props}
        onClick={this.handleClick.bind(this)}
        className={"button " + (this.props.className || "")}
      >
        <button disabled={this.props.disabled} ref={this.state.button}>
          {this.icon}
          {this.props.children}
        </button>
      </div>
    );
  }
}

export default Button;
