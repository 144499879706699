import "./PhoneForm.scss";

import React, { SyntheticEvent } from "react";

import LabelText from "../../custom/text/LabelText";
import PhoneValidator from "../../../utils/phoneValidator/PhoneValidator";
import ShadowBack from "../../../UI/components/popups/shadowBack/ShadowBack";
import { prevent } from "../../../UI/utils/EventMethods";

interface Props {
  phone: App.Phone | null;
  submit: (phone: { number: string; description: string }) => void;
  close: () => void;
}
interface State {
  number: string;
  description: string;
}

class PhoneForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      number: "",
      description: "",
    };
  }

  public componentDidMount() {
    if (this.props.phone) {
      this.setState({
        number: this.props.phone.number,
        description: this.props.phone.description,
      });
    }
  }

  private handleChangeNumber(number: string) {
    const res = number.match(/[0-9]*/);
    if (res && res[0] === number) {
      this.setState({ number });
    }
  }
  private handleChangeDescription(description: string) {
    this.setState({ description });
  }
  private handleSubmit(event: SyntheticEvent) {
    prevent(event);
    if (!PhoneValidator.isCorrectNumber(this.state.number)) {
      return;
    }
    this.props.submit(this.state);
  }
  private close(event: SyntheticEvent) {
    prevent(event);
    this.props.close();
  }

  public render() {
    return (
      <ShadowBack onClick={this.props.close}>
        <form onSubmit={this.handleSubmit.bind(this)} className="phone-form">
          <h1>Добавить телефон</h1>
          <LabelText
            label="Номер телефона"
            value={this.state.number}
            onChange={this.handleChangeNumber.bind(this)}
            minLength={PhoneValidator.number.minLength}
            maxLength={PhoneValidator.number.maxLength}
            correct={PhoneValidator.isCorrectNumber(this.state.number)}
            required
          />
          <LabelText
            label="Описание"
            area
            value={this.state.description}
            onChange={this.handleChangeDescription.bind(this)}
            minLength={PhoneValidator.description.minLength}
            maxLength={PhoneValidator.description.maxLength}
          />
          <div className="buttons">
            <button
              disabled={!PhoneValidator.isCorrectNumber(this.state.number)}
            >
              Сохранить
            </button>
            <button onClick={this.close.bind(this)}>Отмена</button>
          </div>
        </form>
      </ShadowBack>
    );
  }
}

export default PhoneForm;
