import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";
import { serialize } from "object-to-formdata";

export interface FaqData {
  question: string;
  answer: string;
  image?: File | null;
}

class FaqService {
  public async getList(): Promise<App.ServiceResponse<Array<App.Faq>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/faqs", {
      headers: { Authorization },
    }).then((response: AxiosResponse<Array<App.Faq>>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
  public async create(faqData: FaqData): Promise<App.ServiceResponse<App.Faq>> {
    const Authorization = await JWT.getAuth();
    return Axios.post("/faq", serialize(faqData), {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Faq>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
  public async update(
    id: number,
    faqData: FaqData
  ): Promise<App.ServiceResponse<App.Faq>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      `/faq/${id}`,
      serialize(Object.assign(faqData, { _method: "PUT" })),
      {
        headers: { Authorization },
      }
    ).then((response: AxiosResponse<App.Faq>) => {
      return {
        status: true,
        data: response.data,
      };
    });
  }
  public async delete(id: number): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/faq/${id}`, {
      headers: { Authorization },
    }).then(() => {
      return {
        status: true,
        data: null,
      };
    });
  }
}

export default FaqService;
