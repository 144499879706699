import React from "react";

// import { Link } from "react-router-dom";

export default class Tab extends React.Component<UI.Tabs.Props.Tab> {
  static defaultProps = {
    onClick: () => {},
  };
  private handleClick() {
    this.props.onClick(this.props.tab.id);
  }

  public render() {
    return (
      <>
        {!!this.props.tab.link ? (
          // <Link
          //   to={this.props.tab.link || ""}
          //   className="tab"
          //   data-selected={this.props.selected}
          //   onClick={this.handleClick.bind(this)}
          // >
          //   {this.props.tab.name}
          // </Link>
          <div
            // to={this.props.tab.link || ""}
            className="tab"
            data-active={this.props.selected}
            onClick={this.handleClick.bind(this)}
          >
            {this.props.tab.name}
          </div>
        ) : (
          <div
            className="tab"
            data-active={this.props.selected}
            onClick={this.handleClick.bind(this)}
          >
            {this.props.tab.name}
          </div>
        )}
      </>
    );
  }
}
