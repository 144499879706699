import React, { SyntheticEvent } from "react";

interface Props {
  files: Array<App.IFile>;
  remove: (id: number) => void;
}

class ListDisplay extends React.Component<Props> {
  private getHandleClick(id: number) {
    return (event: SyntheticEvent) => {
      event.stopPropagation();
      this.props.remove(id);
    };
  }
  public render() {
    return (
      <ul>
        {this.props.files.map(file => (
          <li key={file.id}>
            <span>{file.name}</span>
            <button onClick={this.getHandleClick(file.id)}>Удалить</button>
          </li>
        ))}
      </ul>
    );
  }
}

export default ListDisplay;
