import "./GroupForm.scss";

import React, { SyntheticEvent } from "react";

import Button from "../../../UI/components/button/Button";
import GroupValidator from "../../../utils/groupValidator/GroupValidator";
import LabelText from "../../custom/text/LabelText";
import ShadowBack from "../../../UI/components/popups/shadowBack/ShadowBack";
import { prevent } from "../../../UI/utils/EventMethods";

interface Props {
  group: App.Group | null;
  submit: (group: App.GroupData) => void;
  close: () => void;
}
interface State {
  name: string;
  code: string;
  color: string;
}

class GroupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      code: "",
      color: "#ffcc00",
    };
  }

  public componentDidMount() {
    if (this.props.group) {
      this.setState({
        name: this.props.group.name,
        code: this.props.group.code.toString(),
        color: this.props.group.color,
      });
    }
  }
  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangedCode(code: string) {
    this.setState({ code });
  }
  private handleChangedColor(color: string) {
    this.setState({ color });
  }
  private handleSubmit(event: SyntheticEvent) {
    prevent(event);
    if (
      !GroupValidator.isCorrectName(this.state.name) ||
      !GroupValidator.isCorrectCode(this.state.code)
    )
      return;
    this.props.submit(this.state);
  }
  private close(event: SyntheticEvent) {
    prevent(event);
    this.props.close();
  }

  public render() {
    return (
      <ShadowBack onClick={this.props.close}>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          className="group-form form"
        >
          <h1>Добавить группу</h1>
          <LabelText
            label="Название"
            value={this.state.name}
            onChange={this.handleChangeName.bind(this)}
            minLength={GroupValidator.aName.minLength}
            maxLength={GroupValidator.aName.maxLength}
            correct={GroupValidator.isCorrectName(this.state.name)}
            required
          />
          <LabelText
            label="Код группы"
            value={this.state.code}
            onChange={this.handleChangedCode.bind(this)}
            minLength={GroupValidator.code.minLength}
            maxLength={GroupValidator.code.maxLength}
            correct={GroupValidator.isCorrectCode(this.state.code)}
            required
          />
          {/* <LabelColor
            label="Цвет"
            value={this.state.color}
            onChange={this.handleChangedColor.bind(this)}
          /> */}

          <div className="buttons">
            <Button
              disabled={
                !GroupValidator.isCorrectName(this.state.name) ||
                !GroupValidator.isCorrectCode(this.state.code)
              }
            >
              Сохранить
            </Button>
            <Button onClick={this.close.bind(this)}>Отмена</Button>
          </div>
        </form>
      </ShadowBack>
    );
  }
}

export default GroupForm;
