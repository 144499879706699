export const homePath: string = "/";
export const authPath: string = "/auth";
export const autocentersPath: string = "/autocenters";
export const autocenterPath: string = "/autocenter/:autocenter";
export const groupPath: string = "/autocenter/:autocenter/:group";
export const operatorPath: string = "/autocenter/:autocenter/:group/:operator";
export const smsesPath: string = "/sms";
export const smsPath: string = "/sms/:id";
export const smsGroupsPath: string = "/sms-groups";
export const trunksPath: string = "/trunks";
export const searchPath: string = "/search";
export const usersPath: string = "/users";
export const smsGroupPath: string = "/sms-group/:id";
export const loadPath: string = "/load/:type";
export const faqPath: string = "/faq";
export const callsFor1C: string = "/client-calls";
export const notFoundPath: string = "/not-found";
export const undistributedExtensionNumber: string = "/undistributed-extension-number";
export const missedRecords: string = "/missed-records";
