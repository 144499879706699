interface Result {
  data: string;
  size: number;
  lastModified: number;
}

interface Success extends Result {
  status: true;
}
interface Failed extends Result {
  status: false;
}

class FileParser {
  static read(file: File, format?: string) {
    const fr = new FileReader();
    return new Promise((resolve, reject) => {
      if (format === "dataUrl") {
        fr.readAsDataURL(file);
      } else {
        fr.readAsText(file);
      }
      fr.onload = () => {
        if (fr.result) {
          resolve();
        } else {
          reject();
        }
      };
    })
      .then(
        (): Success => ({
          status: true,
          // @ts-ignore
          data: fr.result,
          size: file.size,
          lastModified: file.lastModified
        })
      )
      .catch(
        (): Failed => ({
          status: false,
          data: "Не удалось прочитать файл",
          size: file.size,
          lastModified: file.lastModified
        })
      );
  }
}

export default FileParser;
