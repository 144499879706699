import "./NavMenuLink.scss";

import React, { SyntheticEvent } from "react";

import { NavLink } from "react-router-dom";

interface Props {
  name: string;
  to: string;
  defaultImage: string;
  activeImage: string;
  onClick?: (event: SyntheticEvent) => void;
  className?: string;
}
interface State {}

class NavMenuLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <NavLink
        to={this.props.to}
        className={
          this.props.className
            ? `nav-menu-link ${this.props.className}`
            : "nav-menu-link"
        }
        onClick={this.props.onClick}
      >
        <img src={this.props.defaultImage} alt="" />
        <img src={this.props.activeImage} alt="" />
        <div className="link-name">{this.props.name}</div>
      </NavLink>
    );
  }
}
export default NavMenuLink;
