import React from "react";

import Checkmark from "../../../assets/icons/checkmark/Checkmark";
import UncorrectIcon from "../../../assets/uncorrect.png";

class LabelName extends React.Component<UI.Label.LabelName> {
  private get mark() {
    if (this.props["data-cash"]) {
      return (
        <div className="mark cash">
          <Checkmark fill={"white"} stroke={"white"} />
        </div>
      );
    } else if (this.props["data-correct"]) {
      return (
        <div className="mark correct">
          <Checkmark fill={"white"} stroke={"white"} />
        </div>
      );
    } else if (this.props["data-uncorrect"]) {
      return (
        <div className="mark uncorrect">
          <img src={UncorrectIcon} alt="" />
        </div>
      );
    } else {
      return <></>;
    }
  }
  public render() {
    return (
      <>
        {this.props.label && (
          <div className="label-name">
            {this.mark}
            {this.props.label}
          </div>
        )}
      </>
    );
  }
}
export default LabelName;
