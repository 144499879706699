import { ErrorActions, displayErrorsType } from "../actions/error";

export interface ErrorStore {
  id: number;
  errors: Array<string>;
  callback?: () => void;
}

export const defaultErrorStore: ErrorStore = {
  id: 0,
  errors: [],
};

export default function (
  state: App.Store.Errors,
  action: ErrorActions
): App.Store.Errors {
  switch (action.type) {
    case displayErrorsType:
      state.id = action.id;
      state.errors = action.errors;
      state.callback = action.callback;
      break;
    default:
      break;
  }
  return state;
}
