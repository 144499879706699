import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import React, { RefObject } from "react";

import CallService from "../../services/CallService";
import Time from "../../UI/utils/Time";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface PlotDataItem {
  x: Date | number;
  y: number;
  name?: string;
}
interface Props {
  autocenter?: number;
  group?: number;
  operator?: number;
  type: number;
  from: Date;
  to: Date;
  changeLoading: (loading: boolean) => void;
}
interface State {
  chartTag: RefObject<HTMLDivElement>;
  series: Array<{
    name: string;
    index: number;
  }>;
  chart?: am4charts.XYChart;
}

class CallsPlotGraphContainer extends React.Component<Props, State> {
  private callService: CallService;
  private delayForUpdate: number;
  constructor(props: Props) {
    super(props);
    this.callService = new CallService();
    this.delayForUpdate = 0;
    this.state = {
      chartTag: React.createRef(),
      series: [],
    };
    // am4core.useTheme(am4themes_animated)
    am4core.options.queue = true;
    am4core.unuseAllThemes();
  }

  static colors = [
    new am4core.Color({ r: 110, g: 118, b: 255 }),
    new am4core.Color({ r: 10, g: 214, b: 104 }),
    new am4core.Color({ r: 255, g: 213, b: 129 }),
    new am4core.Color({ r: 255, g: 161, b: 163 }),
  ];

  public componentDidMount() {
    this.delayForUpdate = window.setTimeout(
      this.loadInfo.bind(this),
      Time.second
    );
  }
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.from !== prevProps.from ||
      this.props.to !== prevProps.to ||
      this.props.autocenter !== prevProps.autocenter ||
      this.props.group !== prevProps.group ||
      this.props.operator !== prevProps.operator ||
      this.props.type !== prevProps.type
    ) {
      clearTimeout(this.delayForUpdate);
      this.delayForUpdate = window.setTimeout(
        this.loadInfo.bind(this),
        Time.second
      );
    }
  }
  public componentWillUnmount() {
    this.state.chart?.dispose();
  }

  private loadInfo() {
    // return;
    switch (this.props.type) {
      case 1: {
        this.loadByMonths();
        return;
      }
      case 2: {
        this.loadByWeek();
        return;
      }
      case 3: {
        this.loadByDay();
        return;
      }
      case 4: {
        this.loadByHour();
        return;
      }
      default: {
        return;
      }
    }
  }
  private createParams(
    type?: "incoming" | "lost" | "not_answered" | "outgoing" | "sms_sendings"
  ) {
    return {
      autocenter: this.props.autocenter,
      group: this.props.group,
      operator: this.props.operator,
      from: +this.props.from / Time.second,
      to: +this.props.to / Time.second,
      type,
    };
  }

  private async updateData(
    load: (params: any) => Promise<any>,
    handleResponse: (data: any) => Array<PlotDataItem>,
    isLoadSms: boolean = true
  ) {
    let chart = this.createChart(isLoadSms);
    this.props.changeLoading(true);

    const loadQueues: Array<Array<"incoming" | "not_answered" | "outgoing" | "lost">> = [
      ["incoming", "outgoing", "not_answered", "lost"],
    ];

    for (let i = 0; i < loadQueues.length; i ++) {
      await Promise.all(loadQueues[i].map((type) => {
        return load(this.createParams(type));
      })).then((responses) => {
        responses.forEach((response, index) => {
          let type = loadQueues[i][index];
          let modifiedData = handleResponse.call(this, response.status ? response.data[type] || [] : []);
          if (chart && !chart.isDisposed())
            this.addBullet(chart, type, modifiedData, isLoadSms);
        })
      });
    }

    if (isLoadSms) {
      load(this.createParams("sms_sendings")).then((response) => {
        let data = this.parseSms(response.data.sms_sendings);
        data.forEach((s) => {
          if (chart && !chart.isDisposed()) {
            const name = s.name || "";
            const smsSeries = this.createCallsSeries(chart, [s], name);
            smsSeries.hiddenInLegend = true;
            smsSeries.strokeWidth = 0;
            this.createBullet(smsSeries);
          }
        })
        this.props.changeLoading(false);
      })
    } else
      this.props.changeLoading(false);
  }

  // private async updateData(
  //   load: (params: any) => Promise<any>,
  //   handleResponse: (data: any) => Array<PlotDataItem>,
  //   isLoadSms: boolean = true
  // ) {
  //   this.createChart(isLoadSms);
  //   this.props.changeLoading(true);
  //
  //   const result = await Promise.all([
  //     load(this.createParams("incoming")),
  //     load(this.createParams("outgoing")),
  //     load(this.createParams("not_answered")),
  //     load(this.createParams("lost")),
  //     isLoadSms ? load(this.createParams("sms_sendings")) : undefined,
  //   ]).then(this.processResponses.bind(this));
  //
  //   this.createPlot(
  //     handleResponse.call(this, result[0]),
  //     handleResponse.call(this, result[1]),
  //     handleResponse.call(this, result[2]),
  //     handleResponse.call(this, result[3]),
  //     isLoadSms ? this.parseSms(result[4]) : undefined
  //   );
  //
  //   this.props.changeLoading(false);
  // }

  private loadByMonths() {
    this.updateData(
      this.callService.getStaticByMonth,
      this.handleResponseByMonth
    );
  }
  private loadByWeek() {
    this.updateData(
      this.callService.getStaticByWeek,
      this.handleResponseByWeek
    );
  }
  private async loadByDay() {
    this.updateData(this.callService.getStaticByDay, this.handleResponseByDay);
  }
  private async loadByHour() {
    this.updateData(
      this.callService.getStaticByHour,
      this.handleResponseByHour,
      false
    );
  }

  // private processResponses(responses: [any, any, any, any, any | void]) {
  //   return responses.map((response, index) => {
  //     if (response?.status) {
  //       switch (index) {
  //         case 0:
  //           return response.data.incoming;
  //         case 1:
  //           return response.data.outgoing;
  //         case 2:
  //           return response.data.not_answered;
  //         case 3:
  //           return response.data.lost;
  //         case 4:
  //           return response.data.sms_sendings;
  //         default:
  //           break;
  //       }
  //     }
  //     return [];
  //   });
  // }

  private handleResponseByMonth(data: any) {
    const result: Array<PlotDataItem> = [];
    for (let id in data) {
      let date = new Date(id);
      result.push({
        x: date,
        y: data[id],
      });
    }
    return result;
  }
  private handleResponseByWeek(data: any) {
    const result: Array<PlotDataItem> = [];
    let i = 0;
    for (let id in data) {
      result.push({
        x: new Date(+this.props.from + Time.week * i++),
        y: data[id],
      });
    }
    return result;
  }
  private handleResponseByDay(data: any) {
    const result: Array<PlotDataItem> = [];
    for (let id in data) {
      result.push({
        x: new Date(+id * Time.second),
        y: data[id],
      });
    }
    return result;
  }
  private handleResponseByHour(data: any) {
    const result: Array<PlotDataItem> = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    for (let id in data) {
      result.push({
        x: today.getHours(),
        y: data[id],
      });
      today.setHours(today.getHours() + 1);
    }
    return result;
  }
  private parseSms(
    list: Array<{ name: string; totalSms: number; datetime: number }>
  ): Array<PlotDataItem> {
    return list.map((e, i) => ({
      name: e.name || `sms-${i}`,
      x: new Date(e.datetime * Time.second),
      y: e.totalSms,
    }));
  }

  // private createPlot(
  //   incoming: Array<PlotDataItem>,
  //   outgoing: Array<PlotDataItem>,
  //   missing: Array<PlotDataItem>,
  //   lost: Array<PlotDataItem>,
  //   sms?: Array<PlotDataItem>
  // ) {
  //   const tag = this.state.chartTag.current;
  //   if (!tag) return;
  //   let chart = am4core.create(tag, am4charts.XYChart);
  //   chart.language.locale = am4lang_ru_RU;
  //   let xAxis = chart.xAxes.push(
  //     !!sms ? new am4charts.DateAxis() : new am4charts.ValueAxis()
  //   );
  //   xAxis.renderer.labels.template.fill = am4core.color("#666666");
  //   let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //   yAxis.min = 100;
  //   yAxis.renderer.labels.template.fill = am4core.color("#666666");
  //
  //   chart.legend = new am4charts.Legend();
  //   chart.legend.position = "right";
  //   chart.legend.scrollable = true;
  //   chart.legend.useDefaultMarker = true;
  //
  //   let markerTemplate = chart.legend.markers.template;
  //   markerTemplate.width = 20;
  //   markerTemplate.height = 20;
  //
  //   chart.colors.list = CallsPlotGraphContainer.colors;
  //
  //   chart.cursor = new am4charts.XYCursor();
  //   chart.cursor.behavior = "panXY";
  //   chart.cursor.xAxis = xAxis;
  //   chart.cursor.lineX.disabled = true;
  //   chart.cursor.lineY.disabled = true;
  //
  //   chart.fontSize = 12;
  //   chart.fontFamily = "Roboto";
  //
  //   this.createBullet(
  //     this.createCallsSeries(chart, incoming, "Входящие", !!sms)
  //   );
  //   this.createBullet(
  //     this.createCallsSeries(chart, outgoing, "Исходящие", !!sms)
  //   );
  //   this.createBullet(
  //     this.createCallsSeries(chart, missing, "Пропущеные", !!sms)
  //   );
  //   this.createBullet(this.createCallsSeries(chart, lost, "Потерянные", !!sms));
  //   if (sms) {
  //     sms.forEach((s) => {
  //       const name = s.name || "";
  //       const smsSeries = this.createCallsSeries(chart, [s], name);
  //       smsSeries.hiddenInLegend = true;
  //       smsSeries.strokeWidth = 0;
  //       this.createBullet(smsSeries);
  //     });
  //   }
  // }

  private createChart(isLoadSms: boolean) {
    this.state.chart?.dispose();

    const tag = this.state.chartTag.current;
    if (!tag) return;
    let chart = am4core.create(tag, am4charts.XYChart);
    chart.language.locale = am4lang_ru_RU;
    let xAxis = chart.xAxes.push(
      isLoadSms ? new am4charts.DateAxis() : new am4charts.ValueAxis()
    );
    xAxis.renderer.labels.template.fill = am4core.color("#666666");
    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 1;
    yAxis.renderer.labels.template.fill = am4core.color("#666666");
    yAxis.cursorTooltipEnabled = false;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";
    chart.legend.scrollable = true;
    chart.legend.useDefaultMarker = true;

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 20;

    chart.colors.list = CallsPlotGraphContainer.colors;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";
    chart.cursor.xAxis = xAxis;
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;

    chart.fontSize = 12;
    chart.fontFamily = "Roboto";

    this.setState({
      chart
    })
    return chart;
  }

  private addBullet(chart: am4charts.XYChart, name: string, data: Array<PlotDataItem>, isLoadSms: boolean) {
    const seriesNameMap: {
      [key: string]: string
    } = {
      incoming: "Входящие",
      outgoing: "Исходящие",
      not_answered: "Пропущенные",
      lost: "Потерянные"
    };

    if (this.state.chart)
      this.createBullet(
        this.createCallsSeries(chart, data, seriesNameMap[name] || "", isLoadSms)
      );
  }

  private createCallsSeries(
    chart: am4charts.XYChart,
    values: Array<PlotDataItem>,
    name: string,
    isDate: boolean = true,
  ) {
    let series = chart.series.push(new am4charts.LineSeries());
    series.name = name;
    series.data = values;
    series.strokeWidth = 1;
    if (isDate) {
      series.dataFields.dateX = "x";
    } else {
      series.dataFields.valueX = "x";
    }
    series.dataFields.valueY = "y";

    let segment = series.segments.template;
    segment.interactionsEnabled = true;

    let hoverState = segment.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    let dimmed = segment.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");

    segment.events.on("over", (event: any) => {
      this.processOver(chart, event.target.parent.parent.parent);
    });

    segment.events.on("out", (event: any) => {
      this.processOut(chart);
    });

    return series;
  }
  private processOver(chart: am4charts.XYChart, hoveredSeries: any) {
    hoveredSeries.toFront();

    hoveredSeries.segments.each((segment: any) => {
      segment.setState("hover");
    });

    chart.series.each((series: any) => {
      if (series !== hoveredSeries) {
        series.segments.each((segment: any) => {
          segment.setState("dimmed");
        });
        series.bulletsContainer.setState("dimmed");
      }
    });
  }
  private processOut(chart: am4charts.XYChart) {
    chart.series.each((series: any) => {
      series.segments.each((segment: any) => {
        segment.setState("default");
      });
      series.bulletsContainer.setState("default");
    });
  }
  private createBullet(series: am4charts.LineSeries) {
    let bullet = series.bullets.push(new am4core.Circle());
    bullet.strokeWidth = 2;
    bullet.radius = 4;
    // let bullet = series.bullets.push(new am4charts.CircleBullet());
    // bullet.circle.strokeWidth = 2;
    // bullet.circle.radius = 4;
    series.tooltipText = "{name} {y}";

    let bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;
  }

  public render() {
    return <div className="chart-container" ref={this.state.chartTag} />;
  }
}
export default CallsPlotGraphContainer;
