export default class AutocenterValidator {
  static aName = {
    minLength: 3,
    maxLength: 64,
    regExp: /^[A-zА-я0-9_. @-]{3,36}$/,
  };
  static address = {
    minLength: 3,
    maxLength: 64,
    regExp: /^[A-zА-я0-9_., @-]{3,36}$/,
  };
  static isCorrectName(name: string): boolean {
    return (
      name.length >= AutocenterValidator.aName.minLength &&
      name.length <= AutocenterValidator.aName.maxLength &&
      !!name.match(AutocenterValidator.aName.regExp)
    );
  }
  static isCorrectAddress(name: string): boolean {
    return (
      name.length >= AutocenterValidator.address.minLength &&
      name.length <= AutocenterValidator.address.maxLength &&
      !!name.match(AutocenterValidator.address.regExp)
    );
  }
}
