import "./AutocentersLinks.scss";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  autocenters: Array<App.Autocenter>;
  isOpen: boolean;
}
interface State {}

class AutocentersLinks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="autocenters-links" data-open={this.props.isOpen}>
        {this.props.autocenters.map((autocenter) => (
          <NavLink key={autocenter.id} to={`/autocenter/${autocenter.id}`}>
            {autocenter.name}
          </NavLink>
        ))}
      </div>
    );
  }
}
export default AutocentersLinks;
