import "./CallsPlotTabs.scss";

import React from "react";

interface Props {
  tabs: Array<{ id: number; name: string }>;
  tab: number;
  disabled: boolean;
  onChange: (tab: number) => void;
}
interface State {}

class CallsPlotTabs extends React.Component<Props, State> {
  public render() {
    return (
      <div className="calls-plot-tabs" data-disabled={this.props.disabled}>
        {this.props.tabs.map((tab) => (
          <div
            key={tab.id}
            className="calls-plot-tab"
            data-active={this.props.tab === tab.id}
            onClick={() => this.props.onChange(tab.id)}
          >
            {tab.name}
          </div>
        ))}
      </div>
    );
  }
}

export default CallsPlotTabs;
