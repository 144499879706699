import AbonentService from "../../services/AbonentService";
import { AxiosError } from "axios";
import CallService from "../../services/CallService";
import CallsStatistic from "../../components/calls/callStatistic/CallsStatistic";
import React from "react";
import Time from "../../UI/utils/Time";

interface Props {
  autocenter?: number;
  group?: number;
  operator?: number;
}
interface State {
  borders: string;
  employers: string;
  clients: string;
}

class CallsStatisticContainer extends React.Component<Props, State> {
  private callService: CallService;
  private abonentService: AbonentService;
  constructor(props: Props) {
    super(props);
    this.callService = new CallService();
    this.abonentService = new AbonentService();
    this.state = {
      borders: "Загрузка",
      employers: "Загрузка",
      clients: "Загрузка",
    };
  }

  public componentDidMount() {
    this.loadBorders();
    this.getInfo();
  }

  public componentDidUpdate(props: Props) {
    if (
      this.props.autocenter !== props.autocenter ||
      this.props.group !== props.group ||
      this.props.operator !== props.operator
    ) {
      this.loadBorders();
      this.getInfo();
    }
  }

  private get params() {
    if (this.props.operator) {
      return { operator: this.props.operator };
    } else if (this.props.group) {
      return { group: this.props.group };
    } else if (this.props.autocenter) {
      return { autocenter: this.props.autocenter };
    }
    return {};
  }

  private loadBorders() {
    const params = this.params;
    this.setState({
      borders: "Загрузка",
    });
    this.callService
      .getCallsBorders(params)
      .then((response) => {
        this.setState({
          borders: `${Time.beautifyDate(
            response.data.minTimestamp
          )} - ${Time.beautifyDate(response.data.maxTimestamp)}`,
        });
      })
      .catch((error: AxiosError) => {
        this.setState({
          borders: "Ошибка",
        });
      });
  }

  private getInfo() {
    const params = this.params;
    this.setState({
      employers: "Загрузка",
      clients: "Загрузка",
    });

    this.abonentService
      .getTotalNumberAbonents(params)
      .then((response) => {
        this.setState({
          employers: response.data.employees.toString(),
          clients: response.data.clients.toString(),
        });
      })
      .catch(() => {
        this.setState({
          employers: "Ошибка",
          clients: "Ошибка",
        });
      });
  }

  public render() {
    return <CallsStatistic {...this.state} />;
  }
}

export default CallsStatisticContainer;
