import "./Logo.scss";

import LogoIcon from "../../../assets/LogoIcon";
import { NavLink } from "react-router-dom";
import React from "react";

interface Props {}
interface State {}

class Logo extends React.Component<Props, State> {
  public render() {
    return (
      <NavLink to="/" className="logo">
        <LogoIcon />
        <div className="name">
          <div>
            <span>C</span>LIENT
          </div>
          <div>
            <span>M</span>ONITORING
          </div>
        </div>
      </NavLink>
    );
  }
}

export default Logo;
