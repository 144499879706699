import "./AutocenterCard.scss";

import React from "react";

interface Props {
  autocenter: App.Autocenter;
}

interface State {
  isOpen: boolean;
}

class AutocenterCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  private handleClickOnHeader() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  public render() {
    return (
      <div className="autocenter-card">
        <div
          className="autocenter-card-header"
          onClick={this.handleClickOnHeader.bind(this)}
        >
          <div className="info">
            <div className="name">{this.props.autocenter.name}</div>
            <div className="address">{this.props.autocenter.address}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutocenterCard;
