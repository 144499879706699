import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";

class GroupService {
  public async getList(params: {
    autocenter?: number;
  }): Promise<App.ServiceResponse<Array<App.Group>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/groups", { headers: { Authorization }, params }).then(
      (response: AxiosResponse<Array<App.Group>>) => {
        return { status: true, data: response.data };
      }
    );
  }

  public async getById(id: number): Promise<App.ServiceResponse<App.Group>> {
    const Authorization = await JWT.getAuth();
    return Axios.get(`/group/${id}`, { headers: { Authorization } }).then(
      (response: AxiosResponse<App.Group>) => {
        return { status: true, data: response.data };
      }
    );
  }

  public async create(
    group: App.GroupData,
    autocenter_id: number
  ): Promise<App.ServiceResponse<App.Group>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/group/create",
      Object.assign(group, { autocenter_id }),
      { headers: { Authorization } }
    ).then((response: AxiosResponse<App.Group>) => {
      return { status: true, data: response.data };
    });
  }

  public async update(
    group: App.GroupData,
    id: number
  ): Promise<App.ServiceResponse<App.Group>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(`/group/update/${id}`, group, {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Group>) => {
      return { status: true, data: response.data };
    });
  }

  public async delete(id: number): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/group/delete/${id}`, {
      headers: { Authorization },
    }).then(() => {
      return { status: true, data: null };
    });
  }
}

export default GroupService;
