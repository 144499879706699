import "./FaqView.scss";

import FaqListContainer from "../../containers/faqList/FaqListContainer";
import React from "react";

interface Props {}
interface State {}

class FaqView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="faq-view">
        <FaqListContainer />
      </div>
    );
  }
}
export default FaqView;
