import { Actions } from "../actions/autocentersActions";

export interface AutocentersStore {
  isLoaded: boolean;
  isOpenLinks: boolean;
}
export const defaultAutocentersStore: AutocentersStore = {
  isLoaded: false,
  isOpenLinks: false,
};

export function autocenters(
  store: AutocentersStore,
  action: Actions
): AutocentersStore {
  switch (action.type) {
    case "ChangeLoadedStatusAction":
      store.isLoaded = action.status;
      break;
    case "ToggleAutocentersLinksAction":
      store.isOpenLinks = !store.isOpenLinks;
      break;
    default:
      break;
  }
  store.isOpenLinks = store.isLoaded && store.isOpenLinks;
  return store;
}
