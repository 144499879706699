import LabelSearch from "../../labels/search/LabelSearch";
import React from "react";
import Tabs from "../../tabs/Tabs";
import Title from "../../title/Title";

class TableFilters extends React.Component<UI.Table.TableFilters> {
  private timeout: number;
  constructor(props: UI.Table.TableFilters) {
    super(props);
    this.timeout = 0;
  }

  private handleChangeKeyword(keyword: string) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() =>
      this.props.dispatchUpdate(Object.assign(this.props.filters, { keyword }))
    );
  }

  private handleChangeTab(tab: number) {
    this.props.dispatchUpdate(Object.assign(this.props.filters, { tab }));
  }

  public render() {
    return (
      <div className="table-filters">
        {this.props.title && <Title>{this.props.title}</Title>}
        {this.props.rules.search && (
          <LabelSearch
            value={this.props.filters.keyword}
            onChange={this.handleChangeKeyword.bind(this)}
          />
        )}
        {this.props.rules.tab && (
          <Tabs
            tabs={this.props.filterOptions.tabs}
            selected={this.props.filters.tab}
            onClick={this.handleChangeTab.bind(this)}
          />
        )}
        {!!this.props.children && (
          <div className="children">{this.props.children}</div>
        )}
      </div>
    );
  }
}

export default TableFilters;
