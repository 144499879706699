import "./Home.scss";

import AutocenterService from "../../services/AutocenterService";
import { AxiosError } from "axios";
import CallsPlot from "../../components/calls/callsPlot/CallsPlot";
import LastSmsListContainer from "../../containers/sms/LastSmsListContainer";
import Page from "../../UI/utils/Page";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { displayErrors } from "../../store/actions/error";

interface Props extends RouteComponentProps {}
interface State {
  autocenters: Array<App.Autocenter>;
  autocenter?: number;
}

class Home extends React.Component<Props, State> {
  private autocentersService: AutocenterService;
  constructor(props: Props) {
    super(props);
    this.autocentersService = new AutocenterService();
    this.state = {
      autocenters: [],
    };
  }

  public componentDidMount() {
    Page.setTitle("Главная");
    this.loadAutocenters();
  }
  public componentWillUnmount() {}

  private loadAutocenters() {
    this.autocentersService
      .getList()
      .then((response) => {
        this.setState({ autocenters: response.data });
      })
      .catch((error: AxiosError<Array<string>>) => {
        console.log(error);
        displayErrors(
          error.response?.data || [
            "Не удалось получить список ваших автоцентров",
          ]
        );
      });
  }

  private handleChangeAutocenter(autocenter: number | null) {
    this.setState({ autocenter: autocenter || undefined });
  }
  public render() {
    return (
      <div className="home">
        <CallsPlot
          autocenters={this.state.autocenters}
          onChangeAutocenter={this.handleChangeAutocenter.bind(this)}
          autocenter={this.state.autocenter}
        />
        <LastSmsListContainer />
      </div>
    );
  }
}

export default Home;
