import "./Search.scss";

import CallsTableContainer from "../../containers/calls/CallsTableContainer";
import Page from "../../UI/utils/Page";
import React from "react";

class Search extends React.Component {
  public componentDidMount() {
    Page.setTitle("Поиск");
  }
  public render() {
    return (
      <div className="search">
        <CallsTableContainer search />
      </div>
    );
  }
}

export default Search;
