import "./SmsGroupFooter.scss";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  amount: number;
  id: number;
}
interface State {}

class SmsGroupFooter extends React.Component<Props, State> {
  public render() {
    return (
      <div className="sms-group-footer">
        <div className="amount">Объектов в группе: {this.props.amount}</div>
        <NavLink to={`/sms-group/${this.props.id}`}>Подробнее</NavLink>
      </div>
    );
  }
}

export default SmsGroupFooter;
