import "./UserCard.scss";

import Box from "../../custom/box/Box";
import React from "react";
import Select from "../../custom/select/Select";
import Time from "../../../UI/utils/Time";

interface Props {
  user: App.UserInfo;
  autocenters: Array<App.Autocenter>;
  disabled: boolean;
  update: (user: App.UserInfo) => void;
}
interface State {
  role: string;
  autocenters: Array<number>;
}

class UserCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      autocenters: props.user.autocenters,
      role: props.user.role,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.user !== prevProps.user) {
      this.setState({
        role: this.props.user.role,
        autocenters: this.props.user.autocenters,
      });
    }
  }

  private get role() {
    switch (this.state.role) {
      case "admin": return 1;
      case "user_advanced": return 2;
      case "user":
      default: return 3;
    }
  }

  private handleChangeRole(role: number | null) {
    if (role === null) return;
    let stateRole;
    switch(role) {
      case 1: stateRole = "admin"; break;
      case 2: stateRole = "user_advanced"; break;
      case 3: stateRole = "user"; break;
      default: return;
    }
    this.setState({
      role: stateRole
    });
  }
  private handleChangeAutocenters(autocenters: Array<number>) {
    this.setState({
      autocenters,
    });
  }

  private handleSubmit() {
    this.props.update(
      Object.assign({}, this.props.user, {
        role: this.state.role,
        autocenters: this.state.autocenters,
      })
    );
  }

  public render() {
    return (
      <Box className="user-card" shadow={false}>
        <div className="user-card__login">{this.props.user.login}</div>
        <div className="user-card__settings">
          <Select
            disabled={this.props.disabled}
            options={this.props.autocenters}
            selected={this.state.autocenters}
            onChange={this.handleChangeAutocenters.bind(this)}
            multiselect={true}
            label="Автоцентры"
          />
          <Select
            disabled={this.props.disabled}
            options={[
              { id: 1, name: "Администратор" },
              { id: 2, name: "Пользователь с полным поиском" },
              { id: 3, name: "Пользователь" },
            ]}
            onChange={this.handleChangeRole.bind(this)}
            selected={this.role}
            multiselect={false}
            label="Роль"
          />
        </div>
        <div className="user-card__footer">
          <span>Создан: {Time.beautifyDate(new Date())}</span>
          <button
            disabled={
              this.props.disabled ||
              (this.props.user.autocenters === this.state.autocenters &&
                this.props.user.role === this.state.role)
            }
            onClick={this.handleSubmit.bind(this)}
          >
            Сохранить
          </button>
        </div>
      </Box>
    );
  }
}
export default UserCard;
