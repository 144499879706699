import Axios, { AxiosResponse } from "axios";

import JWT from "../utils/jwt/JWT";

export interface AutocenterData {
  name: string;
  address: string;
}
class AutocenterService {
  public async getList(): Promise<App.ServiceResponse<Array<App.Autocenter>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/autocenters", { headers: { Authorization } }).then(
      (response: AxiosResponse<Array<App.Autocenter>>) => {
        return { status: true, data: response.data };
      }
    );
  }
  public async getById(
    id: number
  ): Promise<App.ServiceResponse<App.Autocenter>> {
    const Authorization = await JWT.getAuth();
    return Axios.get(`/autocenter/${id}`, { headers: { Authorization } }).then(
      (response: AxiosResponse<App.Autocenter>) => {
        return { status: true, data: response.data };
      }
    );
  }
  public async create(
    autocenter: AutocenterData
  ): Promise<App.ServiceResponse<App.Autocenter>> {
    const Authorization = await JWT.getAuth();
    return Axios.post("/autocenter/create", autocenter, {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Autocenter>) => {
      return { status: true, data: response.data };
    });
  }
  public async update(
    autocenter: AutocenterData,
    id: number
  ): Promise<App.ServiceResponse<App.Autocenter>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(`/autocenter/update/${id}`, autocenter, {
      headers: { Authorization },
    }).then((response: AxiosResponse<App.Autocenter>) => {
      return { status: true, data: response.data };
    });
  }
  public async delete(id: number): Promise<App.ServiceResponse> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/autocenter/delete/${id}`, {
      headers: { Authorization },
    }).then(() => {
      return { status: true, data: null };
    });
  }
}

export default AutocenterService;
